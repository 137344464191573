import React, { act, useEffect, useState } from 'react';
import { arrayRemove, arrayUnion, collection, doc, onSnapshot, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import ArrowBack from '../components/arrowBack';

export default function BookAdmin() {

  const [facilities, setFacilities] = useState([])
  const [locations, setLocations] = useState("")

  const [facility, setFacility] = useState("")
  const [location, setLocation] = useState("")
  const [groups, setGroups] = useState("")

  const [selectedGroup, setSelectedGroup] = useState("")
  const [list, setList] = useState("")

  const [newUser, setNewUser] = useState("")
  const [user, setUser] = useState("")
  
  const [u, setU] = useState("")

  const [freq, setFreq] = useState("")

  useEffect(() => {
    const getFacilities = onSnapshot(collection(db, "facilities"), (snapshot) => {
      setFacilities(snapshot.docs.map((doc) => (doc.data())));
    });
  }, []);

  useEffect(() => {
    const getFreq = onSnapshot(collection(db, "freq"), (snapshot) => {
      setFreq(snapshot.docs.map((doc) => (doc.data())));
    });
  }, []);

  const addU = () => {
    setDoc(doc(db, "freq", u?.id), {
      users: {
        [u?.uid]: {
          uid: u?.uid,
          name: u?.name,
          phone: u?.phone,
          toBe: false,
          info: ""
        }
      }
    }, {merge: true})
  }


  const addUd = () => {
    setDoc(doc(db, "freq", u?.id), {
      id: u?.id,
      location: u?.location,
      instructor: u?.instructor,
      time: u?.time,
      type: u?.type,
      color: u?.color,
      date: "17.09.2024"
    }, {merge: true})
  }


  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "locations"), (snapshot) => {
      const collectionData = snapshot.docs.map(doc => doc?.data());

      let loc = collectionData
      let locs = []
      for (let i = 0; i < loc?.length; i++) {
        if (!loc[i]) return
        for (let x = 0; x < Object?.values(loc[i])?.length; x++) {
          locs?.push(Object?.values(loc[i])[x])
        }
      }
      setLocations(locs);
    });
  }, []);
  
  useEffect(() => {
    if (selectedGroup === "") return
    const getList = onSnapshot(doc(db, "book-list", selectedGroup?.id), (snapshot) => {
      setList(snapshot.data());
    });
  }, [selectedGroup]);

  useEffect(() => {
    if (location) {
      const getGroups = onSnapshot(doc(db, 'groups', facility?.id), (snapshot) => {
        const data = snapshot.data();
  
        let transformedGroups = [];
  
        Object.keys(data).forEach((groupId) => {
          const group = data[groupId];
  
          if (group.days.length > 1 && !group.rnieozdzielenia) {
            group.days.forEach((day) => {
              const newGroup = {
                ...group,
                days: day, 
              };
              transformedGroups.push(newGroup);
            });
          } else {
            const newGroup = {
              ...group,
              days: group.days[0], 
            };
            transformedGroups.push(newGroup);
          }
        });
  
        setGroups(transformedGroups);
      });
    }
  }, [location]);


  const weekDayConvert = (day) => {
    if (day === 1) return "Poniedziałek"
    if (day === 2) return "Wtorek"
    if (day === 3) return "Środa"
    if (day === 4) return "Czwartek"
    if (day === 5) return "Piątek"
    if (day === 6) return "Sobota"
  }

  const [data, setData] = useState("")

  const addGroup = () => {
    const newId = doc(collection(db, "anyCollection")).id;

    setDoc(doc(db, "groups", data?.facility), {
      [newId]: {
        adults: data?.adults,
        days: arrayUnion({
          endTime: data?.endTime,
          startTime: data?.startTime,
          weekDay: parseInt(data?.weekDay),
          custom_name: data?.custom_name ? data?.custom_name : "",
        }),
        exp: data?.exp,
        id: newId,
        location_id: data?.location,
        maxAge: parseInt(data?.maxAge),
        minAge: parseInt(data?.minAge),
        instructor: data?.instructor ? data?.instructor : "",
        prices: {
          contract: parseInt(data?.contract),
          entry: parseInt(data?.entry),
          obligation: data?.obligation ? parseInt(data?.obligation) : "Nie dotyczy",
        },
        slots: parseInt(data?.slots),
        split: false,
        type: data?.type
      }
    }, {merge: true})

    setDoc(doc(db, "book-list", `${newId}`), {
      id: data?.location,
      reserves: [],
      users: [],
    }, {merge: true})
    
  }

  console.log(u)

    return (
      // <div style={({display: "flex", gap: "40px", margin: "3%", width: "97%", marginTop: "20px", flexDirection: "column"})}>
      //   <h3 style={({fontSize: "18px"})}>Nowa grupa</h3>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Placówka</h3>
      //     <select value={data?.facility?.name} onChange={(e) => setData({...data, facility: e?.target?.value})}>
      //       <option value={""}>--</option>
      //       {facilities && facilities?.map((f) => 
      //         <option value={f?.id}>{f?.name}</option>
      //       )}
      //     </select>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Lokalizacja</h3>
      //     <select value={data?.location?.name} onChange={(e) => setData({...data, location: e?.target?.value})}>
      //       <option value={""}>--</option>
      //       {locations && locations?.map((l) => 
      //         <option value={l?.id}>{l?.name}</option>
      //       )}
      //     </select>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Nazwa grupy</h3>
      //     <input onBlur={(e) => setData({...data, custom_name: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Typ zajeć</h3>
      //     <input onBlur={(e) => setData({...data, type: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Dzień zajęć</h3>
      //     <input type="number" onBlur={(e) => setData({...data, weekDay: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Godzina rozpoczęcia</h3>
      //     <input onBlur={(e) => setData({...data, startTime: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Godzina zakończenia</h3>
      //     <input onBlur={(e) => setData({...data, endTime: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Min wiek</h3>
      //     <input type="number" onBlur={(e) => setData({...data, minAge: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Max wiek</h3>
      //     <input type="number" onBlur={(e) => setData({...data, maxAge: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Instruktor</h3>
      //     <input onBlur={(e) => setData({...data, instructor: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Poziom</h3>
      //     <input onBlur={(e) => setData({...data, exp: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Cena umowy</h3>
      //     <input type="number" onBlur={(e) => setData({...data, contract: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Cena wejścia</h3>
      //     <input type="number" onBlur={(e) => setData({...data, entry: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Cena obligacji</h3>
      //     <input type="number" onBlur={(e) => setData({...data, obligation: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Ilość miejsc</h3>
      //     <input type="number" onBlur={(e) => setData({...data, slots: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Czy dorośli?</h3>
      //     <input type="checkbox" onChange={(e) => setData({...data, adults: e?.target?.checked})} checked={data?.adults}/>
      //   </div>
      //   <button onClick={() => addGroup()}>Dodaj</button>
      // </div>



      <div style={({display: "flex", gap: "40px", margin: "3%", width: "97%", marginTop: "20px", flexDirection: "column"})}>
      <h3 style={({fontSize: "18px"})}>Nowy</h3>
      <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
        <h3 style={({fontSize: "18px"})}>id grupy</h3>
        <input onBlur={(e) => setU({...u, id: e?.target?.value})}/>
      </div>
      <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
        <h3 style={({fontSize: "18px"})}>uid</h3>
        <input onBlur={(e) => setU({...u, uid: e?.target?.value})}/>
      </div>
      <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
        <h3 style={({fontSize: "18px"})}>name</h3>
        <input  onBlur={(e) => setU({...u, name: e?.target?.value})}/>
      </div>
      <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
        <h3 style={({fontSize: "18px"})}>phone</h3>
        <input onBlur={(e) => setU({...u, phone: e?.target?.value})}/>
      </div>
      <button onClick={() => addU()}>Dodaj</button>
    </div>


  //   <div style={({display: "flex", gap: "40px", margin: "3%", width: "97%", marginTop: "20px", flexDirection: "column"})}>
  //   <h3 style={({fontSize: "18px"})}>Nowy</h3>
  //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
  //     <h3 style={({fontSize: "18px"})}>id grupy</h3>
  //     <input onBlur={(e) => setU({...u, id: e?.target?.value})}/>
  //   </div>
  //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
  //     <h3 style={({fontSize: "18px"})}>color</h3>
  //     <input onBlur={(e) => setU({...u, color: e?.target?.value})}/>
  //   </div>
  //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
  //     <h3 style={({fontSize: "18px"})}>type</h3>
  //     <input  onBlur={(e) => setU({...u, type: e?.target?.value})}/>
  //   </div>
  //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
  //     <h3 style={({fontSize: "18px"})}>time</h3>
  //     <input onBlur={(e) => setU({...u, time: e?.target?.value})}/>
  //   </div>
  //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
  //     <h3 style={({fontSize: "18px"})}>location</h3>
  //     <input onBlur={(e) => setU({...u, location: e?.target?.value})}/>
  //   </div>
  //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
  //     <h3 style={({fontSize: "18px"})}>instructor</h3>
  //     <input onBlur={(e) => setU({...u, instructor: e?.target?.value})}/>
  //   </div>
  //   <button onClick={() => addUd()}>Dodaj</button>
  // </div>
    );
  }