import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { Link, useParams } from 'react-router-dom';

function LocationSelect() {

  const params = useParams()
  const [locations, setLocations] = useState("")
  const [selected, setSelected] = useState([])

  useEffect(() => {
    const getLocations = onSnapshot(doc(db, "locations", params?.fid), (snapshot) => {
      setLocations(snapshot.data());
    });
  }, []);

  const chooseLocation = (loc) => {
    if (!selected?.includes(loc)) {
        if (selected?.length === 3) {
            console.log("full")
        }
        else {
          setSelected([...selected, loc])
        }
    }
    else {
      setSelected(selected?.filter(x => x !== loc))
    }
  }

  return (
    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", backgroundColor: "rgb(250,250,250)", width: "100%", minHeight: "100vh", overflowY: "scroll", gap: "30px"})}>
      <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "90%", gap: "15px", marginBottom: "50px"})}>
        <img src={process.env.PUBLIC_URL + "/logosvg.svg"} style={({width: "120px", marginBottom: "-30px"})} />
        <h3 style={({color: "rgb(30,30,30)", fontSize: "27px", textAlign: "center"})}>Wybierz lokalizację do której chcesz się zapisać</h3>
        <h3 style={({color: "rgb(30,30,30)", fontSize: "13px", fontWeight: "normal", textAlign: "center"})}>Wybierz z listy lokalizację, do której na zajęcia chcesz uczęszczać, możesz wybrać maksymalnie 3 placówki.</h3>
        <h3 style={({color: (selected && selected?.length > 0) ? "#0045C7" : "rgb(0,0,0, 0)", fontSize: "14px", fontWeight: "bold", textAlign: "center", transition: "0.5s"})}>{(selected?.length) ? `Wybrane lokalizacje: ${selected?.length}`: ""}</h3>
          <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "100%", gap: "15px", overflowY: "scroll", paddingTop: "6px", paddingBottom: "6px"})}>
            {locations &&
              Object?.values(locations)?.map((l) =>
                <div style={({display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", cursor: "pointer", padding: "15px", border: selected?.includes(l?.id) ? "2px solid #0045C7" : "2px solid rgba(0,0,0,0)", borderRadius: "5px", width: "80%", transition: "0.5s", boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1.5px, rgb(209, 213, 219) 0px 0px 0px 1px"})} onClick={() => chooseLocation(l?.id)}>            
                    <div style={({display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: "flex-start", gap: "20px"})}>
                        <img src={process.env.PUBLIC_URL + `/logo4STARS.png`} style={({width: "35px"})} />
                        <div style={({display: 'flex', flexDirection: 'column', justifyContent: "flex-start", alignItems: "flex-start", gap: "5px"})}>
                            <h3 style={({color: selected?.includes(l?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "12px", transition: "0.5s"})}>{l?.name}</h3>
                            <h3 style={({color: selected?.includes(l?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "12px", transition: "0.5s", fontWeight: "normal"})}>ul. {l?.address}</h3>
                        </div>
                    </div>
                    <div style={({display: 'flex', flexDirection: 'column', justifyContent: "flex-start", alignItems: "flex-end", gap: "5px"})}>
                        <h3 style={({color: "#0045C7", fontSize: "11px", transition: "0.5s"})}>Wolne miejsca!</h3>
                    </div>
                </div>
              )
            }
          </div>
          <Link to={`/rejestracja/${params?.fid}/${selected?.join("-")}`}><button style={({fontSize: "13px", color: "white", borderRadius: "5px", marginTop: "20px", backgroundColor: "#0045C7", border: "2px solid #0045C7", transition: "0.7s", fontWeight: "bold", padding: "10px 30px"})} disabled={selected?.length === 0}>Przejdź dalej</button></Link>
      </div>
    </div>
  );
}

export default LocationSelect;