
import { useRef, useState } from "react";
import CanvasDraw from "react-canvas-draw";
import { PDFDownloadLink, Page, View, Document, Text, Image, Font, StyleSheet, PDFViewer, PDFRenderer } from "@react-pdf/renderer";

export default function AdultContract({user, parent, signing, acc, obligation, facility, date}) {

    Font.register({
        family: "Roboto",
        fonts: [
          { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf", fontWeight: 300 },
          { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf", fontWeight: 400 },
          { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf", fontWeight: 500 },
          { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf", fontWeight: 600 },
        ],
      })
      
    
      const styles = StyleSheet.create({
        body: {
          paddingTop: 25,
          paddingBottom: 65,
          paddingHorizontal: 35,
          width: "50%"
        },
        title: {
          fontSize: 24,
          textAlign: 'center',
          fontFamily: 'Roboto',
          fontWeight: 600,
          marginBottom: 20
        },
        title2: {
            fontSize: 20,
            textAlign: 'center',
            fontFamily: 'Roboto',
            fontWeight: 500
          },
        author: {
          fontSize: 12,
          textAlign: 'center',
          marginTop: 5
        },        author: {
          fontSize: 12,
          textAlign: 'center',
          marginTop: 5
        },
        date: {
            fontSize: 16,
            textAlign: 'right',
            marginBottom: 30,
            marginTop: 10,
            fontWeight: 500,
            fontFamily: 'Roboto',
          },
          name: {
            fontSize: 16,
            textAlign: 'left',
            marginBottom: 30,
            marginTop: 10,
            fontWeight: 500,
            fontFamily: 'Roboto',
          },
        subtitle: {
          fontSize: 9,
          textAlign: 'center',
          fontFamily: 'Roboto'
        },
        subtitle2: {
            fontSize: 9,
            textAlign: 'center',
            fontFamily: 'Roboto',
            marginBottom: 30
          },
        text: {
          margin: 7,
          fontSize: 12,
          textAlign: 'justify',
          fontFamily: 'Roboto'
        },
        textH: {
            margin: 3,
            fontSize: 12,
            textAlign: 'justify',
            fontFamily: 'Roboto',
            width: "80%"
          },
        textB: {
            margin: 7,
            fontSize: 12,
            textAlign: 'justify',
            fontFamily: 'Roboto',   
            fontWeight: 500
          },
        signs: {
            margin: 12,
            fontSize: 12,
            fontFamily: 'Roboto',
            fontWeight: 500
          },
        image: {
          marginVertical: 15,
          marginHorizontal: 10,
          width: 55,
          height: 55,
          alignSelf: "center"
        },
        imageTest: {
            position: "absolute",
            left: "0px",
            right: "0px",
            width: "100vw",
            height: "100vh",
            marginHorizontal: 'auto'
          },
        imageSign: {
            width: 85,
            height: 85,
            alignSelf: "center"
          },
          imageSignMini: {
            width: 60,
            height: 60,
            alignSelf: "center"
          },
        header: {
          fontSize: 12,
          marginBottom: 20,
          textAlign: 'center',
          color: 'grey',
        },
        pageNumber: {
          position: 'absolute',
          fontSize: 12,
          bottom: 30,
          left: 0,
          right: 0,
          textAlign: 'center',
          color: 'grey',
        },
      });

return (
    <>
        <PDFDownloadLink style={{textDecoration: "none", fontSize: "12px", color: "rgb(30,30,30)", cursor: "pointer"}} document={
          <Document>
            <Page style={styles.body}>
                {facility === "cfh66CW4jxFRMkU9AT5y" ?
                  <Image
                      style={styles.imageTest}
                      src={process.env.PUBLIC_URL + "/umowa1_dj.png"}
                      fixed
                  />
                :
                  <Image
                    style={styles.imageTest}
                    src={process.env.PUBLIC_URL + "/umowa1_dw.png"}
                    fixed
                  />
                }
                <Text style={{top: "193px", left: "113px", fontSize: "10px", fontFamily: 'Roboto'}}>{date}</Text>
                <Text style={{top: "240px", left: "143px", fontSize: "10px", fontFamily: 'Roboto'}}>{parent?.displayName}</Text>
                <Text style={{top: "244px", left: "100px", fontSize: "10px", fontFamily: 'Roboto'}}>ul. {parent?.address?.street + "/" + parent?.address?.local} {parent?.address?.city}</Text>
                <Text style={{top: "248px", left: "70px", fontSize: "10px", fontFamily: 'Roboto'}}>{parent?.phone}</Text>
                <Text style={{top: "234px", left: "216px", fontSize: "10px", fontFamily: 'Roboto'}}>{acc?.email}</Text>
                <Text style={{top: "271px", left: "70px", fontSize: "10px", fontFamily: 'Roboto'}}>{user?.dateOfBirth}</Text>
                <Text style={{top: "257px", left: "210px", fontSize: "10px", fontFamily: 'Roboto'}}>{user?.pesel}</Text>
                <Text style={{top: "284px", left: "162px", fontSize: "10px", fontFamily: 'Roboto'}}>{date}</Text>
                <Text style={{top: "445px", left: "115px", fontSize: "9px", fontFamily: 'Roboto'}}>{user?.docs?.contract?.price ? user?.docs?.contract?.price : "-"}</Text>
                <Text style={{top: "434px", left: "186px", fontSize: "9px", fontFamily: 'Roboto'}}>{user?.docs?.contract?.groups ? user?.docs?.contract?.groups : "-"}</Text>
            </Page>
            <Page style={styles.body}>
            {facility === "cfh66CW4jxFRMkU9AT5y" ?
                  <Image
                      style={styles.imageTest}
                      src={process.env.PUBLIC_URL + "/umowa2_dj.png"}
                      fixed
                  />
                :
                  <Image
                    style={styles.imageTest}
                    src={process.env.PUBLIC_URL + "/umowa2_dw.png"}
                    fixed
                  />
                }
                {signing && 
                  <Image
                      style={{top: "240px", left: "60px", width: "80px"}}
                      src={signing}
                      fixed
                  />
                }
            </Page>
          </Document>
        }>{({loading}) => 
          (loading ? 
            <div style={({display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: "15px", cursor: "pointer", padding: "6px", borderRadius: "5px", width: "280px"})}>
              <img src={process.env.PUBLIC_URL + "/emptyFile.png"} style={({width: "24px"})}/>
              <h3 style={({textDecoration: "none", fontSize: "12px", color: "rgb(30,30,30)", cursor: "pointer", fontWeight: "bold", width: "215px"})}>Generowanie umowy...</h3>
            </div>
        :
          <div style={({display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: "15px", cursor: "pointer", padding: "6px", borderRadius: "5px", width: "280px"})}>
            <img src={process.env.PUBLIC_URL + "/emptyFile.png"} style={({width: "24px"})}/>
            <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: 'flex-start'})}>
              <h3 style={({textDecoration: "none", fontSize: "12px", color: "rgb(30,30,30)", cursor: "pointer", fontWeight: "bold", width: "215px"})}>Umowa dot. {user?.displayName}</h3>
            </div>
          </div>
        )}
        </PDFDownloadLink>
    </>
    )
}