import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions';
import JoinRequest from "../components/joinRequest";
import RulesStatement from "../components/rulesStatement";
import Contract from "../components/contract";
import ParentStatement from "../components/parentStatement";
import JoinDecision from "../components/decision";
import CanvasDraw from "react-canvas-draw";
import AdultContract from "../components/adultContract";

export default function ClientPanel() {

    const canvasRef = useRef(null);

    const [error, setError] = useState("")
    
    const navigate = useNavigate(); // Hook do nawigacji
    const [docs, setDocs] = useState("")
      
    const handleLogout = async () => {
        try {
        // Wylogowanie użytkownika
        await signOut(auth);
            
         // Po wylogowaniu przekierowanie na stronę główną
        navigate('/');
        } catch (error) {
        console.error('Błąd podczas wylogowania:', error);
        }
    }

    const [userAcc, setUserAcc] = useState("")

    useEffect(() => {
        if (!auth?.currentUser) return

        const getAcc = onSnapshot(doc(db, "users", auth?.currentUser?.uid), (snapshot) => {
          setUserAcc(snapshot.data());
        });
    }, []);

    useEffect(() => {
        if (!auth?.currentUser) return

        const getAcc = onSnapshot(doc(db, "users", auth?.currentUser?.uid), (snapshot) => {
          setUserAcc(snapshot.data());
        });
    }, [docs]);

    useEffect(() => {
        if (!userAcc) return

        if (!userAcc?.sign) {
            setDocs("to-sign")
        }
        else {
            setDocs("signed")
        }
    }, [userAcc]);

    const checkIfSigned = () => {
        const saveData = canvasRef.current.getSaveData();
        if (saveData && saveData.length > 10) {
            setDoc(doc(db, "users", `${auth?.currentUser?.uid}`), {
                date: new Date()?.toLocaleDateString("pl-PL"), sign: canvasRef?.current?.getDataURL()
            }, {merge: true})
        } else {
            setError("Podpis nie został złożony")
        }
    };

    const generateDocs = () => {
        if (!userAcc?.subUsers?.[0]?.docs?.contract?.active) {
            setDocs("to-sign")
        }
        else {
            setDocs("signed")
        }
    }

    return (
      <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", backgroundColor: "rgb(240,240,240)", width: "100%", minHeight: "100vh", overflowY: "scroll", gap: "30px"})}>
        <div style={({display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", position: "fixed", zIndex: "40", top: error ? "45px" : "-300px", transform: "translate(-50%, -50%)", left: "50%", backgroundColor: "rgb(250,250,250)", width: "320px", height: "75px", transition: "0.8s", borderRadius: "15px", border: "3px solid rgb(215,0,64)", gap: "20px"})}>
            <img src={process.env.PUBLIC_URL + "/error.png"} style={({width: "30px"})} />
            <h3 style={({fontSize: "13px", fontWeight: "bold", color: "rgb(215,0,64)", textAlign: "center", width: "75%"})}>{error}</h3>
        </div>
        {userAcc && 
            (Object.values(userAcc?.subUsers).some(subUser => subUser.toVerificate === true)) ?
                <div style={({display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "15px", width: "80%", height: "100vh"})}>
                    <img src={process.env.PUBLIC_URL + "clock.png.png"} style={({width: "110px", marginBottom: "20px"})} />
                    <h3 style={({fontSize: "30px", color: "rgb(30,30,30)", textAlign: "center", marginBottom: "10px"})}>Twoje konto jest w trakcie weryfikacji!</h3>
                    <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", fontWeight: "lighter", textAlign: "center", marginTop: "-10px", marginBottom: "20px"})}>Twój zapis jest przez nas sprawdzany, gdy tylko ten proces się zakończy otrzymasz wiadomość email, która cię o tym powiadomi!</h3>
                    <h3 style={({fontSize: "15px", color: "#0045C7", fontWeight: "bold", textAlign: "center", marginTop: "50px", marginTop: '-10px'})} onClick={() => handleLogout()}>Wyloguj</h3>
                </div>
            :
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column", gap: "15px", width: "100%", position: "relative"})}>
                    <nav style={({width: "100%", borderBottom: "1px solid rgb(30,30,30)", height: "60px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"})}>
                        <div style={({display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "90%", marginLeft: "-10px"})}>
                            <img src={process.env.PUBLIC_URL + "logo4STARS.png"} style={({width: "45px"})} />
                            <img src={process.env.PUBLIC_URL + "logout_black.png"} style={({width: "22px"})} onClick={() => handleLogout()}/>
                        </div>
                    </nav>
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column", gap: "15px", width: "90%", padding: "0% 5%"})}>
                        <h3 style={({fontSize: "26px", color: "rgb(30,30,30)"})}>Witaj ponownie!</h3>
                        <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", fontWeight: "normal"})}>Aby opłacić fakturę wejdź na swojego maila i znajdź wiadomość od Stripe.</h3>
                        <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", fontWeight: "normal"})}>Poniżej znajduje się dokumentacja, którą musisz podpisać składając podpis polu "Podpis", a jeżeli już to zrobiłeś możesz pobrać swoje wypełnione dokumenty.</h3>
                        <h3 style={({fontSize: "12px", color: "rgb(30,30,30)", fontWeight: "normal"})}>Nie widzisz dokumentów? Kliknij w "Generuj dokumentacje"</h3>
                        <button style={({fontSize: "10px", color: "white", borderRadius: "5px", marginTop: "20px", backgroundColor: "#0045C7", border: "2px solid #0045C7", transition: "0.7s", fontWeight: "bold", padding: "5px 15px", marginTop: "-15px"})} onClick={() => generateDocs()}>Generuj dokumentacje</button>
                        {docs === "to-sign" &&
                            <div style={({display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column", gap: "10px"})}>
                                <h3 style={({fontSize: "18px", color: "rgb(30,30,30)"})}>Dokumentacja do podpisania:</h3>
                                {userAcc && Object?.values(userAcc?.subUsers)?.map((u) =>
                                    Object?.values(u?.prices)?.includes("contract") || Object?.values(u?.prices)?.includes("obligation") ?
                                        <>
                                            <JoinRequest 
                                                displayName={u?.displayName}
                                            />
                                            <RulesStatement 
                                                displayName={u?.displayName} facility={userAcc?.facility_id} date={new Date()?.toLocaleDateString("pl-PL")}
                                            />
                                            {!u?.adult &&
                                            <Contract 
                                                user={u} parent={userAcc?.parent} facility={userAcc?.facility_id} acc={userAcc} obligation={u?.docs?.contract?.obligation} date={new Date()?.toLocaleDateString("pl-PL")}
                                            />
                                            }
                                            {u?.adult &&
                                            <AdultContract 
                                                user={u} parent={userAcc?.parent} facility={userAcc?.facility_id} acc={userAcc} obligation={u?.docs?.contract?.obligation} signing={userAcc?.sign} date={userAcc?.date}
                                            />
                                            }
                                            {!u?.adult &&
                                            <ParentStatement 
                                                user={u} parent={userAcc?.parent} acc={userAcc} date={new Date()?.toLocaleDateString("pl-PL")}
                                            />
                                            }
                                        </>
                                    :
                                    <>
                                        {!u?.adult ?
                                            <ParentStatement 
                                                user={u} parent={userAcc?.parent} acc={userAcc} date={new Date()?.toLocaleDateString("pl-PL")}
                                            />
                                            :
                                            <h3 style={({fontSize: "18px", color: "rgb(30,30,30)", marginBottom: "120px"})}>Na ten moment nie masz do podpisania żadnej dokumentacji</h3>
                                        }
                                    </>
                                )}
                                <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", fontWeight: "bold"})}>UWAGA! Złoż podpis w polu przed kliknięciem "Złóż podpis"!</h3>
                                <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", fontWeight: "normal"})}>Pole na podpis</h3>
                                <CanvasDraw ref={canvasRef} brushColor="black" brushRadius={1.5}  lazyRadius={0} hideInterface hideGrid style={({backgroundColor: "rgb(0,0,0,0)", width: "100%", height: "120px", maxWidth: "400px", border: "1px solid black", borderRadius: "5px"})}/>
                                <button style={({width: "200px", minHeight: "35px", fontSize: "13px", color: "white", borderRadius: "5px"})} className="button-black" onClick={() => checkIfSigned()}>Złóż podpis</button>
                            </div>
                        }
                        {docs === "signed" &&
                            <div style={({display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column", gap: "10px"})}>
                                <h3 style={({fontSize: "18px", color: "rgb(30,30,30)"})}>Podpisana dokumentacja:</h3>
                                {userAcc && Object?.values(userAcc?.subUsers)?.map((u) =>
                                    Object?.values(u?.prices)?.includes("contract") || Object?.values(u?.prices)?.includes("obligation") ?
                                    <>
                                        <JoinDecision 
                                            displayName={u?.displayName} date={userAcc?.date} signing={userAcc?.sign}
                                        />
                                        {!u?.adult &&
                                        <Contract 
                                            user={u} parent={userAcc?.parent} facility={userAcc?.facility_id} acc={userAcc} obligation={u?.docs?.contract?.obligation} signing={userAcc?.sign} date={userAcc?.date}
                                        />
                                        }
                                        {u?.adult &&
                                        <AdultContract 
                                            user={u} parent={userAcc?.parent} facility={userAcc?.facility_id} acc={userAcc} obligation={u?.docs?.contract?.obligation} signing={userAcc?.sign} date={userAcc?.date}
                                        />
                                        }
                                        {!u?.adult &&
                                            <ParentStatement 
                                                user={u} parent={userAcc?.parent} acc={userAcc} signing={userAcc?.sign} date={userAcc?.date}
                                            />
                                        }
                                        <RulesStatement 
                                            parent={userAcc?.parent?.displayName} facility={userAcc?.facility_id} signing={userAcc?.sign} date={userAcc?.date}
                                        />
                                    </>
                                    :
                                    <>
                                        {!u?.adult ?
                                        <ParentStatement 
                                            user={u} parent={userAcc?.parent} acc={userAcc} signing={userAcc?.sign} date={userAcc?.date}
                                        />
                                        :
                                            <h3 style={({fontSize: "18px", color: "rgb(30,30,30)", marginBottom: "120px"})}>Na ten moment nie masz do podpisania żadnej dokumentacji</h3>
                                        }  
                                    </>
                                )}
                            </div>
                        }
                    </div>
                </div>
        }
      </div>
    );
  }