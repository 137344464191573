import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { collection, doc, onSnapshot, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { Link, useParams } from 'react-router-dom';
import Statue from '../components/statue';
import firebase from 'firebase/compat/app';
import { signInWithEmailAndPassword } from 'firebase/auth';
import CanvasDraw from "react-canvas-draw";
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [message, setMessage] = useState('');

  const params = useParams()

  const [state, setState] = useState(0)
  const [data, setData] = useState()

  const [pack, setPack] = useState(false)

  const [facility, setFacility] = useState([])
  const [groups, setGroups] = useState("")
  const [locations, setLocations] = useState("")

  const canvasRef = useRef(null);

  useEffect(() => {
    const getFacility= onSnapshot(doc(db, "facilities", params?.fid), (snapshot) => {
      setFacility(snapshot.data());
    });
  }, []);

  useEffect(() => {
    if (params?.fid) {
      const getLocations = onSnapshot(doc(db, 'locations', params.fid), (snapshot) => {
        const data = snapshot.data();

        if (data) {
          const locs = params?.lid?.split('-') || [];
          const selected = locs
            .map(loc => Object.values(data).find(x => x.id === loc))
            .filter(Boolean);
          setLocations(selected);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (params?.fid) {
      const getGroups = onSnapshot(doc(db, 'groups', params.fid), (snapshot) => {
        const data = snapshot.data();

        if (data) {
          const groups = params?.gid?.split('-') || [];
          const selected = groups
            .map(gr => Object.values(data).find(x => x.id === gr))
            .filter(Boolean);
          setGroups(selected);
        }
      });
    }
  }, []);

  const [error, setError] = useState("")

  const verifyData = (sub, state) => {

    if (!sub?.displayName || sub?.displayName?.length < 5 || !sub?.displayName?.includes(" ")) {
      setError("Imię i nazwisko zostało nieprawidłowo podane")
      return
    }

    if (!sub?.dateOfBirth) {
      setError("Data urodzenia została nieprawidłowo podane")
      return
    }

    if (!sub?.pesel) {
      setError("Nie podano numeru PESEL")
      return
    }

    if (sub?.pesel?.length !== 11) {
      setError("Podany numer PESEL jest nieprawidłowy")
      return
    }

    if (!sub?.address?.city || !sub?.address?.street) {
      setError("Nie podano adresu zamieszkania")
      return
    }

    if (!sub?.address?.postal) {
      setError("Nie podano kodu pocztowego")
      return
    }

    if (sub?.address?.postal?.length !== 6 || !sub?.address?.postal?.includes("-")) {
      setError("Podany kod pocztowy jest niepoprawny, podany musi być w formacie XX-XXX")
      return
    }

    if (sub?.groups?.length < 1) {
      setError("Nie wybrano grupy dla uczestnika")
      return
    }

    if (Math.floor((new Date() - new Date(sub?.dateOfBirth).getTime()) / 3.15576e+10) > 18) {
      if (sub?.phone?.length !== 9) {
        setError("Podaj numer telefonu w formacie 9 cyfrowym")
        return
      }
    }

    if (state === 2) {
      setState(2)
    }
    else if (state === 3) {
      setState(3)
    }
    else if (state === 4) {
      checkParent()
    }
  }

  const countSubs = () => {
    let count = 0;
    if ('sub1' in data) count++;
    if ('sub2' in data) count++;
    if ('sub3' in data) count++;
    return count;
  };

  const checkParent = () => { 
    
    let adult = false
    for (let i = 0; i < countSubs(); i++) {
      if (Math.floor((new Date() - new Date(data?.[`sub${i+1}`]?.dateOfBirth).getTime()) / 3.15576e+10) > 18) {
        adult = true
      }
    }

    if (adult) {
      setState(5)
    }
    else {
      setState(4)
    }
  }

  const [actualStatue, setActualStatue] = useState(0)

  useEffect(() => {
    if (groups && groups?.filter(x => x?.adults === false)?.length > 0) setActualStatue(1)
    if (groups && groups?.filter(x => x?.adults === true)?.length > 0) setActualStatue(0)  
  }, [groups]);

  const schoolGroupCheck = () => {
    let b = false
    for (let i = 0; i < groups?.length; i++) {
        if (groups[i]?.school === true) {
          b = true
        }   
    }

    return b
  }

  const weekDayConvert = (day) => {
    if (day === 1) return "Poniedziałek"
    if (day === 2) return "Wtorek"
    if (day === 3) return "Środa"
    if (day === 4) return "Czwartek"
    if (day === 5) return "Piątek"
    if (day === 6) return "Sobota"
  }

  useEffect(() => {
    if (state === 1) {
      if (groups?.length === 1) {
        setData({...data, sub1: {...data?.sub1, groups: [groups[0]?.id]}})
      }
    }
    if (state === 2) {
      if (groups?.length === 1) {
        setData({...data, sub2: {...data?.sub2, groups: [groups[0]?.id]}})
      }
    }
    if (state === 3) {
      if (groups?.length === 1) {
        setData({...data, sub3: {...data?.sub3, groups: [groups[0]?.id]}})
      }
    }
  }, [state]);

  useEffect(() => {
    setTimeout(() => {
        setError(false)
    }, 5000)
}, [error]);

const verifyParent = () => {
  if (!data?.parent?.displayName || data?.parent?.displayName?.length < 5 || !data?.parent?.displayName?.includes(" ")) {
    setError("Imię i nazwisko zostało nieprawidłowo podane")
    return
  }

  if (!data?.parent?.dateOfBirth) {
    setError("Data urodzenia została nieprawidłowo podane")
    return
  }

  if (!Math.floor((new Date() - new Date(data?.parent?.dateOfBirth).getTime()) / 3.15576e+10) > 18) {
    setError("Opiekun musi być pełnoletni")
    return
  }

  if (!data?.parent?.address?.city || !data?.parent?.address?.street) {
    setError("Nie podano adresu zamieszkania")
    return
  }

  if (!data?.parent?.address?.postal) {
    setError("Nie podano kodu pocztowego")
    return
  }

  if (data?.parent?.address?.postal?.length !== 6 || !data?.parent?.address?.postal?.includes("-")) {
    setError("Podany kod pocztowy jest niepoprawny, podany musi być w formacie XX-XXX")
    return
  }

  if (data?.parent?.phone?.length !== 9) {
    setError("Podaj numer telefonu w formacie 9 cyfrowym")
    return
  }

  setState(6)
}

const [passEyes, setPassEyes] = useState("")

const createAcc = async () => {
  if (data?.password !== data?.confirmPass) {
    setError("Hasła nie są takie same")
    return
  }
  try {
    setAnimation(true)
    const response = await fetch('https://europe-central2-modit-system.cloudfunctions.net/createUser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: data?.email,
        password: data?.password,
        additionalData: {
          parent: data?.parent,
          subUsers: {
            ...(data?.sub1 && { 0: { ...data.sub1, index: 0, toVerificate: true} }),
            ...(data?.sub2 && { 1: { ...data.sub2, index: 1, toVerificate: true } }), 
            ...(data?.sub3 && { 2: { ...data.sub3, index: 2, toVerificate: true} })  
          },
          facility_id: params?.fid,
        },
      }),
    });

    const x = await response.json();
    if (x?.uid) {
      signInWithEmailAndPassword(auth, data?.email, data?.password)
      setState(state + 1)
      setAnimation(false)
    }
  } catch (error) {
    setError("Niestety nie udało założyć się konta, skontaktuj się z nami lub spróbuj ponownie.")
  }
};

const setPrices = () => {
  setDoc(doc(db, "users", `${auth?.currentUser?.uid}`), {
    subUsers: {
      ...(data?.sub1 && { 0: { ...data.sub1, index: 0 } }),
      ...(data?.sub2 && { 1: { ...data.sub2, index: 1 } }), 
      ...(data?.sub3 && { 2: { ...data.sub3, index: 2 } })  
    }
  }, {merge: true})

  setState(state + 1)
}

// const checkIfSigned = () => {
//   const saveData = canvasRef.current.getSaveData();
//   if (saveData && saveData.length > 10) {
//     setDoc(doc(db, "users", `${auth?.currentUser?.uid}`), {
//       sign: canvasRef?.current?.getDataURL(),
//     }, {merge: true})
//   } else {
//     setError("Podpis nie został złożony")
//   }
// };

//  const [docs, setDocs] = useState("")

// useEffect(() => {
//   if (state === 8) {
//     let contract = false;
//     let obligation = false;
//     let adultContract = false;
//     let contractJastrzebie = false;
//     let parent = false

//     for (let i = 0; i < countSubs(); i++) {
//       for (let x = 0; x < Object?.values(data?.[`sub${i+1}`]?.prices)?.length; x++) {
//         if (Object?.values(data?.[`sub${i+1}`]?.prices)[x] === "contract") {
//           if (params?.fid === "ExDQJ9bhXg5XUkiZta78") {
//             for (let y = 0; y < Object?.keys(data?.[`sub${i+1}`].groups)?.length; y++) {
//               if (groups?.filter(x => x?.id === Object?.keys(data?.[`sub${i+1}`].groups)[y])[0]?.adults === true) {
//                 adultContract = true
//               }
//               else {
//                 contract = true
//                 parent = true
//               }
//             }
//           }
//           else if (params?.fid === "cfh66CW4jxFRMkU9AT5y") {
//             for (let y = 0; y < Object?.keys(data?.[`sub${i+1}`].groups)?.length; y++) {
//               if (groups?.filter(x => x?.id === Object?.keys(data?.[`sub${i+1}`].groups)[y])[0]?.adults === true) {
//                 adultContract = true
//               }
//               else {
//                 contractJastrzebie = true
//                 parent = true
//               }
//             }
//           }
//         }
//         else if (Object?.values(data?.[`sub${i+1}`]?.prices)[x] === "obligation") {
//           parent = true
//           obligation = true
//         }
//       }
//     } 

//     let list = ["club"]

//     if (contract) {
//       list?.push("contract")
//     }
//     if (obligation) {
//       list?.push("obligation")
//     }
//     if (contractJastrzebie) {
//       list?.push("contractJastrzebie")
//     }
//     if (adultContract) {
//       list?.push("adultContract")
//     }
//     if (parent) {
//       list?.push("parent")
//     }

//     setDocs(list)
//   }
// }, [state]);

const [animation, setAnimation] = useState(false)

  return (
    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", backgroundColor: "rgb(250,250,250)", width: "100%", minHeight: "100vh", overflowY: "scroll", gap: "30px"})}>
      <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "90%", gap: "15px", marginBottom: "50px", position: "relative"})}>
      <img src={process.env.PUBLIC_URL + "/logosvg.svg"} style={({width: "120px", marginBottom: "-30px"})} />
      <div style={({display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", position: "fixed", zIndex: "40", top: error ? "45px" : "-300px", transform: "translate(-50%, -50%)", left: "50%", backgroundColor: "rgb(250,250,250)", width: "320px", height: "75px", transition: "0.8s", borderRadius: "15px", border: "3px solid rgb(215,0,64)", gap: "20px"})}>
        <img src={process.env.PUBLIC_URL + "/error.png"} style={({width: "30px"})} />
        <h3 style={({fontSize: "13px", fontWeight: "bold", color: "rgb(215,0,64)", textAlign: "center", width: "75%"})}>{error}</h3>
      </div>
        {state === 0 &&
          <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "90%", gap: "35px", marginBottom: "50px"})}>
              <h3 style={({color: "rgb(30,30,30)", fontSize: "27px", textAlign: "center"})}>Zaakceptuj regulamin</h3>
              <h3 style={({color: "rgb(30,30,30)", fontSize: "13px", fontWeight: "normal", textAlign: "center", marginTop: "-20px"})}>Zapoznaj się z regulaminiem 4STARS Dance&Aerial Studio i zaakceptuj go, aby przejść dalej.</h3>
              <div style={({display: "flex", flexDirection: "row", gap: "15px", alignItems: "center", justifyContent: "center", backgroundColor: "rgb(250,250,250)", borderRadius: "10px", marginTop: "10px", marginBottom: "-10px"})}>
                  {groups && groups?.filter(x => x?.adults === true)?.length > 0 &&
                      <div style={({display: "flex", flexDirection: "row", gap: "5px", alignItems: "center", justifyContent: "center", borderBottom: actualStatue === 0 ? "2px solid #0045C7" : "2px solid rgb(250,250,250)", padding: "3px 5px", cursor: "pointer", transition: "0.35s"})} onClick={() => setActualStatue(0)}>
                          <h3 style={({fontSize: "14px", color: actualStatue === 0 ? "#0045C7" : "rgb(20,20,20)", transition: "0.35s", textAlign: "center"})}>Regulamin dot. grupy dla dorosłych</h3>
                      </div>
                  }
                  {groups && groups?.filter(x => x?.adults === false)?.length > 0 &&
                      <div style={({display: "flex", flexDirection: "row", gap: "5px", alignItems: "center", justifyContent: "center", borderBottom: actualStatue === 1 ? "2px solid #0045C7" : "2px solid rgb(250,250,250)", padding: "3px 5px", cursor: "pointer", transition: "0.35s"})} onClick={() => setActualStatue(1)}>
                          <h3 style={({fontSize: "14px", color: actualStatue === 1 ? "#0045C7" : "rgb(20,20,20)", transition: "0.35s", textAlign: "center"})}>Regulamin dot. grupy dla dzieci</h3>
                      </div>
                  }
              </div>
              <Statue id="regulamin" version={actualStatue} place={params?.fid}/>
              <div style={({display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "8px"})}>
                  <div style={({display: "flex", justifyContent: "center", alignItems: "center", border: "3px solid #0045C7", minWidth: "16px", maxWidth: "16px", height: "16px", borderRadius: "15px", cursor: "pointer"})} onClick={() => setData({...data, statue: !data?.statue})}>
                      <img src={process.env.PUBLIC_URL + "/done.png"} style={({width: data?.statue ? "12px" : "0px", transition: "0.4s"})}/>
                  </div>
                  <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Oświadczam, że zapoznałem/łam się z regulaminem 4STARS Dance&Aerial Studio i w pełni go akceptuje.</h3>
              </div>
              <button style={({fontSize: "13px", color: "white", borderRadius: "5px", marginTop: "20px", backgroundColor: "#0045C7", border: "2px solid #0045C7", transition: "0.7s", fontWeight: "bold", padding: "10px 30px"})} onClick={() => setState(state + 1)} disabled={!data?.statue}> Przejdź dalej</button>
          </div>
        }
        {state === 1 &&
          <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "90%", gap: "15px", marginBottom: "50px"})}>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "27px", textAlign: "center"})}>Dodaj uczestnika zajęć</h3>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "13px", fontWeight: "normal", textAlign: "center"})}>Dodaj uczestnika zajęć, siebie lub dziecko, które będzie chodzić na zajęcia.</h3>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "12px", fontWeight: "normal", textAlign: "center", marginTop: "-10px"})}><b>UWAGA!</b> Nie podawaj swoich danych, jeżeli nie będziesz chodzić do grupy dla dorosłych, podaj dane swojego dziecka</h3>
            <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "30px", padding: "30px", backgroundColor: "rgb(250,250,250)", borderRadius: "15px"})}>
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                    <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Imię i nazwisko</h3>
                    <input className="login" type="text" placeholder="np. Anna Kowalska" style={({height: "20px", width: "275px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/personalData.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub1: {...data.sub1, displayName: e.target.value}}); setError(false)}}/>
                </div>
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                    <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Data urodzenia</h3>
                    <input className="login" type="date" style={({height: "20px", width: "275px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/birthday.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub1: {...data.sub1, dateOfBirth: e.target.value}}); setError(false)}}/>
                </div>
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                    <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Numer PESEL</h3>
                    <input placeholder="np. 00121126331" className="login" type="text" style={({height: "20px", width: "275px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/lock.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub1: {...data.sub1, pesel: e.target.value}}); setError(false)}}/>
                </div>
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "20px"})}>
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                        <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Ulica zamieszkania</h3>
                        <input className="login" type="text" placeholder="np. 11 Listopada 20" style={({height: "20px", width: "155px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/address.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub1: {...data.sub1, address: {...data?.sub1?.address, street: e.target.value}}}); setError(false)}}/>
                    </div>
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                        <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Numer lokalu</h3>
                        <input className="login" type="text" placeholder="np. 50B" style={({textAlign: "center", height: "20px", width: "75px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em 12px", color: "rgb(20,20,20)", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub1: {...data.sub1, address: {...data?.sub1?.address, local: e.target.value}}}); setError(false)}}/>
                    </div>
                </div>
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "20px"})}>
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                        <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Miasto</h3>
                        <input className="login" type="text" placeholder="np. Wrocław" style={({height: "20px", width: "155px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/pin.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub1: {...data.sub1, address: {...data?.sub1?.address, city: e.target.value}}}); setError(false)}}/>
                    </div>
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                        <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Kod pocztowy</h3>
                        <input className="login" type="text" placeholder="np. 50-001" style={({textAlign: "center", height: "20px", width: "75px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em 12px", color: "rgb(20,20,20)", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub1: {...data.sub1, address: {...data?.sub1?.address, postal: e.target.value}}}); setError(false)}}/>
                    </div>
                </div>
                {schoolGroupCheck() && <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Czy dziecko ma być przez nas odbierane na zajęcia?</h3>}
                {schoolGroupCheck() &&
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "12px"})}>
                        <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                            <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Odpowiedź Tak / Nie</h3>
                            <input className="login" type="text" placeholder="Tak / Nie" style={({height: "20px", width: "107px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/checkMark.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub1: {...data.sub1, pickUp: e.target.value}}); setError(false)}}/>
                        </div>
                        <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                            <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Klasa / grupa dziecka</h3>
                            <input className="login" type="text" placeholder="np. 1A / gr. Sówki" style={({height: "20px", width: "107px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/group.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub1: {...data.sub1, class: e.target.value}}); setError(false)}}/>
                        </div>
                    </div>
                }
                {(data?.sub1 && data?.sub1?.dateOfBirth && Math.floor((new Date() - new Date(data?.sub1?.dateOfBirth).getTime()) / 3.15576e+10) > 18) &&
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                        <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Numer telefonu</h3>
                        <input placeholder="np. 501999000" className="login" type="text" style={({height: "20px", width: "275px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/phone.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub1: {...data.sub1, phone: e.target.value}}); setError(false)}}/>
                    </div>
                }
                {groups && groups?.length > 1 && <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Wybierz grupy dla uczestnika</h3>}
                {groups && groups?.length > 1 && groups?.map((g) =>
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "10px", width: "300px", padding: '15px', border: data?.sub1?.groups?.includes(g?.id) ? "1px solid #0045C7" : "1px solid rgb(20,20,20)", borderRadius: "5px", transition: "0.4s", marginTop: "-15px"})} onClick={() => setData({...data, sub1: {...data?.sub1, groups: data?.sub1?.groups?.length > 0 ? data?.sub1?.groups?.includes(g?.id) ? data?.sub1?.groups?.filter(x => x !== g?.id) : [...data?.sub1?.groups, g?.id] : [g?.id]}})}>
                        <div style={({display: "flex", justifyContent: "center", alignItems: "center", border: data?.sub1?.groups?.includes(g?.id) ? "2px solid #0045C7" : "2px solid rgb(20,20,20)", minWidth: "16px", minHeight: "16px", borderRadius: "5px", cursor: "pointer"})} >
                            <img src={process.env.PUBLIC_URL + "/done.png"} style={({width: data?.sub1?.groups?.includes(g?.id) ? "10px" : "0px", transition: "0.4s"})}/>
                        </div>
                        <h3 style={({fontSize: "11px", color: data?.sub1?.groups?.includes(g?.id) ? "#0045C7" : "rgb(20,20,20)"})}>{locations?.filter(x => x?.id === g?.location_id)[0]?.name} - {g?.days[0]?.custom_name ? g?.days[0]?.custom_name : g?.type + " - " + weekDayConvert(g?.days[0]?.weekDay) + " " + g?.days[0]?.startTime + " - " +  g?.days[0]?.endTime}</h3>
                    </div>
                )}
                {groups && groups?.length === 1 && groups?.map((g) =>
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "10px", width: "300px", padding: '15px', border: data?.sub1?.groups?.includes(g?.id) ? "1px solid #0045C7" : "1px solid rgb(20,20,20)", borderRadius: "5px", transition: "0.4s", marginTop: "-5px"})}>
                        <div style={({display: "flex", justifyContent: "center", alignItems: "center", border: data?.sub1?.groups?.includes(g?.id) ? "2px solid #0045C7" : "2px solid rgb(20,20,20)", minWidth: "16px", minHeight: "16px", borderRadius: "5px", cursor: "pointer"})} >
                            <img src={process.env.PUBLIC_URL + "/done.png"} style={({width: data?.sub1?.groups?.includes(g?.id) ? "10px" : "0px", transition: "0.4s"})}/>
                        </div>
                        <h3 style={({fontSize: "11px", color: data?.sub1?.groups?.includes(g?.id) ? "#0045C7" : "rgb(20,20,20)"})}>{locations?.filter(x => x?.id === g?.location_id)[0]?.name} - {g?.days[0]?.custom_name ? g?.days[0]?.custom_name : g?.type + " - " + weekDayConvert(g?.days[0]?.weekDay) + " " + g?.days[0]?.startTime + " - " +  g?.days[0]?.endTime}</h3>
                    </div>
                )}
            </div>
            <button style={({width: "200px", minHeight: "35px", fontSize: "13px", color: "white", borderRadius: "5px"})} className="button-black" onClick={() => verifyData(data?.sub1, 4)}>Przejdź dalej</button>
            <button style={({width: "200px", minHeight: "35px", fontSize: "13px", color: "white", borderRadius: "5px"})} className="button-black" onClick={() => verifyData(data?.sub1, 2)}>Dodaj drugiego uczestnika</button>
          </div>
        }
        {state === 2 &&
          <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "90%", gap: "15px", marginBottom: "50px"})}>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "27px", textAlign: "center"})}>Dodaj drugiego uczestnika zajęć</h3>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "13px", fontWeight: "normal", textAlign: "center"})}>Dodaj drugiego uczestnika zajęć, siebie lub dziecko, które będzie chodzić na zajęcia.</h3>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "12px", fontWeight: "normal", textAlign: "center", marginTop: "-10px"})}><b>UWAGA!</b> Nie podawaj swoich danych, jeżeli nie będziesz chodzić do grupy dla dorosłych, podaj dane swojego dziecka</h3>
            <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "30px", padding: "30px", backgroundColor: "rgb(250,250,250)", borderRadius: "15px"})}>
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                    <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Imię i nazwisko</h3>
                    <input className="login" type="text" placeholder="np. Anna Kowalska" style={({height: "20px", width: "275px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/personalData.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub2: {...data.sub2, displayName: e.target.value}}); setError(false)}}/>
                </div>
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                    <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Data urodzenia</h3>
                    <input className="login" type="date" style={({height: "20px", width: "275px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/birthday.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub2: {...data.sub2, dateOfBirth: e.target.value}}); setError(false)}}/>
                </div>
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                    <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Numer PESEL</h3>
                    <input placeholder="np. 00121126331" className="login" type="text" style={({height: "20px", width: "275px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/lock.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub2: {...data.sub2, pesel: e.target.value}}); setError(false)}}/>
                </div>
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "20px"})}>
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                        <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Ulica zamieszkania</h3>
                        <input className="login" type="text" placeholder="np. 11 Listopada 20" style={({height: "20px", width: "155px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/address.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub2: {...data.sub2, address: {...data?.sub2?.address, street: e.target.value}}}); setError(false)}}/>
                    </div>
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                        <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Numer lokalu</h3>
                        <input className="login" type="text" placeholder="np. 50B" style={({textAlign: "center", height: "20px", width: "75px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em 12px", color: "rgb(20,20,20)", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub2: {...data.sub2, address: {...data?.sub2?.address, local: e.target.value}}}); setError(false)}}/>
                    </div>
                </div>
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "20px"})}>
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                        <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Miasto</h3>
                        <input className="login" type="text" placeholder="np. Wrocław" style={({height: "20px", width: "155px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/pin.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub2: {...data.sub2, address: {...data?.sub2?.address, city: e.target.value}}}); setError(false)}}/>
                    </div>
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                        <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Kod pocztowy</h3>
                        <input className="login" type="text" placeholder="np. 50-001" style={({textAlign: "center", height: "20px", width: "75px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em 12px", color: "rgb(20,20,20)", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub2: {...data.sub2, address: {...data?.sub2?.address, postal: e.target.value}}}); setError(false)}}/>
                    </div>
                </div>
                {schoolGroupCheck() && <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Czy dziecko ma być przez nas odbierane na zajęcia?</h3>}
                {schoolGroupCheck() &&
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "12px"})}>
                        <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                            <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Odpowiedź Tak / Nie</h3>
                            <input className="login" type="text" placeholder="Tak / Nie" style={({height: "20px", width: "107px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/checkMark.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub2: {...data.sub2, pickUp: e.target.value}}); setError(false)}}/>
                        </div>
                        <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                            <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Klasa / grupa dziecka</h3>
                            <input className="login" type="text" placeholder="np. 1A / gr. Sówki" style={({height: "20px", width: "107px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/group.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub2: {...data.sub2, class: e.target.value}}); setError(false)}}/>
                        </div>
                    </div>
                }
                {(data?.sub2 && data?.sub2?.dateOfBirth && Math.floor((new Date() - new Date(data?.sub2?.dateOfBirth).getTime()) / 3.15576e+10) > 18) &&
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                        <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Numer telefonu</h3>
                        <input placeholder="np. 501999000" className="login" type="text" style={({height: "20px", width: "275px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/phone.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub2: {...data.sub2, phone: e.target.value}}); setError(false)}}/>
                    </div>
                }
                {groups && groups?.length > 1 && <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Wybierz grupy dla uczestnika</h3>}
                {groups && groups?.length > 1 && groups?.map((g) =>
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "10px", width: "300px", padding: '15px', border: data?.sub2?.groups?.includes(g?.id) ? "1px solid #0045C7" : "1px solid rgb(20,20,20)", borderRadius: "5px", transition: "0.4s", marginTop: "-15px"})} onClick={() => setData({...data, sub2: {...data?.sub2, groups: data?.sub2?.groups?.length > 0 ? data?.sub2?.groups?.includes(g?.id) ? data?.sub2?.groups?.filter(x => x !== g?.id) : [...data?.sub2?.groups, g?.id] : [g?.id]}})}>
                        <div style={({display: "flex", justifyContent: "center", alignItems: "center", border: data?.sub2?.groups?.includes(g?.id) ? "2px solid #0045C7" : "2px solid rgb(20,20,20)", minWidth: "16px", minHeight: "16px", borderRadius: "5px", cursor: "pointer"})} >
                            <img src={process.env.PUBLIC_URL + "/done.png"} style={({width: data?.sub2?.groups?.includes(g?.id) ? "10px" : "0px", transition: "0.4s"})}/>
                        </div>
                        <h3 style={({fontSize: "11px", color: data?.sub2?.groups?.includes(g?.id) ? "#0045C7" : "rgb(20,20,20)"})}>{locations?.filter(x => x?.id === g?.location_id)[0]?.name}- {g?.days[0]?.custom_name ? g?.days[0]?.custom_name : g?.type + " - " + weekDayConvert(g?.days[0]?.weekDay) + " " + g?.days[0]?.startTime + " - " +  g?.days[0]?.endTime}</h3>
                    </div>
                )}
                {groups && groups?.length === 1 && groups?.map((g) =>
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "10px", width: "300px", padding: '15px', border: data?.sub2?.groups?.includes(g?.id) ? "1px solid #0045C7" : "1px solid rgb(20,20,20)", borderRadius: "5px", transition: "0.4s", marginTop: "-5px"})}>
                        <div style={({display: "flex", justifyContent: "center", alignItems: "center", border: data?.sub2?.groups?.includes(g?.id) ? "2px solid #0045C7" : "2px solid rgb(20,20,20)", minWidth: "16px", minHeight: "16px", borderRadius: "5px", cursor: "pointer"})} >
                            <img src={process.env.PUBLIC_URL + "/done.png"} style={({width: data?.sub2?.groups?.includes(g?.id) ? "10px" : "0px", transition: "0.4s"})}/>
                        </div>
                        <h3 style={({fontSize: "11px", color: data?.sub2?.groups?.includes(g?.id) ? "#0045C7" : "rgb(20,20,20)"})}>{locations?.filter(x => x?.id === g?.location_id)[0]?.name} - {g?.days[0]?.custom_name ? g?.days[0]?.custom_name : g?.type + " - " + weekDayConvert(g?.days[0]?.weekDay) + " " + g?.days[0]?.startTime + " - " +  g?.days[0]?.endTime}</h3>
                    </div>
                )}
            </div>
            <button style={({width: "200px", minHeight: "35px", fontSize: "13px", color: "white", borderRadius: "5px"})} className="button-black" onClick={() => verifyData(data?.sub2, 4)}>Przejdź dalej</button>
            <button style={({width: "200px", minHeight: "35px", fontSize: "13px", color: "white", borderRadius: "5px"})} className="button-black" onClick={() => verifyData(data?.sub2, 3)}>Dodaj drugiego uczestnika</button>
          </div>
        }
        {state === 3 &&
          <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "90%", gap: "15px", marginBottom: "50px"})}>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "27px", textAlign: "center"})}>Dodaj trzeciego uczestnika zajęć</h3>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "13px", fontWeight: "normal", textAlign: "center"})}>Dodaj trzeciego uczestnika zajęć, siebie lub dziecko, które będzie chodzić na zajęcia.</h3>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "12px", fontWeight: "normal", textAlign: "center", marginTop: "-10px"})}><b>UWAGA!</b> Nie podawaj swoich danych, jeżeli nie będziesz chodzić do grupy dla dorosłych, podaj dane swojego dziecka</h3>
            <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "30px", padding: "30px", backgroundColor: "rgb(250,250,250)", borderRadius: "15px"})}>
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                    <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Imię i nazwisko</h3>
                    <input className="login" type="text" placeholder="np. Anna Kowalska" style={({height: "20px", width: "275px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/personalData.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub3: {...data.sub3, displayName: e.target.value}}); setError(false)}}/>
                </div>
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                    <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Data urodzenia</h3>
                    <input className="login" type="date" style={({height: "20px", width: "275px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/birthday.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub3: {...data.sub3, dateOfBirth: e.target.value}}); setError(false)}}/>
                </div>
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                    <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Numer PESEL</h3>
                    <input placeholder="np. 00121126331" className="login" type="text" style={({height: "20px", width: "275px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/lock.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub3: {...data.sub3, pesel: e.target.value}}); setError(false)}}/>
                </div>
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "20px"})}>
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                        <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Ulica zamieszkania</h3>
                        <input className="login" type="text" placeholder="np. 11 Listopada 20" style={({height: "20px", width: "155px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/address.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub3: {...data.sub3, address: {...data?.sub3?.address, street: e.target.value}}}); setError(false)}}/>
                    </div>
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                        <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Numer lokalu</h3>
                        <input className="login" type="text" placeholder="np. 50B" style={({textAlign: "center", height: "20px", width: "75px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em 12px", color: "rgb(20,20,20)", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub3: {...data.sub3, address: {...data?.sub3?.address, local: e.target.value}}}); setError(false)}}/>
                    </div>
                </div>
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "20px"})}>
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                        <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Miasto</h3>
                        <input className="login" type="text" placeholder="np. Wrocław" style={({height: "20px", width: "155px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/pin.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub3: {...data.sub3, address: {...data?.sub3?.address, city: e.target.value}}}); setError(false)}}/>
                    </div>
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                        <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Kod pocztowy</h3>
                        <input className="login" type="text" placeholder="np. 50-001" style={({textAlign: "center", height: "20px", width: "75px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em 12px", color: "rgb(20,20,20)", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub3: {...data.sub3, address: {...data?.sub3?.address, postal: e.target.value}}}); setError(false)}}/>
                    </div>
                </div>
                {schoolGroupCheck() && <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Czy dziecko ma być przez nas odbierane na zajęcia?</h3>}
                {schoolGroupCheck() &&
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "12px"})}>
                        <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                            <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Odpowiedź Tak / Nie</h3>
                            <input className="login" type="text" placeholder="Tak / Nie" style={({height: "20px", width: "107px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/checkMark.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub3: {...data.sub3, pickUp: e.target.value}}); setError(false)}}/>
                        </div>
                        <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                            <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Klasa / grupa dziecka</h3>
                            <input className="login" type="text" placeholder="np. 1A / gr. Sówki" style={({height: "20px", width: "107px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/group.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub3: {...data.sub3, class: e.target.value}}); setError(false)}}/>
                        </div>
                    </div>
                }
                {(data?.sub3 && data?.sub3?.dateOfBirth && Math.floor((new Date() - new Date(data?.sub3?.dateOfBirth).getTime()) / 3.15576e+10) > 18) &&
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                        <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Numer telefonu</h3>
                        <input placeholder="np. 501999000" className="login" type="text" style={({height: "20px", width: "275px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/phone.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, sub3: {...data.sub3, phone: e.target.value}}); setError(false)}}/>
                    </div>
                }
                {groups && groups?.length > 1 && <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Wybierz grupy dla uczestnika</h3>}
                {groups && groups?.length > 1 && groups?.map((g) =>
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "10px", width: "300px", padding: '15px', border: data?.sub3?.groups?.includes(g?.id) ? "1px solid #0045C7" : "1px solid rgb(20,20,20)", borderRadius: "5px", transition: "0.4s", marginTop: "-15px"})} onClick={() => setData({...data, sub3: {...data?.sub3, groups: data?.sub3?.groups?.length > 0 ? data?.sub3?.groups?.includes(g?.id) ? data?.sub3?.groups?.filter(x => x !== g?.id) : [...data?.sub3?.groups, g?.id] : [g?.id]}})}>
                        <div style={({display: "flex", justifyContent: "center", alignItems: "center", border: data?.sub3?.groups?.includes(g?.id) ? "2px solid #0045C7" : "2px solid rgb(20,20,20)", minWidth: "16px", minHeight: "16px", borderRadius: "5px", cursor: "pointer"})} >
                            <img src={process.env.PUBLIC_URL + "/done.png"} style={({width: data?.sub3?.groups?.includes(g?.id) ? "10px" : "0px", transition: "0.4s"})}/>
                        </div>
                        <h3 style={({fontSize: "11px", color: data?.sub3?.groups?.includes(g?.id) ? "#0045C7" : "rgb(20,20,20)"})}>{locations?.filter(x => x?.id === g?.location_id)[0]?.name} - {g?.days[0]?.custom_name ? g?.days[0]?.custom_name : g?.type + " - " + weekDayConvert(g?.days[0]?.weekDay) + " " + g?.days[0]?.startTime + " - " +  g?.days[0]?.endTime}</h3>
                    </div>
                )}
                {groups && groups?.length === 1 && groups?.map((g) =>
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "10px", width: "300px", padding: '15px', border: data?.sub3?.groups?.includes(g?.id) ? "1px solid #0045C7" : "1px solid rgb(20,20,20)", borderRadius: "5px", transition: "0.4s", marginTop: "-5px"})}>
                        <div style={({display: "flex", justifyContent: "center", alignItems: "center", border: data?.sub3?.groups?.includes(g?.id) ? "2px solid #0045C7" : "2px solid rgb(20,20,20)", minWidth: "16px", minHeight: "16px", borderRadius: "5px", cursor: "pointer"})} >
                            <img src={process.env.PUBLIC_URL + "/done.png"} style={({width: data?.sub3?.groups?.includes(g?.id) ? "10px" : "0px", transition: "0.4s"})}/>
                        </div>
                        <h3 style={({fontSize: "11px", color: data?.sub3?.groups?.includes(g?.id) ? "#0045C7" : "rgb(20,20,20)"})}>{locations?.filter(x => x?.id === g?.location_id)[0]?.name} - {g?.days[0]?.custom_name ? g?.days[0]?.custom_name : g?.type + " - " + weekDayConvert(g?.days[0]?.weekDay) + " " + g?.days[0]?.startTime + " - " +  g?.days[0]?.endTime}</h3>
                    </div>
                )}
            </div>
            <button style={({width: "200px", minHeight: "35px", fontSize: "13px", color: "white", borderRadius: "5px"})} className="button-black" onClick={() => verifyData(data?.sub3, 4)}>Przejdź dalej</button>
          </div>
        }
        {state === 4 &&
          <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "90%", gap: "15px", marginBottom: "50px"})}>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "27px", textAlign: "center"})}>Dodaj rodzica / opiekuna</h3>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "13px", fontWeight: "normal", textAlign: "center"})}>Co najmniej jedna z osób została podana jako niepełnoletnia</h3>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "12px", fontWeight: "normal", textAlign: "center", marginTop: "-10px"})}>Jeżeli rodzic / opiekun również będzie uczęszczać na zajęcia i został on wpisany wcześniej, prosimy sprawdzić, czy wyświetlane dane są poprawne, a razie potrzeby, zmienić je</h3>
            <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "30px", padding: "30px", backgroundColor: "rgb(250,250,250)", borderRadius: "15px"})}>
              <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Imię i nazwisko</h3>
                <input defaultValue={data?.parent?.displayName && data?.parent?.displayName} className="login" type="text" placeholder="np. Anna Kowalska" style={({height: "20px", width: "275px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/personalData.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, parent: {...data.parent, displayName: e.target.value}}); setError(false)}}/>
              </div>
              <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Data urodzenia</h3>
                <input defaultValue={data?.parent?.dateOfBirth && data?.parent?.dateOfBirth} className="login" type="date" style={({height: "20px", width: "275px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/birthday.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, parent: {...data.parent, dateOfBirth: e.target.value}}); setError(false)}}/>
              </div>
              <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Numer telefonu</h3>
                <input defaultValue={data?.parent?.phone && data?.parent?.phone} placeholder="np. 501999000" className="login" type="text" style={({height: "20px", width: "275px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/phone.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, parent: {...data.parent, phone: e.target.value}}); setError(false)}}/>
              </div>
              <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "20px"})}>
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                  <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Ulica zamieszkania</h3>
                  <input defaultValue={data?.parent?.address?.line1 && data?.parent?.address?.line1} className="login" type="text" placeholder="np. 11 Listopada 20" style={({height: "20px", width: "155px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/address.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, parent: {...data.parent, address: {...data?.parent?.address, street: e.target.value}}}); setError(false)}}/>
                </div>
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                  <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Numer lokalu</h3>
                  <input defaultValue={data?.parent?.address?.line2 && data?.parent?.address?.line2} className="login" type="text" placeholder="np. 50B" style={({textAlign: "center", height: "20px", width: "75px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em 12px", color: "rgb(20,20,20)", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, parent: {...data.parent, address: {...data?.parent?.address, local: e.target.value}}}); setError(false)}}/>
                </div>
              </div>
              <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "20px"})}>
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                  <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Miasto</h3>
                  <input defaultValue={data?.parent?.address?.city && data?.parent?.address?.city} className="login" type="text" placeholder="np. Wrocław" style={({height: "20px", width: "155px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/pin.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, parent: {...data.parent, address: {...data?.parent?.address, city: e.target.value}}}); setError(false)}}/>
                </div>
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                  <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Kod pocztowy</h3>
                  <input defaultValue={data?.parent?.address?.postal_code && data?.parent?.address?.postal_code} className="login" type="text" placeholder="np. 50-001" style={({textAlign: "center", height: "20px", width: "75px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em 12px", color: "rgb(20,20,20)", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, parent: {...data.parent, address: {...data?.parent?.address, postal: e.target.value}}}); setError(false)}}/>
                </div>
              </div>
            </div>
            <button style={({width: "200px", minHeight: "35px", fontSize: "13px", color: "white", borderRadius: "5px"})} className="button-black" onClick={() => verifyParent()}>Przejdź dalej</button>
          </div>
        }
        {state === 5 &&
          <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "90%", gap: "15px", marginBottom: "50px"})}>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "27px", textAlign: "center"})}>Wybierz opiekuna konta</h3>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "13px", fontWeight: "normal", textAlign: "center"})}>Wybierz jedną z podanych pełnoletnich osób lub dodaj nowego opiekuna.</h3>
            <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "30px", padding: "30px", backgroundColor: "rgb(250,250,250)", borderRadius: "15px"})}>
              {data?.sub1 && Math.floor((new Date() - new Date(data?.sub1?.dateOfBirth).getTime()) / 3.15576e+10) > 18 &&
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "10px", width: "300px", padding: '15px', border: data?.parent === data?.sub1 ? "2px solid #0045C7" : "2px solid rgb(20,20,20)", borderRadius: "5px", transition: "0.4s", marginTop: "-15px"})} onClick={() => setData({...data, parent: data?.parent === data?.sub1 ? "" : data?.sub1})}>
                  <div style={({display: "flex", justifyContent: "center", alignItems: "center", border: data?.parent === data?.sub1 ? "2px solid #0045C7" : "2px solid rgb(20,20,20)", minWidth: "16px", minHeight: "16px", borderRadius: "5px", cursor: "pointer"})} >
                    <img src={process.env.PUBLIC_URL + "/done.png"} style={({width: data?.parent === data?.sub1 ? "10px" : "0px", transition: "0.4s"})}/>
                  </div>
                  <h3 style={({fontSize: "11px", color: data?.parent === data?.sub1 ? "#0045C7" : "rgb(20,20,20)"})}>{data?.sub1?.displayName}</h3>
                </div>
              }
              {data?.sub2 && Math.floor((new Date() - new Date(data?.sub2?.dateOfBirth).getTime()) / 3.15576e+10) > 18 &&
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "10px", width: "300px", padding: '15px', border: data?.parent === data?.sub2 ? "2px solid #0045C7" : "2px solid rgb(20,20,20)", borderRadius: "5px", transition: "0.4s", marginTop: "-15px"})} onClick={() => setData({...data, parent: data?.parent === data?.sub2 ? "" : data?.sub2})}>
                  <div style={({display: "flex", justifyContent: "center", alignItems: "center", border: data?.parent === data?.sub2 ? "2px solid #0045C7" : "2px solid rgb(20,20,20)", minWidth: "16px", minHeight: "16px", borderRadius: "5px", cursor: "pointer"})} >
                    <img src={process.env.PUBLIC_URL + "/done.png"} style={({width: data?.parent === data?.sub2 ? "10px" : "0px", transition: "0.4s"})}/>
                  </div>
                  <h3 style={({fontSize: "11px", color: data?.parent === data?.sub2 ? "#0045C7" : "rgb(20,20,20)"})}>{data?.sub2?.displayName}</h3>
                </div>
              }
              {data?.sub3 && Math.floor((new Date() - new Date(data?.sub3?.dateOfBirth).getTime()) / 3.15576e+10) > 18 &&
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "10px", width: "300px", padding: '15px', border: data?.parent === data?.sub3 ? "2px solid #0045C7" : "2px solid rgb(20,20,20)", borderRadius: "5px", transition: "0.4s", marginTop: "-15px"})} onClick={() => setData({...data, parent: data?.parent === data?.sub3 ? "" : data?.sub3})}>
                  <div style={({display: "flex", justifyContent: "center", alignItems: "center", border: data?.parent === data?.sub3 ? "2px solid #0045C7" : "2px solid rgb(20,20,20)", minWidth: "16px", minHeight: "16px", borderRadius: "5px", cursor: "pointer"})} >
                    <img src={process.env.PUBLIC_URL + "/done.png"} style={({width: data?.parent === data?.sub3 ? "10px" : "0px", transition: "0.4s"})}/>
                  </div>
                  <h3 style={({fontSize: "11px", color: data?.parent === data?.sub3 ? "#0045C7" : "rgb(20,20,20)"})}>{data?.sub3?.displayName}</h3>
                </div>
              }
              <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "10px", width: "300px", padding: '15px', border: "2px solid rgb(20,20,20)", borderRadius: "5px", transition: "0.4s", marginTop: "-15px"})} onClick={() => setState(4)}>
                <div style={({display: "flex", justifyContent: "center", alignItems: "center", border: "2px solid rgb(20,20,20)", minWidth: "16px", minHeight: "16px", borderRadius: "5px", cursor: "pointer"})} >
                  <img src={process.env.PUBLIC_URL + "/plus.png"} style={({width: "10px", transition: "0.4s"})}/>
                </div>
                <h3 style={({fontSize: "11px", color: "rgb(20,20,20)"})}>Dodaj innego opiekuna</h3>
              </div>
            </div>
            <button style={({width: "200px", minHeight: "35px", fontSize: "13px", color: "white", borderRadius: "5px"})} className="button-black" onClick={() => setState(6)}>Przejdź dalej</button>
          </div>
        }
        {state === 6 &&
          <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "90%", gap: "15px", marginBottom: "50px"})}>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "27px", textAlign: "center"})}>Załóż konto</h3>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "13px", fontWeight: "normal", textAlign: "center"})}>Załóż konto, na które będziesz się logować do systemu, zapamiętaj swoje ustalone hasło.</h3>
            <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "30px", padding: "30px", backgroundColor: "rgb(250,250,250)", borderRadius: "15px"})}>
              <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "12px"})}>
                <h3 style={({fontSize: "12px", color: "rgb(20,20,20)", textAlign: "center"})}>Adres e-mail</h3>
                <input value={data?.email} className="login" type="email" placeholder="np. annakowalska@gmail.com" style={({height: "20px", width: "275px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/email.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, email: e.target.value}); setError(false)}}/>
              </div>
              <div style={({display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "5px"})}>
                <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Ustal hasło</h3>
                <div style={({position: "relative"})}>
                  <input className="login" value={data?.password} placeholder="Hasło musi posiadać min. 6 znaków" type={passEyes?.pass ? "text" : "password"} style={({height: "20px", width: "275px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/pass.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, password: e.target.value}); setError(false)}}/>
                  <img onClick={() => setPassEyes({...passEyes, pass: passEyes?.pass ? false : true})} className="login-eye" src={passEyes?.pass ? process.env.PUBLIC_URL + "/eyeClosed.png" : process.env.PUBLIC_URL +  "/eye.png"} style={({width: "20px", position: "absolute", right: "6px", bottom: "7px"})}/>
                </div>
              </div>
              <div style={({display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "5px"})}>
                <h3 style={({fontSize: "12px", color: "rgb(20,20,20)"})}>Potwierdź ustalone hasło</h3>
                <div style={({position: "relative"})}>
                  <input className="login" value={data?.confirmPass} placeholder="Hasło musi posiadać min. 6 znaków" type={passEyes?.confPass ? "text" : "password"} style={({height: "20px", width: "275px", fontgWeight: "bold", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/pass.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "7px 7px", paddingLeft: "40px", transition: "0.8s", borderBottom: ".2em solid rgb(20,20,20)"})} onChange={(e) => {setData({...data, confirmPass: e.target.value}); setError(false)}}/>
                  <img onClick={() => setPassEyes({...passEyes, confPass: passEyes?.confPass ? false : true})} className="login-eye" src={passEyes?.confPass ? process.env.PUBLIC_URL + "/eyeClosed.png" : process.env.PUBLIC_URL +  "/eye.png"} style={({width: "20px", position: "absolute", right: "6px", bottom: "7px"})}/>
                </div>
              </div>
            </div>
            <button style={({width: "200px", minHeight: "35px", fontSize: "13px", color: "white", borderRadius: "5px"})} className="button-black" onClick={() => createAcc()}>Załóż konto</button>
          </div>
        }
        {animation === true &&
          <div style={({display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", gap: "15px", position: "fixed", top: "0px", left: "0px", minHeight: "100vh", zIndex: "5", backgroundColor: "rgb(250,250,250)"})}>
            <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <h3 style={({fontSize: "18px", color: "rgb(20,20,20)"})}>Tworzenie konta...</h3>
          </div>
        }
        {state === 7 &&
          <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "90%", gap: "15px", marginBottom: "50px"})}>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "27px", textAlign: "center"})}>Wybierz opcję zapisu</h3>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "13px", fontWeight: "normal", textAlign: "center"})}>Wybierz w jaki sposób chcesz zapisać każdego z uczestników.</h3>
            <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "30px", padding: "30px", backgroundColor: "rgb(250,250,250)", borderRadius: "15px"})}>
              {data?.sub1 &&
                <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "100%", gap: "35px"})}>
                  <h3 style={({fontSize: "16px", borderBottom: "2px solid rgb(30,30,30)", padding: "0px 20px", marginBottom: "-10px"})}>{data?.sub1?.displayName}</h3>
                  {data?.sub1?.groups?.map((g) =>
                    <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "100%", gap: "15px"})}>
                      <h3 style={({fontSize: "14px", textAlign: "center"})}>{locations?.filter(x => x?.id === groups?.filter(x => x?.id === g)[0]?.location_id)[0]?.name} - {groups?.filter(x => x?.id === g)[0]?.days[0]?.custom_name ? groups?.filter(x => x?.id === g)[0]?.days[0]?.custom_name : groups?.filter(x => x?.id === g)[0]?.type}</h3>
                      {!groups?.filter(x => x?.id === g)[0]?.days[0]?.custom_name && <h3 style={({fontSize: "14px", textAlign: "center", marginTop: '-12px', marginBottom: "10px", marginBottom: "-5px"})}>{weekDayConvert(groups?.filter(x => x?.id === g)[0]?.days[0]?.weekDay)} {groups?.filter(x => x?.id === g)[0]?.days[0]?.startTime} - {groups?.filter(x => x?.id === g)[0]?.days[0]?.endTime}</h3>}
                      <div style={({display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%", gap: "5px"})}>
                        <div style={({transition: "0.5s", width: "60px", height: "60px", gap: "5px", border: data?.sub1?.prices?.[g] === "contract" ? "2px solid #0045C7" : "2px solid rgba(0,0,0,0)", boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1.5px, rgb(209, 213, 219) 0px 0px 0px 1px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "20px", backgroundColor: "rgb(250,250,250)", borderRadius: "5px", cursor: 'pointer'})} onClick={() => setData({...data, sub1: {...data?.sub1, prices: {...data?.sub1?.prices, [g]: "contract"}}})}>
                          <h3 style={({color: data?.sub1?.prices?.[g] === "contract" ? "#0045C7" : "rgb(30,30,30)", fontWeight: "bold", textAlign: "center", marginTop: "5px", fontSize: "12px"})}>Umowa z wypowiedzeniem miesięcznym</h3>
                          <h3 style={({color: "#0045C7", fontWeight: "bold", textAlign: "center", fontSize: "12px"})}>{groups?.filter(x => x?.id === g)[0]?.prices?.contract} ZŁ</h3>
                        </div>
                        {groups?.filter(x => x?.id === g)[0]?.prices?.obligation !== "Nie dotyczy" && 
                          <div style={({transition: "0.5s", width: "60px", height: "60px", gap: "5px", border: data?.sub1?.prices?.[g] === "obligation" ? "2px solid #0045C7" : "2px solid rgba(0,0,0,0)", boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1.5px, rgb(209, 213, 219) 0px 0px 0px 1px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "20px", backgroundColor: "rgb(250,250,250)", borderRadius: "5px", cursor: 'pointer'})} onClick={() => setData({...data, sub1: {...data?.sub1, prices: {...data?.sub1?.prices, [g]: "obligation"}}})}>
                            <h3 style={({color: data?.sub1?.prices?.[g] === "obligation" ? "#0045C7" : "rgb(30,30,30)", fontWeight: "bold", textAlign: "center", marginTop: "5px", fontSize: "12px"})}>Umowa bez możliwości wypowiedzenia</h3>
                            <h3 style={({color: "#0045C7", fontWeight: "bold", textAlign: "center", fontSize: "12px"})}>{groups?.filter(x => x?.id === g)[0]?.prices?.obligation} ZŁ</h3>
                          </div>
                        }
                        <div style={({transition: "0.5s", width: "60px", height: "60px", gap: "5px", border: data?.sub1?.prices?.[g] === "entry" ? "2px solid #0045C7" : "2px solid rgba(0,0,0,0)", boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1.5px, rgb(209, 213, 219) 0px 0px 0px 1px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "20px", backgroundColor: "rgb(250,250,250)", borderRadius: "5px", cursor: 'pointer'})} onClick={() => setData({...data, sub1: {...data?.sub1, prices: {...data?.sub1?.prices, [g]: "entry"}}})}>
                          <h3 style={({color: data?.sub1?.prices?.[g] === "entry" ? "#0045C7" : "rgb(30,30,30)", fontWeight: "bold", textAlign: "center", marginTop: "5px", fontSize: "12px"})}>Wejście jednorazowe</h3>
                          <h3 style={({color: "#0045C7", fontWeight: "bold", textAlign: "center", fontSize: "12px"})}>{groups?.filter(x => x?.id === g)[0]?.prices?.entry} ZŁ</h3>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              }
              {data?.sub2 &&
                <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "100%", gap: "15px"})}>
                  <h3 style={({fontSize: "16px", borderBottom: "2px solid rgb(30,30,30)", padding: "0px 20px"})}>{data?.sub2?.displayName}</h3>
                  {data?.sub2?.groups?.map((g) =>
                    <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "100%", gap: "15px"})}>
                      <h3 style={({fontSize: "14px", textAlign: "center"})}>{locations?.filter(x => x?.id === groups?.filter(x => x?.id === g)[0]?.location_id)[0]?.name} - {groups?.filter(x => x?.id === g)[0]?.days[0]?.custom_name ? groups?.filter(x => x?.id === g)[0]?.days[0]?.custom_name : groups?.filter(x => x?.id === g)[0]?.type}</h3>
                      {!groups?.filter(x => x?.id === g)[0]?.days[0]?.custom_name && <h3 style={({fontSize: "14px", textAlign: "center", marginTop: '-12px', marginBottom: "10px", marginBottom: "-5px"})}>{weekDayConvert(groups?.filter(x => x?.id === g)[0]?.days[0]?.weekDay)} {groups?.filter(x => x?.id === g)[0]?.days[0]?.startTime} - {groups?.filter(x => x?.id === g)[0]?.days[0]?.endTime}</h3>}
                      <div style={({display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%", gap: "5px"})}>
                        <div style={({transition: "0.5s", width: "60px", height: "60px", gap: "5px", border: data?.sub2?.prices?.[g] === "contract" ? "2px solid #0045C7" : "2px solid rgba(0,0,0,0)", boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1.5px, rgb(209, 213, 219) 0px 0px 0px 1px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "20px", backgroundColor: "rgb(250,250,250)", borderRadius: "5px", cursor: 'pointer'})} onClick={() => setData({...data, sub2: {...data?.sub2, prices: {...data?.sub2?.prices, [g]: "contract"}}})}>
                          <h3 style={({color: data?.sub2?.prices?.[g] === "contract" ? "#0045C7" : "rgb(30,30,30)", fontWeight: "bold", textAlign: "center", marginTop: "5px", fontSize: "12px"})}>Umowa z wypowiedzeniem miesięcznym</h3>
                          <h3 style={({color: "#0045C7", fontWeight: "bold", textAlign: "center", fontSize: "12px"})}>{groups?.filter(x => x?.id === g)[0]?.prices?.contract} ZŁ</h3>
                        </div>
                        {groups?.filter(x => x?.id === g)[0]?.prices?.obligation !== "Nie dotyczy" && 
                          <div style={({transition: "0.5s", width: "60px", height: "60px", gap: "5px", border: data?.sub2?.prices?.[g] === "obligation" ? "2px solid #0045C7" : "2px solid rgba(0,0,0,0)", boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1.5px, rgb(209, 213, 219) 0px 0px 0px 1px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "20px", backgroundColor: "rgb(250,250,250)", borderRadius: "5px", cursor: 'pointer'})} onClick={() => setData({...data, sub2: {...data?.sub2, prices: {...data?.sub2?.prices, [g]: "obligation"}}})}>
                            <h3 style={({color: data?.sub2?.prices?.[g] === "obligation" ? "#0045C7" : "rgb(30,30,30)", fontWeight: "bold", textAlign: "center", marginTop: "5px", fontSize: "12px"})}>Umowa bez możliwości wypowiedzenia</h3>
                            <h3 style={({color: "#0045C7", fontWeight: "bold", textAlign: "center", fontSize: "12px"})}>{groups?.filter(x => x?.id === g)[0]?.prices?.obligation} ZŁ</h3>
                          </div>
                        }
                        <div style={({transition: "0.5s", width: "60px", height: "60px", gap: "5px", border: data?.sub2?.prices?.[g] === "entry" ? "2px solid #0045C7" : "2px solid rgba(0,0,0,0)", boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1.5px, rgb(209, 213, 219) 0px 0px 0px 1px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "20px", backgroundColor: "rgb(250,250,250)", borderRadius: "5px", cursor: 'pointer'})} onClick={() => setData({...data, sub2: {...data?.sub2, prices: {...data?.sub2?.prices, [g]: "entry"}}})}>
                          <h3 style={({color: data?.sub2?.prices?.[g] === "entry" ? "#0045C7" : "rgb(30,30,30)", fontWeight: "bold", textAlign: "center", marginTop: "5px", fontSize: "12px"})}>Wejście jednorazowe</h3>
                          <h3 style={({color: "#0045C7", fontWeight: "bold", textAlign: "center", fontSize: "12px"})}>{groups?.filter(x => x?.id === g)[0]?.prices?.entry} ZŁ</h3>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              }
              {data?.sub3 &&
                <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "100%", gap: "15px"})}>
                  <h3 style={({fontSize: "16px", borderBottom: "2px solid rgb(30,30,30)", padding: "0px 20px"})}>{data?.sub3?.displayName}</h3>
                  {data?.sub3?.groups?.map((g) =>
                    <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "100%", gap: "15px"})}>
                      <h3 style={({fontSize: "14px", textAlign: "center"})}>{locations?.filter(x => x?.id === groups?.filter(x => x?.id === g)[0]?.location_id)[0]?.name} - {groups?.filter(x => x?.id === g)[0]?.days[0]?.custom_name ? groups?.filter(x => x?.id === g)[0]?.days[0]?.custom_name : groups?.filter(x => x?.id === g)[0]?.type}</h3>
                      {!groups?.filter(x => x?.id === g)[0]?.days[0]?.custom_name && <h3 style={({fontSize: "14px", textAlign: "center", marginTop: '-12px', marginBottom: "10px", marginBottom: "-5px"})}>{weekDayConvert(groups?.filter(x => x?.id === g)[0]?.days[0]?.weekDay)} {groups?.filter(x => x?.id === g)[0]?.days[0]?.startTime} - {groups?.filter(x => x?.id === g)[0]?.days[0]?.endTime}</h3>}
                      <div style={({display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%", gap: "5px"})}>
                        <div style={({transition: "0.5s", width: "60px", height: "60px", gap: "5px", border: data?.sub3?.prices?.[g] === "contract" ? "2px solid #0045C7" : "2px solid rgba(0,0,0,0)", boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1.5px, rgb(209, 213, 219) 0px 0px 0px 1px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "20px", backgroundColor: "rgb(250,250,250)", borderRadius: "5px", cursor: 'pointer'})} onClick={() => setData({...data, sub3: {...data?.sub3, prices: {...data?.sub3?.prices, [g]: "contract"}}})}>
                          <h3 style={({color: data?.sub3?.prices?.[g] === "contract" ? "#0045C7" : "rgb(30,30,30)", fontWeight: "bold", textAlign: "center", marginTop: "5px", fontSize: "12px"})}>Umowa z wypowiedzeniem miesięcznym</h3>
                          <h3 style={({color: "#0045C7", fontWeight: "bold", textAlign: "center", fontSize: "12px"})}>{groups?.filter(x => x?.id === g)[0]?.prices?.contract} ZŁ</h3>
                        </div>
                        {groups?.filter(x => x?.id === g)[0]?.prices?.obligation !== "Nie dotyczy" && 
                          <div style={({transition: "0.5s", width: "60px", height: "60px", gap: "5px", border: data?.sub3?.prices?.[g] === "obligation" ? "2px solid #0045C7" : "2px solid rgba(0,0,0,0)", boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1.5px, rgb(209, 213, 219) 0px 0px 0px 1px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "20px", backgroundColor: "rgb(250,250,250)", borderRadius: "5px", cursor: 'pointer'})} onClick={() => setData({...data, sub3: {...data?.sub3, prices: {...data?.sub3?.prices, [g]: "obligation"}}})}>
                            <h3 style={({color: data?.sub3?.prices?.[g] === "obligation" ? "#0045C7" : "rgb(30,30,30)", fontWeight: "bold", textAlign: "center", marginTop: "5px", fontSize: "12px"})}>Umowa bez możliwości wypowiedzenia</h3>
                            <h3 style={({color: "#0045C7", fontWeight: "bold", textAlign: "center", fontSize: "12px"})}>{groups?.filter(x => x?.id === g)[0]?.prices?.obligation} ZŁ</h3>
                          </div>
                        }
                        <div style={({transition: "0.5s", width: "60px", height: "60px", gap: "5px", border: data?.sub3?.prices?.[g] === "entry" ? "2px solid #0045C7" : "2px solid rgba(0,0,0,0)", boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1.5px, rgb(209, 213, 219) 0px 0px 0px 1px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "20px", backgroundColor: "rgb(250,250,250)", borderRadius: "5px", cursor: 'pointer'})} onClick={() => setData({...data, sub3: {...data?.sub3, prices: {...data?.sub3?.prices, [g]: "entry"}}})}>
                          <h3 style={({color: data?.sub3?.prices?.[g] === "entry" ? "#0045C7" : "rgb(30,30,30)", fontWeight: "bold", textAlign: "center", marginTop: "5px", fontSize: "12px"})}>Wejście jednorazowe</h3>
                          <h3 style={({color: "#0045C7", fontWeight: "bold", textAlign: "center", fontSize: "12px"})}>{groups?.filter(x => x?.id === g)[0]?.prices?.entry} ZŁ</h3>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              }
            </div>
            <button style={({width: "200px", minHeight: "35px", fontSize: "13px", color: "white", borderRadius: "5px"})} onClick={() => setPrices()} className="button-black">Przejdź dalej</button>
          </div>
        }
        {state === 8 &&
          <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "90%", gap: "15px", marginBottom: "50px"})}>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "27px", textAlign: "center"})}>Rejestracja zakończona</h3>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "15px", fontWeight: "normal", textAlign: "center"})}>Twoje konto zostało założone, w ciągu maksymalnie 12h zostanie ono przez nas zweryfikowane.</h3>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "15px", fontWeight: "normal", textAlign: "center"})}>Po weryfikacji otrzymają Państwo na maila fakturę do opłaty oraz po zalogowaniu podpisać będzie można dokumenty online.</h3>
            <Link style={({textDecoration: "none"})} to={'/'}><button style={({width: "200px", minHeight: "35px", fontSize: "13px", color: "white", borderRadius: "5px"})} className="button-black">Powrót na stronę główną</button></Link>
          </div>
        }
        {/* {state === 8 &&
          <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "90%", gap: "15px", marginBottom: "50px"})}>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "27px", textAlign: "center"})}>Dokumentacja</h3>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "13px", fontWeight: "normal", textAlign: "center"})}>Złóż podpis pod niezbędną dokumentacją.</h3>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "13px", fontWeight: "normal", textAlign: "center"})}>Klikając w nazwę dokumentu możesz pobrać go do wglądu.</h3>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "13px", fontWeight: "normal", textAlign: "center"})}>Poniższe pola reprezentują puste pola na dokumencie.</h3>
            <h3 style={({color: "rgb(30,30,30)", fontSize: "13px", fontWeight: "normal", textAlign: "center"})}>Uzupełnij niezbędne pola.</h3>
            <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "30px", padding: "30px", width: "100%"})}>
              {docs && docs?.map((d) =>
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "30px", width: "100%"})}>
                  {/* <h3 style={({fontSize: "14px", fontWeight: "bold"})}>Kwestionariusz zdrowia - {d?.user?.displayName}</h3>
                  <div style={({display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row", width: "100%"})}>
                      <h3 style={({fontSize: "14px", fontWeight: "bold"})}>Czy w ostatnim miesiącu dziecko skarżyło się na ból w klatce piersiowej w trakcie lub podczas wysiłku?</h3>
                      <input type="radio" id="1">TAK</input>
                      <input type="radio" id="1">NIE</input>
                    </div>
                    <div style={({display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row", width: "100%"})}>
                      <h3 style={({fontSize: "14px", fontWeight: "bold"})}>Czy dziecko traci równowagę z powodu zawrotów głowy lub czy kiedykolwiek straciło przytomność?</h3>
                      <input type="radio" id="1">TAK</input>
                      <input type="radio" id="1">NIE</input>
                    </div>
                    <div style={({display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row", width: "100%"})}>
                      <h3 style={({fontSize: "14px", fontWeight: "bold"})}>Czy ma problemy z kośćmi lub stawami, które mogą ulec pogorszeniu w związku z wysiłkiem fizycznym?</h3>
                      <input type="radio" id="1">TAK</input>
                      <input type="radio" id="1">NIE</input>
                    </div>
                    <div style={({display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row", width: "100%"})}>
                      <h3 style={({fontSize: "14px", fontWeight: "bold"})}>Czy dziecko przyjmuje lekarstwa (np. tabletki) na ciśnienie lub serce?</h3>
                      <input type="radio" id="1">TAK</input>
                      <input type="radio" id="1">NIE</input>
                    </div>
                    <div style={({display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row", width: "100%"})}>
                      <h3 style={({fontSize: "14px", fontWeight: "bold"})}>Czy nieletnia jest w ciąży lub była w ciągu ostatnich trzech miesięcy?</h3>
                      <input type="radio" id="1">TAK</input>
                      <input type="radio" id="1">NIE</input>
                    </div>
                    <div style={({display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row", width: "100%"})}>
                      <h3 style={({fontSize: "14px", fontWeight: "bold"})}>Czy znasz jakiekolwiek powody, które mogą uniemożliwiać Twojemu dziecku rozpoczęcie wysiłku fizycznego?</h3>
                      <input type="radio" id="1">TAK</input>
                      <input type="radio" id="1">NIE</input>
              {/* <h3 style={({color: "rgb(30,30,30)", fontSize: "16px", fontWeight: "bold", textAlign: "center"})}>Złóż odręczny podpis na polu poniżej. Podpis podpisze wszystkie dokumenty.</h3>
              <CanvasDraw ref={canvasRef} brushColor="black" brushRadius={1.5}  lazyRadius={0} hideInterface hideGrid style={({backgroundColor: "rgb(0,0,0,0)", width: "100%", height: "120px", maxWidth: "400px", border: "1px solid black", borderRadius: "5px"})}/>
            </div>
            <button style={({width: "200px", minHeight: "35px", fontSize: "13px", color: "white", borderRadius: "5px"})} className="button-black" onClick={() => checkIfSigned()}>Złóż podpis</button>
          </div> */}
      </div>
    </div>
  );
}

export default Register;