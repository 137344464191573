import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/homePage";
import Register from "./pages/register";
import GroupsSelect from "./pages/groupsSelect";
import LocationSelect from "./pages/locationSelect";
import "./styles/global.css"
import BookInfoPanel from "./pages/bookInfoPanel";
import BookAdmin from "./pages/bookInfoAdmin";
import RegistrationForm from "./pages/registration_new";
import Registration from "./pages/register_new";
import Test from "./pages/test";
import ClientPanel from "./pages/clientPanel";
import Lists from "./pages/lists";
import FreqPanel from "./pages/freq";

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HomePage />
            </>
          }
        />
        <Route
          path="/rejestracja/:fid"
          element={
            <>
              <LocationSelect />
            </>
          }
        />
        <Route
          path="/rejestracja/:fid/:lid"
          element={
            <>
              <GroupsSelect />
            </>
          }
        />
        <Route
          path="/rejestracja/:fid/:lid/:gid"
          element={
            <>
              <Register />
            </>
          }
        />
         <Route
          path="/ksiegowosc"
          element={
            <>
              <BookInfoPanel />
            </>
          }
        />
        <Route
          path="/listy"
          element={
            <>
              <Lists />
            </>
          }
        />
       <Route
          path="/ksiegowosc/admin"
          element={
            <>
              <BookAdmin />
            </>
          }
        />

        {/* <Route
          path="/test/rejestracja"
          element={
            <>
              <Test />
            </>
          }
        /> */}

        <Route
          path="/panel-klienta"
          element={
            <>
              <ClientPanel />
            </>
          }
        />

        <Route
          path="/frekwencja"
          element={
            <>
              <FreqPanel />
            </>
          }
        />

        <Route path="/register" element={<Registration />} />
        <Route path="/register/:facilityId" element={<Registration />} />
        <Route path="/register/:facilityId/:locationId" element={<Registration />} />
        <Route path="/register/:facilityId/:locationId/:groupId" element={<Registration />} />
      </Routes>
    </>
  );
}

export default App;
