import React, { act, useEffect, useState } from 'react';
import { arrayRemove, arrayUnion, collection, doc, onSnapshot, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import ArrowBack from '../components/arrowBack';

export default function Lists() {

  const [users, setUsers] = useState("")
  const [groups, setGroups] = useState("")
  const [locations, setLocations] = useState("")

  useEffect(() => {

    let data = []
    const getUsers = onSnapshot(collection(db, "users"), (snapshot) => {
      data = (snapshot.docs.map((doc) => (doc.data())));

      let clients = []
      for(let i = 0; i < data?.length; i++) {
        for (let x = 0; x < Object?.keys(data[i]?.subUsers)?.length; x++) {
          clients.push({uid: data[i]?.uid, acc: data[i], user: Object?.values(data[i]?.subUsers)[x]})
        }
      }
      setUsers(clients);

    });
  }, []);

  const weekDayConvert = (day) => {
    if (day === 1) return "Poniedziałek"
    if (day === 2) return "Wtorek"
    if (day === 3) return "Środa"
    if (day === 4) return "Czwartek"
    if (day === 5) return "Piątek"
    if (day === 6) return "Sobota"
  }

  useEffect(() => {
    let data = [];
    const getGroups = onSnapshot(collection(db, "groups"), (snapshot) => {
      // Pobieranie danych z snapshot
      data = snapshot.docs.map((doc) => doc.data());
  
      // Połączenie danych w jedną tablicę
      let g = [];
      data.forEach((group) => {
        g.push(...Object.values(group)); // Zakładamy, że dane są obiektem
      });
  
      // Ustawianie stanu grup
      setGroups(g);
    });
  
    // Czyszczenie subskrypcji onSnapshot
    return () => getGroups();
  }, []);

  useEffect(() => {
    let data = [];
    const getLocations = onSnapshot(collection(db, "locations"), (snapshot) => {
      // Pobieranie danych z snapshot
      data = snapshot.docs.map((doc) => doc.data());
  
      // Połączenie danych w jedną tablicę
      let l = [];
      data.forEach((location) => {
        l.push(...Object.values(location)); // Zakładamy, że dane są obiektem
      });
  
      // Ustawianie stanu grup
      setLocations(l);
    });
  
    // Czyszczenie subskrypcji onSnapshot
    return () => getLocations();
  }, []);

  const verifyAcc = (u) => {
    setDoc(doc(db, "users", u?.uid), {
      subUsers: {
        [u?.user?.index]: {
          docs: {
            contract: {
              groups: u?.user?.groups?.length,
              price: 0,
              type: "standard"
            },
          },
          toVerificate: false
        }
      }
    }, {merge: true})
  }

    return (
      <div style={({display: "flex", gap: "100px", margin: "3%", width: "97%", marginTop: "20px", flexDirection: "column"})}>
        {users && <h3>{users?.length}</h3>}
        {groups && groups?.sort((a, b) => a.id.localeCompare(b.id))?.map((g) =>
          users && users?.filter(x => x?.user?.groups?.includes(g?.id))?.length > 0 &&
          <>
            <h3>{locations?.filter(x => x?.id === g?.location_id)[0]?.name} - {g?.days[0]?.custom_name ? g?.days[0]?.custom_name : g?.type + " - " + weekDayConvert(g?.days[0]?.weekDay) + " " + g?.days[0]?.startTime + " - " + g?.days[0]?.endTime} - {g?.id}</h3>
            {users?.filter(x => x?.user?.groups?.includes(g?.id)).sort((a, b) => a?.user?.displayName.localeCompare(b?.user?.displayName))?.map((u, index) =>
              <div style={({display: "flex", gap: "20px", flexDirection: "row"})}>
                <h3>{index + 1}. {u?.user?.displayName} - {u?.acc?.parent?.phone}- {u?.uid} - {u?.user?.docs?.contract?.price} - {u?.acc?.email} - {u?.user?.prices && Object?.values(u?.user?.prices)[0]}</h3>
                {u?.acc?.sign ? <img src={u?.acc?.sign} /> : <h3>Brak podpisu</h3>}
                {u?.user?.toVerificate === true && <button onClick={() => verifyAcc(u)}>Verify</button>}
              </div>
            )}
          </>

        )}
      </div>
    );
  }