export default function Statue(props) {

    return (
        <div id="regulamin" style={({width: "50%", minWidth: "300px", height: "450px", overflowY: "scroll", padding: "15px", marginTop: "-10px", border: "1px solid rgb(20,20,20)", borderRadius: "5px"})}>
            {props?.version !== 1 ? 
                <>    
<h3 style={({fontSize: "15px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "8px"})}>REGULAMIN UCZESTNICTWA W ZAJĘCIACH W 4STARS DANCE & AERIAL STUDIO</h3>
<h3 style={({fontSize: "13px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "8px"})}>Niniejszy regulamin (dalej: „Regulamin") określa zasady korzystania z usług 4STARS DANCE & AERIAL STUDIO (dalej: „Klub”), prowadzonego przez FUNDACJĘ 4STARS DANCE & AERIAL STUDIO z siedzibą we Wrocławiu,</h3>
<h3 style={({fontSize: "13px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "10px"})}>Zajęcia prowadzone są w ramach klubu sportowego 4STARS DANCE & AERIAL STUDIO. Działalność klubu reguluje regulamin klubu sportowego. Członkowie klubu korzystają z zajęć 4STARS DANCE & AERIAL STUDIO i jej działalności w ramach wnoszonej opłaty członkowskiej. Każdorazowo, kiedy mowa o klientach lub uczestnikach zajęć należy przez to rozumieć także członków klubu sportowego.</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 1 Postanowienia ogólne</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> 4STARS Dance & Aerial Studio za pośrednictwem instruktorów zobowiązuje się świadczyć na rzecz Klienta usługi ruchowe, zwane dalej zajęciami baletowymi / tanecznymi / akrobatyką / gimnastyką / gimnastyką artystyczną / aerial.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> Jedynym przedstawicielem i osobą upoważnioną do podejmowania wszelkich decyzji związanych z funkcjonowaniem 4STARS, zajęciami, opłatami i pracą Instruktorów jest Monika Mączka-Gąsior.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>3.</b> Klient (jego dziecko) może rozpocząć kurs tylko po podpisaniu umowy o świadczeniu usług w zakresie wybranych zajęć oraz pełną akceptacją jej warunków, jak i regulaminu oraz aktualnego cennika zajęć.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>4.</b> Aby zajęcia przebiegały sprawnie, na najwyższym poziomie i aby współpraca układała się z największą korzyścią dla dzieci, prosimy o przestrzeganie niniejszego regulaminu.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>5.</b> Klub informuje, iż przedmiotem usługi jest możliwość skorzystania z umiejętności instruktora. Zajęcia prowadzone w 4STARS, są zajęciami technicznymi, opartymi o konkretną technikę lub elementy techniczne o charakterze sportowym.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>6.</b> Klub nie oferuje swoim klientom ćwiczenia z wykorzystaniem muzyki na zajęciach. Obecność muzyki lub jej brak w żadnym stopniu nie jest elementem usługi, a Uczestnik deklaruje, że muzyka lub jej brak nie wpływa na decyzję o zakupie członkostwa, jednocześnie został poinformowany, że część pracowników słucha utworów dla poprawy własnego nastroju. Uczestnik taką deklarację składa, podpisując oświadczenie znajomości i akceptacji regulaminu.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>7.</b> . Instruktor w 4STARS prowadzi zajęcia. Wszelkie zajęcia rytmiczne są oparte o wyklaskiwanie rytmu, wyliczanie rytmu i taktów lub odpowiednie komendy głosowe. Wszelka muzyka wykorzystana w tle, jeśli jest używana to tylko i wyłącznie prywatnie przez pracownika (instruktora) w celu umilenia sobie czasu pomiędzy nauką poszczególnych figur. Jest również dla wielu pracowników (instruktora) sposobem na stres występujący podczas prezentacji przed grupą.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>8.</b> 4STARS zastrzega sobie prawo do natychmiastowego rozwiązania umowy przy braku regularnej opłaty miesięcznej, obrażaniu mienia 4STARS, niesubordynacji dziecka na zajęciach.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>9.</b> W 4STARS obowiązuje system zniżek:</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>a) za polecenia oraz zachęcenie do zapisu do klubu nowych osób, nie będących jeszcze klientami 4STARS,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>b) zniżki rodzinne (rodzeństwo lub rodzic + dziecko) - dla osób powiązanych ze sobą, dodanych na jednym koncie w systemie obsługującym 4STARS (minimum 2, maksimum 3 osoby)</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 2 Instruktorzy</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> 4STARS zapewnia Klientowi wyszkolonego Instruktora tańca / akrobatyki / gimnastyki / aerial.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b>  4STARS gwarantuje Klientowi, że każdy Instruktor prowadzący zajęcia dla klubu przeszedł wieloetapowe postępowanie kwalifikacyjne, a jego poziom znajomości techniki oraz metodyki pozwala na nauczanie na odpowiednim, najwyższym poziomie.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>3.</b> Każdy Instruktor ma podpisaną z 4STARS umowę o zakazie konkurencji, która nie pozwala mu prowadzić zajęć bez pośrednictwa klubu dla osób, które korzystają, bądź korzystały z jej usług.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>4.</b> W przypadku choroby lub innej nieobecności Instruktora, 4STARS zobowiązuje się wyznaczyć zastępstwo.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "7px"})}><b>5.</b> W szczególnych przypadkach Klient ma prawo zaproponować zmianę Instruktora.</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 3 Dziecko i jego strój na zajęciach baletowych/tanecznych/akrobatycznych/gimnastycznych/aerial</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Klient zobowiązuje się zapewnić dziecku wygodny, lekki strój do ćwiczeń (dopuszczalny strój baletowy / gimnastyczny) oraz obuwie zmienne (baletki, skarpetki – na zajęcia baletowe; napalcówki, skarpetki – w przypadku zajęć gimnastycznych; obuwie sportowe, skarpetki – w przypadku zajęć tanecznych).</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "7px"})}><b>2.</b> Klient zobowiązuje się, że zrobi wszystko, by jego dziecko przychodziło na zajęcia zdrowe, wypoczęte, z chęcią do ćwiczeń, z własną wodą, oraz by słuchało i wykonywało polecenia Instruktora.</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 4 Zobowiązania Klienta/Rodzica/Opiekuna</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Klient / Rodzic / Opiekun zobowiązuje się do:</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>a) regularnego i terminowego uiszczania opłat za wybrane zajęcia,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>b) nieuczęszczania na zajęcia podczas choroby / nieprzyprowadzania dziecka chorego na zajęcia,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>c)c) punktualnego przyprowadzania i odbierania dziecka przed i po zajęciach o wyznaczonej godzinie (chyba, że ustali z Instruktorką inaczej), bądź poinformowania dziecka, by czekało na Instruktora w określonym miejscu przed zajęciami oraz wróciło z nim w określone miejsce, po odbytych zajęciach,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>d) niewchodzenia na salę ćwiczeń oraz nieoglądania dziecka poprzez szybę/drzwi (dziecko podczas trwania zajęć jest pod opieką Instruktora),</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>e) czekania na dziecko poza salą ćwiczeń, a jeśli konieczne jest wejście na salę, to bez obuwia lub w obuwiu zmiennym,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "7px"})}>f) powiadomienia właściciela firmy (Monika Mączka-Gąsior) o zmianie adresu zamieszkania, adresu e-mail oraz telefonu kontaktowego, jeśli zmiana nastąpiła w trakcie trwania umowy.</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 5 Dodatkowe zobowiązania rodziców</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> DOTYCZY TYLKO zespołów turniejowych (Super Stars, Mini Super Stars, Stars, Arytmia Mini, Arytmia). Klient/Rodzic/Opiekun członka w.w. zespołów turniejowych zobowiązuje się do:</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>a) zakupu raz w roku kostiumu niezbędnego do występów oraz pokrycia kosztów wyjazdów na festiwale i konkursy („wpisowe”, przejazd, opieka trenerska),</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>b) poinformowania Instruktora - Moniki Mączki-Gąsior minimum miesiąc przed planowanym wyjazdem na turniej / konkurs taneczny, w przypadku niemożliwości uczestniczenia dziecka w danym turnieju / konkursie tanecznym (MOŻLIWOŚĆ TAKA ISTNIEJE TYLKO W NAGŁYCH, LOSOWYCH PRZYPADKACH). Terminy wyjazdów na turnieje/konkursy taneczne są podawane zawsze z możliwie największym wyprzedzeniem tak, by Rodzice/Opiekunowie mieli możliwość ustalenia własnego grafiku,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>c) w przypadku wyjazdu na turniej jest pobierany zadatek (patrz § 5 podpunkt a),</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>d) pokrycia kosztów naprawy wszelkich szkód wyrządzonych przez dziecko zarówno w sali, gdzie odbywają się zajęcia jak i na korytarzu oraz w trakcie przeglądów tańca czy innych wyjazdów grupowych (jeżeli takowe będą),</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>e) przyprowadzania dziecka na wcześniej ustalone dodatkowe próby i występy</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "7px"})}>f) wspierania dziecka w jego pracy w zespole i zachęcania do podnoszenia swoich umiejętności,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "7px"})}>g) informowania Instruktorki o jakichkolwiek zaistniałych problemach, dotyczących bezpośrednio zdrowia i samopoczucia dziecka, bądź jego funkcjonowania w grupie.</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 6 Zobowiązania 4STARS Dance & Aerial Studio</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> 4STARS oświadcza, że posiada personel dydaktyczny przyjazny dzieciom oraz posiadający niezbędne kwalifikacje do prowadzenia zajęć.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> 4STARS zobowiązuje się do świadczenia za pośrednictwem Instruktorów usług w postaci prowadzenia zajęć z należytą starannością, a w szczególności:</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>a) realizacji programu nauczania dla poszczególnych grup (baletowych, tanecznych, akrobatycznych, gimnastycznych, aerialowych),</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>b) tworzenia układów choreograficznych/pokazów do zaprezentowania dla Rodziców/Opiekunów i/lub na turniejach tanecznych (jeśli są zaplanowane),</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>c) prowadzenia list obecności dzieci,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>d) możliwie szybkiej reakcji na sugestie Klienta,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>e) zapewnienia opieki nad dzieckiem podczas zajęć,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "7px", marginLeft: "4px"})}>f) informowanie Rodziców / Opiekunów o zaistniałych zmianach dotyczących płatności, Instruktorów, godziny zajęć, odwołania zajęć czy też ich odrobienia,</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 7 Termin zajęć i płatności</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Z tytułu świadczenia usług przez Klub Uczestnik lub Opiekun zobowiązany jest uiszczać na rzecz 4STARS miesięczną opłatę za przynależność do grupy, stosownie do cennika zajęć.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> Uczestnik zobowiązany jest do ponoszenia całości kwoty za miesiąc, niezależnie od pojedynczych nieobecności na zajęciach, gdyż opłata jest za przynależność do poszczególnych grup</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>3.</b> Zajęcia odbywają się raz, dwa lub trzy razy w tygodniu po 30/45/50/60/90/120 minut (w zależności od grupy).</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>4.</b> Opłata regularna za zajęcia jest ustalana w zależności od rodzaju, miejsca zajęć, czasu ich trwania oraz charakteru grupy (grupa turniejowa, rekreacyjna).</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>5.</b> Podpięcie karty oznacza, płatność automatyczną, pobieraną równo z rozpoczęciem terminu płatności, bez dodatkowej ingerencji klienta (klient raz dodaje kartę, następne opłaty pobierane zostają bez ponownego podawania danych w systemie).</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>6.</b> W wypadku chęci odpięcia karty z systemu przez Klienta, podczas, gdy Klienta obowiązuje okres wypowiedzenia, karta zostanie odpięta dopiero po skończeniu okresu wypowiedzenia lub po pobraniu z góry z karty opłaty za cały obowiązujący okres.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>7.</b> W wypadku częstych problemów z automatycznym pobraniem opłaty miesięcznej (takimi jak: brak środków na koncie, blokowanie limitów karty, problemy z 3D Secure lub inne), 4STARS ma prawo do odpięcia karty.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>8.</b> Opłata może zostać pomniejszona tylko w przypadku, gdy:</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>a) a) zajęcia nie odbędą się i nie będzie możliwe ich odrobienie z winy 4STARS;</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>9.</b>  Opłata za jednorazowe wejście na zajęcia wynosi 40 zł, 50 zł lub 60 zł (według cenników poszczególnych placówek) i jest doliczana do całości miesięcznej opłaty, w przypadku jeśli Rodzic / Opiekun zdecyduje się na dalszy udział dziecka w zajęciach (podpisaniu umowy). </h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>10.</b> Zajęcia odbywają się 1x w tygodniu, 2x w tygodniu, bądź 3x w tygodniu w zależności od charakteru i zaawansowania grupy. Opłata za zajęcia jest zryczałtowana - dotyczy przynależności do poszczególnych grup - co oznacza, że za KAŻDY MIESIĄC wynosi tyle samo (nie wliczając wakacji - (miesiąc lipiec i sierpień).</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>11.</b> Opłata jest pobierana z góry w terminie od 1-eg do 7-go dnia każdego miesiąca (automatycznie poprzez podpięcie karty) lub poprzez ręczne dokonanie jej w systemie obsługującym 4STARS wybierając jeden z dostępnych sposób płatności (jednorazowa opłata kartą, przelewy24, applePay, blik) - termin opłaty obowiązuje również do 7-go dnia każdego miesiąca. </h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>12.</b> W przypadku niedokonania płatności w terminie Klient zobowiązuje się do zapłaty odsetek ustawowych.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>13.</b> . W przypadku niedokonania wpłaty w wyznaczonym terminie, dziecko nie będzie mogło uczestniczyć w zajęciach tanecznych, instruktorka ma prawo skreślić dziecko z listy.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>14.</b> . Instruktor ma prawo nie wpuścić Uczestnika na zajęcia, których wcześniej nie opłacił.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>15.</b> Pracownicy administracji wraz z kierownictwem mają prawo zdecydować o niedopuszczeniu do zajęć dowolnej osoby bez podania przyczyny, jeśli uznają, że wpływa to na bezpieczeństwo lub komfort zajęć.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>a) jeśli pracownicy administracji lub kierownictwo zadecydują o niedopuszczeniu danej osoby do zajęć, zostaje mu zwrócona cała kwota, którą oferował się zapłacić,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>b)  pracownicy administracji, jak i kierownictwo mogą podjąć taką decyzję w dowolnym momencie prowadzenia zajęć.,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "7px"})}><b>16.</b> Każda osoba, która przebywa na terenie Klubu musi być na liście uczestników na zajęcia. W przypadku braku obecności na liście, osoba jest zmuszona opuścić Klub w trybie natychmiastowym. Każda lista określa precyzyjnie czas, godzinie oraz personalia osoby uprawnionej do przebywania na terenie Klubu w wybranych godzinach, na określonych w regulaminie zasadach. Nie można przebywać w placówce poza wyznaczonymi warunkami.</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 8 Odrobienie zajęć</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Każdą nieobecność na zajęciach MOŻNA ODROBIĆ na innych zajęciach dostosowanych dla danej grupy wiekowej w naszych wybranych lokalizacjach i terminach dogodnych dla klientów.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> Nieobecność należy zgłosić najpóźniej do godziny 12:00 w dniu, w którym planowane są zajęcia (poprzez wyznaczone do tego miejsce w systemie obsługującym klub). Przy zachowaniu tego terminu jest możliwe ich odrobienie w przeciągu 14 dni od dnia zgłoszenia nieobecności. Osoba, która nie poinformowała o nieobecności we wskazanym terminie nie ma prawa do odrobienia zajęć w tym trybie.</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 9 Okres prowadzenia zajęć i rezygnacja z udziału w zajęciach</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Zajęcia w 4STARS są prowadzone od września/października/listopada do czerwca (zgodnie z kalendarzem roku szkolnego ustalonym przez MEN).</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> Dni wolne od zajęć będą ustalane według kalendarza MEN.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>3.</b> Rezygnację z uczestnictwa dziecka w zajęciach należy złożyć poprzez system obsługujący klub. Nie zostają akceptowane wypowiedzenia składane ustnie, drogą mailową, smsową, pisemną bądź też przez „osoby trzecie“.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>4.</b>  W przypadku rezygnacji z zajęć rodzic jest zobowiązany do uzupełnienia wypełnienia „Wypowiedzenia umowy”, dostępnego w systemie obsługującym klub, w którym wyraźnie zaznaczy od jakiego miesiąca dziecko nie będzie uczęszczało na zajęcia. Wypowiedzenie jest jednomiesięczne i następuje ze skutkiem na koniec miesiąca , co oznacza że jeśli złożymy rezygnację należy opłacić jeszcze cały kolejny miesiąc zajęć, przez który dziecko może uczęszczać na zajęcia (obowiązuje w przypadku podpisania umowy z możliwością wypowiedzenia).</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>5.</b> W przypadku braku złożonego wypowiedzenia klient zobowiązany jest do opłaty okresu do końca trwania umowy (nawet jeżeli klient lub jego dziecko nie uczęszcza na zajęcia od kilku miesięcy).</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>6.</b> W przypadku, kiedy klient ma podpiętą kartę, jest ona odłączana z systemu w momencie uregulowania wszystkich opłat do końca trwania umowy lub za zgodą dwóch stron (klient musi uargumentować powód chęci odpięcia karty).</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>7.</b> 4STARS ma prawo do rozwiązania umowy w trybie natychmiastowym w przypadku zalegania z płatnościami przez klienta.</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 10 Końcowy pokaz - spektakl</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Zajęcia będą miały swoje zwieńczenie w postaci spektaklu tanecznego, który zostanie wystawiony w czerwcu, dokładnie 7.06.2025r. na dużej scenie ATM na Bielanach Wrocławskich (4STARS zastrzega sobie zmianę miejsca i terminu z przyczyn nie zależnych od klubu)</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> Każde dziecko, które uczęszcza na zajęcia do 4STARS będzie częścią wielkiego widowiska tanecznego, które odbędzie się w czerwcu we wskazanym miejscu.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>3.</b> Wejście na spektakl będzie biletowane, każdy rodzic/opiekun dokonuje zakupu biletu na widowisko we własnym zakresie.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>4.</b> Cena biletów normalnych na galę wynosi 70 zł lub 80 zł, w zależności od wybranego rzędu. Cena biletu ulgowego (dla osób do 16 r.ż) wynosi 50 zł.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>5.</b> Przy zakupie co najmniej 4 biletów, nabywca uprawniony jest do otrzymania zniżki w wysokości 5 zł na każdy bilet. W przypadku zakupu więcej niż 6 biletów, nabywca otrzymuje zniżkę w wysokości 10 zł na każdy bilet.</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 11 Pozostałe</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Usługodawca nie ubezpiecza uczestników zajęć od nieszczęśliwych wypadków</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> Klub informuje, iż dochowa wszelkiej staranności w celu maksymalnego zabezpieczenia klienta Klubu. Jednocześnie klient deklaruje, iż nie będzie rościł sobie prawa do odszkodowania i zadośćuczynienia w przypadku doznania kontuzji lub uszczerbku na zdrowiu, który został doznany w wyniku niedostosowania się do poleceń i uwag instruktora lub korzystania ze sprzętu w sposób niezgodny z instrukcją. Uczestnik taką, szczegółową deklarację składa, podpisując oświadczenie zdrowotne oraz oświadczenie znajomości i akceptacji regulaminu.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>3.</b> Klub informuje, iż dochowa wszelkiej staranności w celu maksymalnego zabezpieczenia miejsca prowadzenia zajęć. Zastrzega się, by nie pozostawiać wartościowych rzeczy bez nadzoru i zabierać takowe ze sobą na salę, w celu zminimalizowania ryzyka kradzieży oraz zgubienia cennych rzeczy. Uczestnik deklaruje, że nie będzie rościł sobie prawa do odszkodowania w przypadku, gdy kradzież, uszkodzenie lub zaginięcie pozostawionych na terenie placówki rzeczy nastąpi w momencie, gdy nie dochował szczególnej staranności w celu ich zabezpieczenia (zabrania ich ze sobą na salę), mimo zaleceń ze strony Klubu. Uczestnik taką deklarację składa, podpisując oświadczenie znajomości i akceptacji regulaminu. </h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>4.</b> Instruktor prowadzący zajęcia ma prawo do wykluczenia z zajęć dziecka, którego zachowanie zakłóca ich przebieg. W przypadku permanentnego łamania regulaminu dziecko może zostać usunięte z grupy.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>5.</b> Każdy uczestnik zajęć na koniec roku szkolnego otrzyma dyplom ukończenia zajęć od 4STARS. </h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>6.</b> Ze względu na fakt, iż integralną częścią usługi może być również utrwalenie uczestnictwa klientów w zajęciach. Uczestnik oświadcza, że wyraża zgodę na używanie jego wizerunku w przypadku utrwalania zajęć podczas Warsztatów, zarówno w postaci foto, jak i wideo i nie będzie rościł sobie praw majątkowych z powodu ich wykorzystania. Uczestnik zostanie każdorazowo poinformowany, jeśli na miejscu pojawi się osoba utrwalająca foto lub video. Na prośbę Uczestnik każdorazowo Klub usunie zdjęcie lub wideo, na którym wypadł niekorzystnie. Zdjęcia będą publikowany tylko przez kanały należące lub powiązane z organizatorem, tj. Klubem. Jeśli nie wyrażają Państwo zgody na utrwalanie materiałów, prosimy nie pozować i usunąć się z przestrzeni zdjęcia lub video oraz poinformować o tym osobę wykonującą materiały w momencie jego powstawania, tak by mogła w odpowiednim czasie zareagować w przypadku np. zdjęć grupowych czy ujęć video, gdzie inni uczestnicy chcieliby zostać utrwaleni. Materiały są wykorzystywane do promocji zajęć oraz w celach marketingowych, ale też wielokrotnie są przedmiotem usługi, którą jest utrwalenie warsztatów, ćwiczeń czy relacja uczestnictwa danej osoby/ grupy osób w zajęciach, co jest ujęte w ofercie usługi lub oferowane słownie. Materiały nie będą wykorzystywane komercyjnie i nie będą przekazywane osobom trzecim. Materiały nie będą wykorzystywane i tworzone wbrew obowiązującym w Polsce przepisom.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>7.</b> Teren 4STARS Dance & Aerial Studio jest w pełni terenem prywatnym i nie jest przestrzenią publiczną ani nie jest miejscem ogólnodostępnym. Na terenie 4STARS Dance &Aerial Studio znajdować się mogą tylko określone osoby, w określonych godzinach i spełniające określone kryteria wynikające z regulaminu 4STARS Dance & Aerial Studio. Nie jest dozwolone, by osoby postronne bez względu na reprezentowany podmiot mogły swobodnie poruszać się po terenie Klubu. Każda próba zakłócenia pracy instruktorów lub administracji będzie skutkowała wezwaniem odpowiednich służb pod zarzutem utrudniania pracy oraz nękania. Każdorazowo będzie nakładana również kara dla osób postronnych utrudniających pracę, w skali godzinowej stawki względem dziennego obrotu Klubu, jako zadośćuczynienie za zakłócanie poprawnej pracy klubu i brakiem możliwości wykonywania swojej pracy</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "7px"})}><b>8.</b> 4STARS informuje, że teren Klubu jest monitorowany ze względów bezpieczeństwa. Przebywanie na terenie Klubu jest skrupulatnie rejestrowane (audio i video) ze względów bezpieczeństwa za pomocą różnych środków (smartphone, tablet, kamera, rejestracja audio, telefon itp.). Ma to posłużyć w przypadku jakichkolwiek sporów prawnych jako materiał dowodowy. Rejestrowany jest jedynie teren holu na wypadek wtargnięcia osób niebędących naszymi klientami. Codzienna praca 4STARS nie będzie rejestrowana, a materiały codziennie kasowane. Jest to działanie jedynie prewencyjne w trosce  o najwyższą jakość świadczonych usług. Jeśli nie wyrażasz zgody na nagrywanie, prosimy o opuszczenie Klubu.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "7px"})}><b>9.</b> W związku z rozporządzeniem Unijnym RODO informujemy, że informacje o tym w, jaki sposób są przetwarzane Wasze dane, są dostępne tutaj: https://4starsdanceaerialstudio.pl/wp-content/uploads/2024/09/Polityka-prywatnosci-2024-2025.pdf.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "7px"})}><b>10.</b> Warunki zapisane w regulaminie nie podlegają negocjacjom.</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 12 Siła Wyższa</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Przez Siłę Wyższą rozumie się zdarzenia pozostające poza kontrolą każdej ze Stron, których nie mogły one przewidzieć ani zapobiec, a które zakłócają lub uniemożliwiają realizację Umowy.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> W przypadku zaistnienia Siły Wyższej, Strona, której taka okoliczność uniemożliwia lub utrudnia prawidłowe wywiązanie się z jej zobowiązań niezwłocznie nie później jednak niż w ciągu 7 dni, powiadomi drugą Stronę o takich okolicznościach i ich przyczynie.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>3.</b> W przypadku wystąpienia Siły Wyższej, placówka jest uprawniona do przejścia na tryb nauczania/prowadzenia zdalnego lub realizacji usługi w formie „zajęć online” do końca trwania umowy, co jest integralną częścią umowy pomiędzy Placówką i Uczestnikiem.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>4.</b> W przypadku, gdy Uczestnik z obiektywnych względów nie ma możliwości korzystania z usługi na tożsamym poziomie, dopuszcza się możliwość zamrożenia usługi do czasu ustania Siły Wyższej, tym samym przedłużając czas trwania umowy o analogiczny okres zamrożenia, w którym usługa nie była świadczona.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>5.</b> Forma realizacji usługi po wystąpieniu Siły Wyższej zostanie przedstawiona Uczestnikowi niezwłocznie po reorganizacji placówki i zapoznaniu się z aktualnymi wytycznymi odpowiednich służb wraz z nowo obowiązującym prawem oraz udostępniona za pośrednictwem dostępnych kanałów komunikacji z Uczestnikiem</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>6.</b> Uczestnik będzie mógł zapoznać się z nowymi zasadami realizacji usługi oraz dokonać ich akceptacji w przypadku chęci kontynuowania usługi. W przypadku braku akceptacji w ciągu 7 dni od komunikatu dochodzi do automatycznego zamrożenia usługi do czasu ustania Siły Wyższej.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>7.</b> W przypadku akceptacji sposobu realizacji usługi placówka zobowiązuje się do zachowania najwyższego, obiektywnie możliwego w danej sytuacji poziomu realizacji usługi na wcześniej ustalonych zasadach cenowych. Poziom merytoryczny nie ulegnie zmianie, jedynie środki techniczne realizacji usługi zostaną dostosowane do aktualnej sytuacji w możliwie nieinwazyjny dla Uczestnika sposób.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>8.</b> Uczestnik wyraża świadomie zgodę na zastosowanie wyżej opisanego trybu w sytuacji wystąpienia Siły Wyższej akceptując regulamin i nie wnosi do niego uwag.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>9.</b> W przypadku ponownego zamknięcia placówek szkolnych, 4STARS przenosi działania na drogę online i zapewnia każdemu uczestnikowi zajęcia na platformie ZOOM (lub innej ustalonej przez 4STARS), bądź w postaci nagrań - filmów z lekcjami (możliwość wyboru wg preferencji), opłata ulega wtedy pomniejszeniu o 20% wyjściowej kwoty za zajęcia (w przypadku grup rekreacyjnych) lub 40% wyjściowej kwoty za zajęcia (w przypadku zespołów turniejowych).</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 13 Odstąpienie i reklamacje</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Zamawiający, będący konsumentem może odstąpić od umowy zawartej na odległość w ciągu 14 dni bez podania jakiejkolwiek przyczyny, wysyłając oświadczenie Wykonawcy</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> Bieg terminu określonego w ust. 1 rozpoczyna się w chwili podpisania Umowy.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>3.</b> . Oświadczenie może być wysłane drogą elektroniczną poprzez pocztę, na adres mailowy: 4starsdanceaerialstudio@gmail.com, bądź poprzez system obsługujący szkołę.
</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>4.</b>  Reklamację należy zgłosić pisemnie lub drogą elektroniczną na podane w ust. 3 adres. Wykonawca ustosunkuje się do żądania reklamacyjnego niezwłocznie, nie później niż w terminie 14 dni.
</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 14 Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia roszczeń</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Szczegółowe informacje dotyczące możliwości skorzystania przez Konsumenta z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń oraz zasady dostępu do tych procedur dostępne są w siedzibach oraz na stronach internetowych powiatowych (miejskich) rzeczników konsumentów, organizacji społecznych, do których zadań statutowych należy ochrona konsumentów, Wojewódzkich Inspektoratów Inspekcji Handlowej oraz pod następującymi adresami internetowymi Urzędu Ochrony Konkurencji i Konsumentów: http://www.uokik.gov.pl/spory_konsumenckie.php; http://www.uokik.gov.pl/sprawy_indywidualne.php oraz http://www.uokik.gov.pl/wazne_adresy.php.</h3>
<h3><b>2.</b> . Konsument posiada następujące przykładowe możliwości skorzystania z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń: a) Konsument uprawniony jest do zwrócenia się do stałego polubownego sądu konsumenckiego, o którym mowa w art. 37 ustawy z dnia 15 grudnia 2000 r. o Inspekcji Handlowej (Dz.U. z 2014 r. poz. 148 z późn. zm.), z wnioskiem o rozstrzygnięcie sporu wynikłego z Umowy zawartej z Usługodawcą., b) Konsument uprawniony jest do zwrócenia się do wojewódzkiego inspektora Inspekcji Handlowej, zgodnie z art. 36 ustawy z dnia 15 grudnia 2000 r. o Inspekcji Handlowej (Dz.U. z 2014 r. poz. 148 z późn. zm.), z wnioskiem o wszczęcie postępowania mediacyjnego w sprawie polubownego zakończenia sporu między Konsumentem a Usługodawcą, c) Konsument może uzyskać bezpłatną pomoc w sprawie rozstrzygnięcia sporu między nim a Sprzedawcą, korzystając także z bezpłatnej pomocy powiatowego (miejskiego) rzecznika konsumentów lub organizacji społecznej, do której zadań statutowych należy ochrona konsumentów (m.in. Federacja Konsumentów, Stowarzyszenie Konsumentów Polskich), </h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>3.</b>  Klient ma prawo skorzystać z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń. W tym celu może złożyć skargę za pośrednictwem unijnej platformy internetowej ODR dostępnej pod adresem:http://ec.europa.eu/consumers/odr/4. </h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 15 Kontakt i sprawy informacyjne</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> W przypadku wątpliwości w sprawach związanych z usługami 4STARS klient ma prawo do kontaktu z odpowiednimi biurami informacyjnymi 4STARS.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> W wypadku kontaktu z nieodpowiednim biurem, Klient może zostać przekierowany do odpowiedniego, zajmującym się sprawami związanymi z sprawą klienta biura.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>3.</b> Działy informacyjne 4STARS pracują w poniżej przedstawionym harmonogramie. Pracownicy nie mają obowiązku udzielania informacji poza godzinami pracy biura.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>4.</b>Harmonogram pracy i działalności biur: <n/> a) Biuro informacyjne – pod numerem 505-376-552 – dostępne w godzinach 9:00 – 20:00 <n/> b) Biuro informacyjne – pod adresem mailowym – 4starsdanceaerialstudio@gmail.com – odpowiedź Klient uzyska tak szybko, jak to możliwe</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>5.</b> 4STARS zastrzega prawo do zakończenia komunikacji i pomocy w wyjaśnieniu sprawy, w przypadku agresji werbalnej, obrażania mienia 4STARS lub innej sytuacji, którą pracowników uzna za rażącą w jego osobę. W przypadku wystąpienia jednej z wymienionych sytuacji, umowa może zostać rozwiązana w trybie natychmiastowym.</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 16 Postanowienia końcowe</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Warunki regulaminu nie podlegają negocjacjom i są wiążące dla wszystkich uczestników.</h3>
                </>
:
                <>    
<h3 style={({fontSize: "15px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "8px"})}>REGULAMIN UCZESTNICTWA W ZAJĘCIACH W 4STARS DANCE & AERIAL STUDIO</h3>
<h3 style={({fontSize: "13px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "8px"})}>Niniejszy regulamin (dalej: „Regulamin") określa zasady korzystania z usług 4STARS DANCE & AERIAL STUDIO (dalej: „Klub”), prowadzonego przez FUNDACJĘ 4STARS DANCE & AERIAL STUDIO z siedzibą we Wrocławiu,</h3>
<h3 style={({fontSize: "13px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "10px"})}>Zajęcia prowadzone są w ramach klubu sportowego 4STARS DANCE & AERIAL STUDIO. Działalność klubu reguluje regulamin klubu sportowego. Członkowie klubu korzystają z zajęć 4STARS DANCE & AERIAL STUDIO i jej działalności w ramach wnoszonej opłaty członkowskiej. Każdorazowo, kiedy mowa o klientach lub uczestnikach zajęć należy przez to rozumieć także członków klubu sportowego.</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 1 Postanowienia ogólne</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> 4STARS Dance & Aerial Studio za pośrednictwem instruktorów zobowiązuje się świadczyć na rzecz Klienta usługi ruchowe, zwane dalej zajęciami baletowymi / tanecznymi / akrobatyką / gimnastyką / gimnastyką artystyczną / aerial.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> Jedynym przedstawicielem i osobą upoważnioną do podejmowania wszelkich decyzji związanych z funkcjonowaniem 4STARS, zajęciami, opłatami i pracą Instruktorów jest Monika Mączka-Gąsior.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>3.</b> Klient (jego dziecko) może rozpocząć kurs tylko po podpisaniu umowy o świadczeniu usług w zakresie wybranych zajęć oraz pełną akceptacją jej warunków, jak i regulaminu oraz aktualnego cennika zajęć.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>4.</b> Aby zajęcia przebiegały sprawnie, na najwyższym poziomie i aby współpraca układała się z największą korzyścią dla dzieci, prosimy o przestrzeganie niniejszego regulaminu.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>5.</b> Klub informuje, iż przedmiotem usługi jest możliwość skorzystania z umiejętności instruktora. Zajęcia prowadzone w 4STARS, są zajęciami technicznymi, opartymi o konkretną technikę lub elementy techniczne o charakterze sportowym.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>6.</b> Klub nie oferuje swoim klientom ćwiczenia z wykorzystaniem muzyki na zajęciach. Obecność muzyki lub jej brak w żadnym stopniu nie jest elementem usługi, a Uczestnik deklaruje, że muzyka lub jej brak nie wpływa na decyzję o zakupie członkostwa, jednocześnie został poinformowany, że część pracowników słucha utworów dla poprawy własnego nastroju. Uczestnik taką deklarację składa, podpisując oświadczenie znajomości i akceptacji regulaminu.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>7.</b> . Instruktor w 4STARS prowadzi zajęcia. Wszelkie zajęcia rytmiczne są oparte o wyklaskiwanie rytmu, wyliczanie rytmu i taktów lub odpowiednie komendy głosowe. Wszelka muzyka wykorzystana w tle, jeśli jest używana to tylko i wyłącznie prywatnie przez pracownika (instruktora) w celu umilenia sobie czasu pomiędzy nauką poszczególnych figur. Jest również dla wielu pracowników (instruktora) sposobem na stres występujący podczas prezentacji przed grupą.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>8.</b> 4STARS zastrzega sobie prawo do natychmiastowego rozwiązania umowy przy braku regularnej opłaty miesięcznej, obrażaniu mienia 4STARS, niesubordynacji dziecka na zajęciach.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>9.</b> W 4STARS obowiązuje system zniżek:</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>a) za polecenia oraz zachęcenie do zapisu do klubu nowych osób, nie będących jeszcze klientami 4STARS,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>b) zniżki rodzinne (rodzeństwo lub rodzic + dziecko) - dla osób powiązanych ze sobą, dodanych na jednym koncie w systemie obsługującym 4STARS (minimum 2, maksimum 3 osoby)</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 2 Instruktorzy</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> 4STARS zapewnia Klientowi wyszkolonego Instruktora tańca / akrobatyki / gimnastyki / aerial.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b>  4STARS gwarantuje Klientowi, że każdy Instruktor prowadzący zajęcia dla klubu przeszedł wieloetapowe postępowanie kwalifikacyjne, a jego poziom znajomości techniki oraz metodyki pozwala na nauczanie na odpowiednim, najwyższym poziomie.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>3.</b> Każdy Instruktor ma podpisaną z 4STARS umowę o zakazie konkurencji, która nie pozwala mu prowadzić zajęć bez pośrednictwa klubu dla osób, które korzystają, bądź korzystały z jej usług.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>4.</b> W przypadku choroby lub innej nieobecności Instruktora, 4STARS zobowiązuje się wyznaczyć zastępstwo.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "7px"})}><b>5.</b> W szczególnych przypadkach Klient ma prawo zaproponować zmianę Instruktora.</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 3 Dziecko i jego strój na zajęciach baletowych/tanecznych/akrobatycznych/gimnastycznych/aerial</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Klient zobowiązuje się zapewnić dziecku wygodny, lekki strój do ćwiczeń (dopuszczalny strój baletowy / gimnastyczny) oraz obuwie zmienne (baletki, skarpetki – na zajęcia baletowe; napalcówki, skarpetki – w przypadku zajęć gimnastycznych; obuwie sportowe, skarpetki – w przypadku zajęć tanecznych).</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "7px"})}><b>2.</b> Klient zobowiązuje się, że zrobi wszystko, by jego dziecko przychodziło na zajęcia zdrowe, wypoczęte, z chęcią do ćwiczeń, z własną wodą, oraz by słuchało i wykonywało polecenia Instruktora.</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 4 Zobowiązania Klienta/Rodzica/Opiekuna</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Klient / Rodzic / Opiekun zobowiązuje się do:</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>a) regularnego i terminowego uiszczania opłat za wybrane zajęcia,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>b) nieuczęszczania na zajęcia podczas choroby / nieprzyprowadzania dziecka chorego na zajęcia,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>c)c) punktualnego przyprowadzania i odbierania dziecka przed i po zajęciach o wyznaczonej godzinie (chyba, że ustali z Instruktorką inaczej), bądź poinformowania dziecka, by czekało na Instruktora w określonym miejscu przed zajęciami oraz wróciło z nim w określone miejsce, po odbytych zajęciach,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>d) niewchodzenia na salę ćwiczeń oraz nieoglądania dziecka poprzez szybę/drzwi (dziecko podczas trwania zajęć jest pod opieką Instruktora),</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>e) czekania na dziecko poza salą ćwiczeń, a jeśli konieczne jest wejście na salę, to bez obuwia lub w obuwiu zmiennym,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "7px"})}>f) powiadomienia właściciela firmy (Monika Mączka-Gąsior) o zmianie adresu zamieszkania, adresu e-mail oraz telefonu kontaktowego, jeśli zmiana nastąpiła w trakcie trwania umowy.</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 5 Dodatkowe zobowiązania rodziców</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> DOTYCZY TYLKO zespołów turniejowych (Super Stars, Mini Super Stars, Stars, Arytmia Mini, Arytmia). Klient/Rodzic/Opiekun członka w.w. zespołów turniejowych zobowiązuje się do:</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>a) zakupu raz w roku kostiumu niezbędnego do występów oraz pokrycia kosztów wyjazdów na festiwale i konkursy („wpisowe”, przejazd, opieka trenerska),</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>b) poinformowania Instruktora - Moniki Mączki-Gąsior minimum miesiąc przed planowanym wyjazdem na turniej / konkurs taneczny, w przypadku niemożliwości uczestniczenia dziecka w danym turnieju / konkursie tanecznym (MOŻLIWOŚĆ TAKA ISTNIEJE TYLKO W NAGŁYCH, LOSOWYCH PRZYPADKACH). Terminy wyjazdów na turnieje/konkursy taneczne są podawane zawsze z możliwie największym wyprzedzeniem tak, by Rodzice/Opiekunowie mieli możliwość ustalenia własnego grafiku,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>c) w przypadku wyjazdu na turniej jest pobierany zadatek (patrz § 5 podpunkt a),</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>d) pokrycia kosztów naprawy wszelkich szkód wyrządzonych przez dziecko zarówno w sali, gdzie odbywają się zajęcia jak i na korytarzu oraz w trakcie przeglądów tańca czy innych wyjazdów grupowych (jeżeli takowe będą),</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>e) przyprowadzania dziecka na wcześniej ustalone dodatkowe próby i występy</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "7px"})}>f) wspierania dziecka w jego pracy w zespole i zachęcania do podnoszenia swoich umiejętności,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "7px"})}>g) informowania Instruktorki o jakichkolwiek zaistniałych problemach, dotyczących bezpośrednio zdrowia i samopoczucia dziecka, bądź jego funkcjonowania w grupie.</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 6 Zobowiązania 4STARS Dance & Aerial Studio</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> 4STARS oświadcza, że posiada personel dydaktyczny przyjazny dzieciom oraz posiadający niezbędne kwalifikacje do prowadzenia zajęć.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> 4STARS zobowiązuje się do świadczenia za pośrednictwem Instruktorów usług w postaci prowadzenia zajęć z należytą starannością, a w szczególności:</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>a) realizacji programu nauczania dla poszczególnych grup (baletowych, tanecznych, akrobatycznych, gimnastycznych, aerialowych),</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>b) tworzenia układów choreograficznych/pokazów do zaprezentowania dla Rodziców/Opiekunów i/lub na turniejach tanecznych (jeśli są zaplanowane),</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>c) prowadzenia list obecności dzieci,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>d) możliwie szybkiej reakcji na sugestie Klienta,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>e) zapewnienia opieki nad dzieckiem podczas zajęć,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "7px", marginLeft: "4px"})}>f) informowanie Rodziców / Opiekunów o zaistniałych zmianach dotyczących płatności, Instruktorów, godziny zajęć, odwołania zajęć czy też ich odrobienia,</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 7 Termin zajęć i płatności</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Z tytułu świadczenia usług przez Klub Uczestnik lub Opiekun zobowiązany jest uiszczać na rzecz 4STARS miesięczną opłatę za przynależność do grupy, stosownie do cennika zajęć.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> Uczestnik zobowiązany jest do ponoszenia całości kwoty za miesiąc, niezależnie od pojedynczych nieobecności na zajęciach, gdyż opłata jest za przynależność do poszczególnych grup</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>3.</b> Zajęcia odbywają się raz, dwa lub trzy razy w tygodniu po 30/45/50/60/90/120 minut (w zależności od grupy).</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>4.</b> Opłata regularna za zajęcia jest ustalana w zależności od rodzaju, miejsca zajęć, czasu ich trwania oraz charakteru grupy (grupa turniejowa, rekreacyjna).</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>5.</b> Podpięcie karty oznacza, płatność automatyczną, pobieraną równo z rozpoczęciem terminu płatności, bez dodatkowej ingerencji klienta (klient raz dodaje kartę, następne opłaty pobierane zostają bez ponownego podawania danych w systemie).</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>6.</b> W wypadku chęci odpięcia karty z systemu przez Klienta, podczas, gdy Klienta obowiązuje okres wypowiedzenia, karta zostanie odpięta dopiero po skończeniu okresu wypowiedzenia lub po pobraniu z góry z karty opłaty za cały obowiązujący okres.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>7.</b> W wypadku częstych problemów z automatycznym pobraniem opłaty miesięcznej (takimi jak: brak środków na koncie, blokowanie limitów karty, problemy z 3D Secure lub inne), 4STARS ma prawo do odpięcia karty.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>8.</b> Opłata może zostać pomniejszona tylko w przypadku, gdy:</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>a) a) zajęcia nie odbędą się i nie będzie możliwe ich odrobienie z winy 4STARS;</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>9.</b>  Opłata za jednorazowe wejście na zajęcia wynosi 40 zł, 50 zł lub 60 zł (według cenników poszczególnych placówek) i jest doliczana do całości miesięcznej opłaty, w przypadku jeśli Rodzic / Opiekun zdecyduje się na dalszy udział dziecka w zajęciach (podpisaniu umowy). </h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>10.</b> Zajęcia odbywają się 1x w tygodniu, 2x w tygodniu, bądź 3x w tygodniu w zależności od charakteru i zaawansowania grupy. Opłata za zajęcia jest zryczałtowana - dotyczy przynależności do poszczególnych grup - co oznacza, że za KAŻDY MIESIĄC wynosi tyle samo (nie wliczając wakacji - (miesiąc lipiec i sierpień).</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>11.</b> Opłata jest pobierana z góry w terminie od 1-eg do 7-go dnia każdego miesiąca (automatycznie poprzez podpięcie karty) lub poprzez ręczne dokonanie jej w systemie obsługującym 4STARS wybierając jeden z dostępnych sposób płatności (jednorazowa opłata kartą, przelewy24, applePay, blik) - termin opłaty obowiązuje również do 7-go dnia każdego miesiąca. </h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>12.</b> W przypadku niedokonania płatności w terminie Klient zobowiązuje się do zapłaty odsetek ustawowych.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>13.</b> . W przypadku niedokonania wpłaty w wyznaczonym terminie, dziecko nie będzie mogło uczestniczyć w zajęciach tanecznych, instruktorka ma prawo skreślić dziecko z listy.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>14.</b> . Instruktor ma prawo nie wpuścić Uczestnika na zajęcia, których wcześniej nie opłacił.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>15.</b> Pracownicy administracji wraz z kierownictwem mają prawo zdecydować o niedopuszczeniu do zajęć dowolnej osoby bez podania przyczyny, jeśli uznają, że wpływa to na bezpieczeństwo lub komfort zajęć.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>a) jeśli pracownicy administracji lub kierownictwo zadecydują o niedopuszczeniu danej osoby do zajęć, zostaje mu zwrócona cała kwota, którą oferował się zapłacić,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px", marginLeft: "4px"})}>b)  pracownicy administracji, jak i kierownictwo mogą podjąć taką decyzję w dowolnym momencie prowadzenia zajęć.,</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "7px"})}><b>16.</b> Każda osoba, która przebywa na terenie Klubu musi być na liście uczestników na zajęcia. W przypadku braku obecności na liście, osoba jest zmuszona opuścić Klub w trybie natychmiastowym. Każda lista określa precyzyjnie czas, godzinie oraz personalia osoby uprawnionej do przebywania na terenie Klubu w wybranych godzinach, na określonych w regulaminie zasadach. Nie można przebywać w placówce poza wyznaczonymi warunkami.</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 8 Odrobienie zajęć</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Każdą nieobecność na zajęciach MOŻNA ODROBIĆ na innych zajęciach dostosowanych dla danej grupy wiekowej w naszych wybranych lokalizacjach i terminach dogodnych dla klientów.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> Nieobecność należy zgłosić najpóźniej do godziny 12:00 w dniu, w którym planowane są zajęcia (poprzez wyznaczone do tego miejsce w systemie obsługującym klub). Przy zachowaniu tego terminu jest możliwe ich odrobienie w przeciągu 14 dni od dnia zgłoszenia nieobecności. Osoba, która nie poinformowała o nieobecności we wskazanym terminie nie ma prawa do odrobienia zajęć w tym trybie.</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 9 Okres prowadzenia zajęć i rezygnacja z udziału w zajęciach</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Zajęcia w 4STARS są prowadzone od września/października/listopada do czerwca (zgodnie z kalendarzem roku szkolnego ustalonym przez MEN).</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> Dni wolne od zajęć będą ustalane według kalendarza MEN.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>3.</b> Rezygnację z uczestnictwa dziecka w zajęciach należy złożyć poprzez system obsługujący klub. Nie zostają akceptowane wypowiedzenia składane ustnie, drogą mailową, smsową, pisemną bądź też przez „osoby trzecie“.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>4.</b>  W przypadku rezygnacji z zajęć rodzic jest zobowiązany do uzupełnienia wypełnienia „Wypowiedzenia umowy”, dostępnego w systemie obsługującym klub, w którym wyraźnie zaznaczy od jakiego miesiąca dziecko nie będzie uczęszczało na zajęcia. Wypowiedzenie jest jednomiesięczne i następuje ze skutkiem na koniec miesiąca , co oznacza że jeśli złożymy rezygnację należy opłacić jeszcze cały kolejny miesiąc zajęć, przez który dziecko może uczęszczać na zajęcia (obowiązuje w przypadku podpisania umowy z możliwością wypowiedzenia).</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>5.</b> W przypadku braku złożonego wypowiedzenia klient zobowiązany jest do opłaty okresu do końca trwania umowy (nawet jeżeli klient lub jego dziecko nie uczęszcza na zajęcia od kilku miesięcy).</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>6.</b> W przypadku, kiedy klient ma podpiętą kartę, jest ona odłączana z systemu w momencie uregulowania wszystkich opłat do końca trwania umowy lub za zgodą dwóch stron (klient musi uargumentować powód chęci odpięcia karty).</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>7.</b> 4STARS ma prawo do rozwiązania umowy w trybie natychmiastowym w przypadku zalegania z płatnościami przez klienta.</h3>

{props?.place !== "cfh66CW4jxFRMkU9AT5y" && <h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 10 Końcowy pokaz - spektakl</h3>}
{props?.place !== "cfh66CW4jxFRMkU9AT5y" && <h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Zajęcia będą miały swoje zwieńczenie w postaci spektaklu tanecznego, który zostanie wystawiony w czerwcu, dokładnie 7.06.2025r. na dużej scenie ATM na Bielanach Wrocławskich (4STARS zastrzega sobie zmianę miejsca i terminu z przyczyn nie zależnych od klubu)</h3>}
{props?.place !== "cfh66CW4jxFRMkU9AT5y" && <h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> Każde dziecko, które uczęszcza na zajęcia do 4STARS będzie częścią wielkiego widowiska tanecznego, które odbędzie się w czerwcu we wskazanym miejscu.</h3>}
{props?.place !== "cfh66CW4jxFRMkU9AT5y" && <h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>3.</b> Wejście na spektakl będzie biletowane, każdy rodzic/opiekun dokonuje zakupu biletu na widowisko we własnym zakresie.</h3>}
{props?.place !== "cfh66CW4jxFRMkU9AT5y" && <h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>4.</b> Cena biletów normalnych na galę wynosi 70 zł lub 80 zł, w zależności od wybranego rzędu. Cena biletu ulgowego (dla osób do 16 r.ż) wynosi 50 zł.</h3>}
{props?.place !== "cfh66CW4jxFRMkU9AT5y" && <h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>5.</b> Przy zakupie co najmniej 4 biletów, nabywca uprawniony jest do otrzymania zniżki w wysokości 5 zł na każdy bilet. W przypadku zakupu więcej niż 6 biletów, nabywca otrzymuje zniżkę w wysokości 10 zł na każdy bilet.</h3>}

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 11 Pozostałe</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Usługodawca nie ubezpiecza uczestników zajęć od nieszczęśliwych wypadków</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> Klub informuje, iż dochowa wszelkiej staranności w celu maksymalnego zabezpieczenia klienta Klubu. Jednocześnie klient deklaruje, iż nie będzie rościł sobie prawa do odszkodowania i zadośćuczynienia w przypadku doznania kontuzji lub uszczerbku na zdrowiu, który został doznany w wyniku niedostosowania się do poleceń i uwag instruktora lub korzystania ze sprzętu w sposób niezgodny z instrukcją. Uczestnik taką, szczegółową deklarację składa, podpisując oświadczenie zdrowotne oraz oświadczenie znajomości i akceptacji regulaminu.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>3.</b> Klub informuje, iż dochowa wszelkiej staranności w celu maksymalnego zabezpieczenia miejsca prowadzenia zajęć. Zastrzega się, by nie pozostawiać wartościowych rzeczy bez nadzoru i zabierać takowe ze sobą na salę, w celu zminimalizowania ryzyka kradzieży oraz zgubienia cennych rzeczy. Uczestnik deklaruje, że nie będzie rościł sobie prawa do odszkodowania w przypadku, gdy kradzież, uszkodzenie lub zaginięcie pozostawionych na terenie placówki rzeczy nastąpi w momencie, gdy nie dochował szczególnej staranności w celu ich zabezpieczenia (zabrania ich ze sobą na salę), mimo zaleceń ze strony Klubu. Uczestnik taką deklarację składa, podpisując oświadczenie znajomości i akceptacji regulaminu. </h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>4.</b> Instruktor prowadzący zajęcia ma prawo do wykluczenia z zajęć dziecka, którego zachowanie zakłóca ich przebieg. W przypadku permanentnego łamania regulaminu dziecko może zostać usunięte z grupy.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>5.</b> Każdy uczestnik zajęć na koniec roku szkolnego otrzyma dyplom ukończenia zajęć od 4STARS. </h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>6.</b> Ze względu na fakt, iż integralną częścią usługi może być również utrwalenie uczestnictwa klientów w zajęciach. Uczestnik oświadcza, że wyraża zgodę na używanie jego wizerunku w przypadku utrwalania zajęć podczas Warsztatów, zarówno w postaci foto, jak i wideo i nie będzie rościł sobie praw majątkowych z powodu ich wykorzystania. Uczestnik zostanie każdorazowo poinformowany, jeśli na miejscu pojawi się osoba utrwalająca foto lub video. Na prośbę Uczestnik każdorazowo Klub usunie zdjęcie lub wideo, na którym wypadł niekorzystnie. Zdjęcia będą publikowany tylko przez kanały należące lub powiązane z organizatorem, tj. Klubem. Jeśli nie wyrażają Państwo zgody na utrwalanie materiałów, prosimy nie pozować i usunąć się z przestrzeni zdjęcia lub video oraz poinformować o tym osobę wykonującą materiały w momencie jego powstawania, tak by mogła w odpowiednim czasie zareagować w przypadku np. zdjęć grupowych czy ujęć video, gdzie inni uczestnicy chcieliby zostać utrwaleni. Materiały są wykorzystywane do promocji zajęć oraz w celach marketingowych, ale też wielokrotnie są przedmiotem usługi, którą jest utrwalenie warsztatów, ćwiczeń czy relacja uczestnictwa danej osoby/ grupy osób w zajęciach, co jest ujęte w ofercie usługi lub oferowane słownie. Materiały nie będą wykorzystywane komercyjnie i nie będą przekazywane osobom trzecim. Materiały nie będą wykorzystywane i tworzone wbrew obowiązującym w Polsce przepisom.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>7.</b> Teren 4STARS Dance & Aerial Studio jest w pełni terenem prywatnym i nie jest przestrzenią publiczną ani nie jest miejscem ogólnodostępnym. Na terenie 4STARS Dance &Aerial Studio znajdować się mogą tylko określone osoby, w określonych godzinach i spełniające określone kryteria wynikające z regulaminu 4STARS Dance & Aerial Studio. Nie jest dozwolone, by osoby postronne bez względu na reprezentowany podmiot mogły swobodnie poruszać się po terenie Klubu. Każda próba zakłócenia pracy instruktorów lub administracji będzie skutkowała wezwaniem odpowiednich służb pod zarzutem utrudniania pracy oraz nękania. Każdorazowo będzie nakładana również kara dla osób postronnych utrudniających pracę, w skali godzinowej stawki względem dziennego obrotu Klubu, jako zadośćuczynienie za zakłócanie poprawnej pracy klubu i brakiem możliwości wykonywania swojej pracy</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "7px"})}><b>8.</b> 4STARS informuje, że teren Klubu jest monitorowany ze względów bezpieczeństwa. Przebywanie na terenie Klubu jest skrupulatnie rejestrowane (audio i video) ze względów bezpieczeństwa za pomocą różnych środków (smartphone, tablet, kamera, rejestracja audio, telefon itp.). Ma to posłużyć w przypadku jakichkolwiek sporów prawnych jako materiał dowodowy. Rejestrowany jest jedynie teren holu na wypadek wtargnięcia osób niebędących naszymi klientami. Codzienna praca 4STARS nie będzie rejestrowana, a materiały codziennie kasowane. Jest to działanie jedynie prewencyjne w trosce  o najwyższą jakość świadczonych usług. Jeśli nie wyrażasz zgody na nagrywanie, prosimy o opuszczenie Klubu.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "7px"})}><b>9.</b> W związku z rozporządzeniem Unijnym RODO informujemy, że informacje o tym w, jaki sposób są przetwarzane Wasze dane, są dostępne tutaj: https://4starsdanceaerialstudio.pl/wp-content/uploads/2024/09/Polityka-prywatnosci-2024-2025.pdf.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "7px"})}><b>10.</b> Warunki zapisane w regulaminie nie podlegają negocjacjom.</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 12 Siła Wyższa</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Przez Siłę Wyższą rozumie się zdarzenia pozostające poza kontrolą każdej ze Stron, których nie mogły one przewidzieć ani zapobiec, a które zakłócają lub uniemożliwiają realizację Umowy.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> W przypadku zaistnienia Siły Wyższej, Strona, której taka okoliczność uniemożliwia lub utrudnia prawidłowe wywiązanie się z jej zobowiązań niezwłocznie nie później jednak niż w ciągu 7 dni, powiadomi drugą Stronę o takich okolicznościach i ich przyczynie.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>3.</b> W przypadku wystąpienia Siły Wyższej, placówka jest uprawniona do przejścia na tryb nauczania/prowadzenia zdalnego lub realizacji usługi w formie „zajęć online” do końca trwania umowy, co jest integralną częścią umowy pomiędzy Placówką i Uczestnikiem.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>4.</b> W przypadku, gdy Uczestnik z obiektywnych względów nie ma możliwości korzystania z usługi na tożsamym poziomie, dopuszcza się możliwość zamrożenia usługi do czasu ustania Siły Wyższej, tym samym przedłużając czas trwania umowy o analogiczny okres zamrożenia, w którym usługa nie była świadczona.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>5.</b> Forma realizacji usługi po wystąpieniu Siły Wyższej zostanie przedstawiona Uczestnikowi niezwłocznie po reorganizacji placówki i zapoznaniu się z aktualnymi wytycznymi odpowiednich służb wraz z nowo obowiązującym prawem oraz udostępniona za pośrednictwem dostępnych kanałów komunikacji z Uczestnikiem</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>6.</b> Uczestnik będzie mógł zapoznać się z nowymi zasadami realizacji usługi oraz dokonać ich akceptacji w przypadku chęci kontynuowania usługi. W przypadku braku akceptacji w ciągu 7 dni od komunikatu dochodzi do automatycznego zamrożenia usługi do czasu ustania Siły Wyższej.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>7.</b> W przypadku akceptacji sposobu realizacji usługi placówka zobowiązuje się do zachowania najwyższego, obiektywnie możliwego w danej sytuacji poziomu realizacji usługi na wcześniej ustalonych zasadach cenowych. Poziom merytoryczny nie ulegnie zmianie, jedynie środki techniczne realizacji usługi zostaną dostosowane do aktualnej sytuacji w możliwie nieinwazyjny dla Uczestnika sposób.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>8.</b> Uczestnik wyraża świadomie zgodę na zastosowanie wyżej opisanego trybu w sytuacji wystąpienia Siły Wyższej akceptując regulamin i nie wnosi do niego uwag.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>9.</b> W przypadku ponownego zamknięcia placówek szkolnych, 4STARS przenosi działania na drogę online i zapewnia każdemu uczestnikowi zajęcia na platformie ZOOM (lub innej ustalonej przez 4STARS), bądź w postaci nagrań - filmów z lekcjami (możliwość wyboru wg preferencji), opłata ulega wtedy pomniejszeniu o 20% wyjściowej kwoty za zajęcia (w przypadku grup rekreacyjnych) lub 40% wyjściowej kwoty za zajęcia (w przypadku zespołów turniejowych).</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 13 Odstąpienie i reklamacje</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Zamawiający, będący konsumentem może odstąpić od umowy zawartej na odległość w ciągu 14 dni bez podania jakiejkolwiek przyczyny, wysyłając oświadczenie Wykonawcy</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> Bieg terminu określonego w ust. 1 rozpoczyna się w chwili podpisania Umowy.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>3.</b> Oświadczenie może być wysłane drogą elektroniczną poprzez pocztę, na adres mailowy: 4starsdanceaerialstudio@gmail.com, bądź poprzez system obsługujący szkołę.
</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>4.</b>  Reklamację należy zgłosić pisemnie lub drogą elektroniczną na podane w ust. 3 adres. Wykonawca ustosunkuje się do żądania reklamacyjnego niezwłocznie, nie później niż w terminie 14 dni.
</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 14 Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia roszczeń</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Szczegółowe informacje dotyczące możliwości skorzystania przez Konsumenta z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń oraz zasady dostępu do tych procedur dostępne są w siedzibach oraz na stronach internetowych powiatowych (miejskich) rzeczników konsumentów, organizacji społecznych, do których zadań statutowych należy ochrona konsumentów, Wojewódzkich Inspektoratów Inspekcji Handlowej oraz pod następującymi adresami internetowymi Urzędu Ochrony Konkurencji i Konsumentów: http://www.uokik.gov.pl/spory_konsumenckie.php; http://www.uokik.gov.pl/sprawy_indywidualne.php oraz http://www.uokik.gov.pl/wazne_adresy.php.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> Konsument posiada następujące przykładowe możliwości skorzystania z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń: a) Konsument uprawniony jest do zwrócenia się do stałego polubownego sądu konsumenckiego, o którym mowa w art. 37 ustawy z dnia 15 grudnia 2000 r. o Inspekcji Handlowej (Dz.U. z 2014 r. poz. 148 z późn. zm.), z wnioskiem o rozstrzygnięcie sporu wynikłego z Umowy zawartej z Usługodawcą., b) Konsument uprawniony jest do zwrócenia się do wojewódzkiego inspektora Inspekcji Handlowej, zgodnie z art. 36 ustawy z dnia 15 grudnia 2000 r. o Inspekcji Handlowej (Dz.U. z 2014 r. poz. 148 z późn. zm.), z wnioskiem o wszczęcie postępowania mediacyjnego w sprawie polubownego zakończenia sporu między Konsumentem a Usługodawcą, c) Konsument może uzyskać bezpłatną pomoc w sprawie rozstrzygnięcia sporu między nim a Sprzedawcą, korzystając także z bezpłatnej pomocy powiatowego (miejskiego) rzecznika konsumentów lub organizacji społecznej, do której zadań statutowych należy ochrona konsumentów (m.in. Federacja Konsumentów, Stowarzyszenie Konsumentów Polskich), </h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>3.</b>  Klient ma prawo skorzystać z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń. W tym celu może złożyć skargę za pośrednictwem unijnej platformy internetowej ODR dostępnej pod adresem:http://ec.europa.eu/consumers/odr/4. </h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 15 Kontakt i sprawy informacyjne</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> W przypadku wątpliwości w sprawach związanych z usługami 4STARS klient ma prawo do kontaktu z odpowiednimi biurami informacyjnymi 4STARS.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>2.</b> W wypadku kontaktu z nieodpowiednim biurem, Klient może zostać przekierowany do odpowiedniego, zajmującym się sprawami związanymi z sprawą klienta biura.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>3.</b> Działy informacyjne 4STARS pracują w poniżej przedstawionym harmonogramie. Pracownicy nie mają obowiązku udzielania informacji poza godzinami pracy biura.</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>4.</b>Harmonogram pracy i działalności biur: <n/> a) Biuro informacyjne – pod numerem 505-376-552 – dostępne w godzinach 9:00 – 20:00 <n/> b) Biuro informacyjne – pod adresem mailowym – 4starsdanceaerialstudio@gmail.com – odpowiedź Klient uzyska tak szybko, jak to możliwe</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>5.</b> 4STARS zastrzega prawo do zakończenia komunikacji i pomocy w wyjaśnieniu sprawy, w przypadku agresji werbalnej, obrażania mienia 4STARS lub innej sytuacji, którą pracowników uzna za rażącą w jego osobę. W przypadku wystąpienia jednej z wymienionych sytuacji, umowa może zostać rozwiązana w trybie natychmiastowym.</h3>

<h3 style={({fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginBottom: "7px"})}>§ 16 Postanowienia końcowe</h3>
<h3 style={({fontSize: "12px", fontWeight: "normal", width: "100%", textAlign: "left", marginBottom: "5px"})}><b>1.</b> Warunki regulaminu nie podlegają negocjacjom i są wiążące dla wszystkich uczestników.</h3>
                </>
            }
        </div>
    )
}