import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import axios from 'axios';

const firebaseConfig = {
  apiKey: "AIzaSyBgwDYgZfHJsLcwTRRzfsu5PpuTl4cj-eM",
  authDomain: "modit-system.firebaseapp.com",
  projectId: "modit-system",
  storageBucket: "modit-system.appspot.com",
  messagingSenderId: "446696569284",
  appId: "1:446696569284:web:3cfe592a4330223065cc91"
};

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);

// Konfiguracja Axios do komunikacji z Firebase Functions
export const apiClient = axios.create({
  baseURL: 'https://europe-west3-modit-system.cloudfunctions.net/app', // Ustaw URL bazowy dla Twoich funkcji Firebase
  timeout: 5000, // Ustawienie limitu czasu na żądanie (opcjonalne)
  headers: {
    'Content-Type': 'application/json',
  }
});

export default app;