import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { Link, useParams } from 'react-router-dom';

function GroupsSelect() {

  const params = useParams()
  const [groups, setGroups] = useState("")

  const [selected, setSelected] = useState([])
  const [actualLoc, setActualLoc] = useState("")
  const [selectedLocations, setSelectedLocations] = useState([])

  useEffect(() => {
    if (params?.fid) {
      const getLocations = onSnapshot(doc(db, 'locations', params.fid), (snapshot) => {
        const data = snapshot.data();

        if (data) {
          const locs = params?.lid?.split('-') || [];
          const selected = locs
            .map(loc => Object.values(data).find(x => x.id === loc))
            .filter(Boolean);
          setSelectedLocations(selected);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (selectedLocations.length > 0) {
      setActualLoc(selectedLocations[0]);
    }
  }, [selectedLocations]);

  useEffect(() => {
    if (params?.fid) {
      const getGroups = onSnapshot(doc(db, 'groups', params?.fid), (snapshot) => {
        const data = snapshot.data();
  
        let transformedGroups = [];
  
        Object.keys(data).forEach((groupId) => {
          const group = data[groupId];
  
          if (group.days.length > 1 && !group.rnieozdzielenia) {
            group.days.forEach((day) => {
              const newGroup = {
                ...group,
                days: day, 
              };
              transformedGroups.push(newGroup);
            });
          } else {
            const newGroup = {
              ...group,
              days: group.days[0], 
            };
            transformedGroups.push(newGroup);
          }
        });
  
        setGroups(transformedGroups);
      });
    }
  }, [params?.fid]);

  const chooseGroup = (gr) => {
    if (!selected?.includes(gr?.id)) {
        if (selected?.length === 5) {
        }
        else {
          setSelected([...selected, gr?.id])
        }
    }
    else {
      setSelected(selected?.filter(x => x !== gr?.id))
    }
}

const weekDayConvert = (day) => {
  if (day === 1) return "Poniedziałek"
  if (day === 2) return "Wtorek"
  if (day === 3) return "Środa"
  if (day === 4) return "Czwartek"
  if (day === 5) return "Piątek"
  if (day === 6) return "Sobota"
}

  return (
    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", backgroundColor: "rgb(250,250,250)", width: "100%", minHeight: "100vh", overflowY: "scroll", gap: "30px"})}>
      <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "90%", gap: "15px", marginBottom: "50px", position: "relative"})}>
      <img src={process.env.PUBLIC_URL + "/logosvg.svg"} style={({width: "120px", marginBottom: "-30px"})} />
        <h3 style={({color: "rgb(30,30,30)", fontSize: "27px", textAlign: "center"})}>Wybierz grupę do której chcesz się zapisać</h3>
        <h3 style={({color: "rgb(30,30,30)", fontSize: "13px", fontWeight: "normal", textAlign: "center"})}>Wybierz z listy grupe, do której na zajęcia chcesz uczęszczać, możesz wybrać maksymalnie 5 grupy</h3>
        <div style={({display: "flex", flexDirection: "row", gap: "15px", alignItems: "center", justifyContent: "center", backgroundColor: "rgb(250,250,250)", borderRadius: "10px"})}>
            {selectedLocations && selectedLocations?.length >= 1 && selectedLocations?.map((l) =>
                <div style={({display: "flex", flexDirection: "row", gap: "5px", alignItems: "center", justifyContent: "center", borderBottom: actualLoc === l ? "2px solid #0045C7" : "2px solid rgb(250,250,250)", padding: "3px 5px", cursor: "pointer", transition: "0.4s"})} onClick={() => setActualLoc(l)}>
                    <h3 style={({fontSize: "14px", color: actualLoc === l ? "#0045C7" : "rgb(20,20,20)", transition: "0.35s"})}>{l?.name}</h3>
                </div>
            )}
        </div>
        <h3 style={({color: (selected && selected?.length) ? "#0045C7" : "rgb(0,0,0, 0)", fontSize: "14px", fontWeight: "bold", textAlign: "center", transition: "0.5s", marginBottom: "-10px"})}>{(selected) ? `Wybrane grupy: ${selected ? selected.length : "0"}`: ""}</h3>
        <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "100%", gap: "15px"})}>
            {groups && Object?.values(groups)?.filter(x => x?.location_id === actualLoc?.id)?.filter(x => x?.days?.weekDay === 1)?.length >= 1 && <h3 style={({color: "rgb(30,30,30)", fontSize: "15px", transition: "0.5s", marginTop: "15px"})}>Poniedziałek</h3>}
            {groups && Object?.values(groups)?.filter(x => x?.location_id === actualLoc?.id)?.filter(x => x?.days?.weekDay === 1)?.map((g) => 
                <div style={({display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", cursor: "pointer", padding: "15px", border: selected?.includes(g?.id) ? "2px solid #0045C7" : "2px solid rgba(0,0,0,0)", borderRadius: "5px", width: "80%", transition: "0.5s", boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1.5px, rgb(209, 213, 219) 0px 0px 0px 1px"})} onClick={() => chooseGroup(g)}>
                    <div style={({display: 'flex', flexDirection: 'column', justifyContent: "flex-start", alignItems: "flex-start", gap: "5px", width: "99%"})}>
                        <div style={({display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "flex-start", gap: "5px", width: "100%"})}>
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "12px", transition: "0.5s"})}>{g?.days?.custom_name ? g?.days?.custom_name : g?.type} - {weekDayConvert(g?.days?.weekDay) + " " + g?.days?.startTime + " - " + g?.days?.endTime}</h3>
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "12px", transition: "0.5s"})}>{g?.maxAge === 99 ? ("Dorośli " + g?.minAge + "+") : g?.minAge} {g?.maxAge === 99 ? "" : "- " + g?.maxAge + " lat"}</h3>        
                        </div>         
                        {/* {Object?.values(g?.classes)?.length === 1 && <h3 style={({color: data?.groups?.includes(g?.groupID) ? "#0045C7" : "rgb(30,30,30)", fontSize: "12px", transition: "0.5s", fontWeight: "normal"})}>{Object?.keys(g?.classes)[0] + " " + Object?.values(g?.classes)[0]?.startTime + " - " + Object?.values(g?.classes)[0]?.endTime}</h3>}
                        {Object?.values(g?.classes)?.length > 1 && <h3 style={({color: "#0045C7", fontSize: "12px", transition: "0.5s", fontWeight: "normal"})}>{Object?.values(g?.classes)?.length + " razy w tygodniu"} - pokaż grafik</h3>} */}
                        {/* <div style={({display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "flex-start", gap: "5px", width: "100%"})}>
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "11px", transition: "0.5s", fontWeight: "normal"})}>{g?.exp}</h3>       
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "11px", transition: "0.5s", fontWeight: "normal"})}>{g?.slots}/{g?.slots} wolnych miejsc</h3>    
                        </div>   */}
                    </div>
                </div>
            )}
            {groups && Object?.values(groups)?.filter(x => x?.location_id === actualLoc?.id)?.filter(x => x?.days?.weekDay === 2)?.length >= 1 && <h3 style={({color: "rgb(30,30,30)", fontSize: "15px", transition: "0.5s", marginTop: "15px"})}>Wtorek</h3>}
            {groups && Object?.values(groups)?.filter(x => x?.location_id === actualLoc?.id)?.filter(x => x?.days?.weekDay === 2)?.map((g) => 
                <div style={({display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", cursor: "pointer", padding: "15px", border: selected?.includes(g?.id) ? "2px solid #0045C7" : "2px solid rgba(0,0,0,0)", borderRadius: "5px", width: "80%", transition: "0.5s", boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1.5px, rgb(209, 213, 219) 0px 0px 0px 1px"})} onClick={() => chooseGroup(g)}>
                    <div style={({display: 'flex', flexDirection: 'column', justifyContent: "flex-start", alignItems: "flex-start", gap: "5px", width: "99%"})}>
                        <div style={({display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "flex-start", gap: "5px", width: "100%"})}>
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "12px", transition: "0.5s"})}>{g?.days?.custom_name ? g?.days?.custom_name : g?.type} - {weekDayConvert(g?.days?.weekDay) + " " + g?.days?.startTime + " - " + g?.days?.endTime}</h3>
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "12px", transition: "0.5s"})}>{g?.maxAge === 99 ? ("Dorośli " + g?.minAge + "+") : g?.minAge} {g?.maxAge === 99 ? "" : "- " + g?.maxAge + " lat"}</h3>        
                        </div>         
                        {/* {Object?.values(g?.classes)?.length === 1 && <h3 style={({color: data?.groups?.includes(g?.groupID) ? "#0045C7" : "rgb(30,30,30)", fontSize: "12px", transition: "0.5s", fontWeight: "normal"})}>{Object?.keys(g?.classes)[0] + " " + Object?.values(g?.classes)[0]?.startTime + " - " + Object?.values(g?.classes)[0]?.endTime}</h3>}
                        {Object?.values(g?.classes)?.length > 1 && <h3 style={({color: "#0045C7", fontSize: "12px", transition: "0.5s", fontWeight: "normal"})}>{Object?.values(g?.classes)?.length + " razy w tygodniu"} - pokaż grafik</h3>} */}
                        {/* <div style={({display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "flex-start", gap: "5px", width: "100%"})}>
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "11px", transition: "0.5s", fontWeight: "normal"})}>{g?.exp}</h3>       
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "11px", transition: "0.5s", fontWeight: "normal"})}>{g?.slots}/{g?.slots} wolnych miejsc</h3>    
                        </div>   */}
                    </div>
                </div>
            )}
            {groups && Object?.values(groups)?.filter(x => x?.location_id === actualLoc?.id)?.filter(x => x?.days?.weekDay === 3)?.length >= 1 && <h3 style={({color: "rgb(30,30,30)", fontSize: "15px", transition: "0.5s", marginTop: "15px"})}>Środa</h3>}
            {groups && Object?.values(groups)?.filter(x => x?.location_id === actualLoc?.id)?.filter(x => x?.days?.weekDay === 3)?.map((g) => 
                <div style={({display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", cursor: "pointer", padding: "15px", border: selected?.includes(g?.id) ? "2px solid #0045C7" : "2px solid rgba(0,0,0,0)", borderRadius: "5px", width: "80%", transition: "0.5s", boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1.5px, rgb(209, 213, 219) 0px 0px 0px 1px"})} onClick={() => chooseGroup(g)}>
                    <div style={({display: 'flex', flexDirection: 'column', justifyContent: "flex-start", alignItems: "flex-start", gap: "5px", width: "99%"})}>
                        <div style={({display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "flex-start", gap: "5px", width: "100%"})}>
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "12px", transition: "0.5s"})}>{g?.days?.custom_name ? g?.days?.custom_name : g?.type} - {weekDayConvert(g?.days?.weekDay) + " " + g?.days?.startTime + " - " + g?.days?.endTime}</h3>
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "12px", transition: "0.5s"})}>{g?.maxAge === 99 ? ("Dorośli " + g?.minAge + "+") : g?.minAge} {g?.maxAge === 99 ? "" : "- " + g?.maxAge + " lat"}</h3>        
                        </div>         
                        {/* {Object?.values(g?.classes)?.length === 1 && <h3 style={({color: data?.groups?.includes(g?.groupID) ? "#0045C7" : "rgb(30,30,30)", fontSize: "12px", transition: "0.5s", fontWeight: "normal"})}>{Object?.keys(g?.classes)[0] + " " + Object?.values(g?.classes)[0]?.startTime + " - " + Object?.values(g?.classes)[0]?.endTime}</h3>}
                        {Object?.values(g?.classes)?.length > 1 && <h3 style={({color: "#0045C7", fontSize: "12px", transition: "0.5s", fontWeight: "normal"})}>{Object?.values(g?.classes)?.length + " razy w tygodniu"} - pokaż grafik</h3>} */}
                        {/* <div style={({display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "flex-start", gap: "5px", width: "100%"})}>
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "11px", transition: "0.5s", fontWeight: "normal"})}>{g?.exp}</h3>       
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "11px", transition: "0.5s", fontWeight: "normal"})}>{g?.slots}/{g?.slots} wolnych miejsc</h3>    
                        </div>   */}
                    </div>
                </div>
            )}
            {groups && Object?.values(groups)?.filter(x => x?.location_id === actualLoc?.id)?.filter(x => x?.days?.weekDay === 4)?.length >= 1 && <h3 style={({color: "rgb(30,30,30)", fontSize: "15px", transition: "0.5s", marginTop: "15px"})}>Czwartek</h3>}
            {groups && Object?.values(groups)?.filter(x => x?.location_id === actualLoc?.id)?.filter(x => x?.days?.weekDay === 4)?.map((g) => 
                <div style={({display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", cursor: "pointer", padding: "15px", border: selected?.includes(g?.id) ? "2px solid #0045C7" : "2px solid rgba(0,0,0,0)", borderRadius: "5px", width: "80%", transition: "0.5s", boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1.5px, rgb(209, 213, 219) 0px 0px 0px 1px"})} onClick={() => chooseGroup(g)}>
                    <div style={({display: 'flex', flexDirection: 'column', justifyContent: "flex-start", alignItems: "flex-start", gap: "5px", width: "99%"})}>
                        <div style={({display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "flex-start", gap: "5px", width: "100%"})}>
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "12px", transition: "0.5s"})}>{g?.days?.custom_name ? g?.days?.custom_name : g?.type} - {weekDayConvert(g?.days?.weekDay) + " " + g?.days?.startTime + " - " + g?.days?.endTime}</h3>
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "12px", transition: "0.5s"})}>{g?.maxAge === 99 ? ("Dorośli " + g?.minAge + "+") : g?.minAge} {g?.maxAge === 99 ? "" : "- " + g?.maxAge + " lat"}</h3>        
                        </div>         
                        {/* {Object?.values(g?.classes)?.length === 1 && <h3 style={({color: data?.groups?.includes(g?.groupID) ? "#0045C7" : "rgb(30,30,30)", fontSize: "12px", transition: "0.5s", fontWeight: "normal"})}>{Object?.keys(g?.classes)[0] + " " + Object?.values(g?.classes)[0]?.startTime + " - " + Object?.values(g?.classes)[0]?.endTime}</h3>}
                        {Object?.values(g?.classes)?.length > 1 && <h3 style={({color: "#0045C7", fontSize: "12px", transition: "0.5s", fontWeight: "normal"})}>{Object?.values(g?.classes)?.length + " razy w tygodniu"} - pokaż grafik</h3>} */}
                        {/* <div style={({display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "flex-start", gap: "5px", width: "100%"})}>
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "11px", transition: "0.5s", fontWeight: "normal"})}>{g?.exp}</h3>       
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "11px", transition: "0.5s", fontWeight: "normal"})}>{g?.slots}/{g?.slots} wolnych miejsc</h3>    
                        </div>   */}
                    </div>
                </div>
            )}
            {groups && Object?.values(groups)?.filter(x => x?.location_id === actualLoc?.id)?.filter(x => x?.days?.weekDay === 5)?.length >= 1 && <h3 style={({color: "rgb(30,30,30)", fontSize: "15px", transition: "0.5s", marginTop: "15px"})}>Piątek</h3>}
            {groups && Object?.values(groups)?.filter(x => x?.location_id === actualLoc?.id)?.filter(x => x?.days?.weekDay === 5)?.map((g) => 
                <div style={({display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", cursor: "pointer", padding: "15px", border: selected?.includes(g?.id) ? "2px solid #0045C7" : "2px solid rgba(0,0,0,0)", borderRadius: "5px", width: "80%", transition: "0.5s", boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1.5px, rgb(209, 213, 219) 0px 0px 0px 1px"})} onClick={() => chooseGroup(g)}>
                    <div style={({display: 'flex', flexDirection: 'column', justifyContent: "flex-start", alignItems: "flex-start", gap: "5px", width: "99%"})}>
                        <div style={({display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "flex-start", gap: "5px", width: "100%"})}>
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "12px", transition: "0.5s"})}>{g?.days?.custom_name ? g?.days?.custom_name : g?.type} - {weekDayConvert(g?.days?.weekDay) + " " + g?.days?.startTime + " - " + g?.days?.endTime}</h3>
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "12px", transition: "0.5s"})}>{g?.maxAge === 99 ? ("Dorośli " + g?.minAge + "+") : g?.minAge} {g?.maxAge === 99 ? "" : "- " + g?.maxAge + " lat"}</h3>        
                        </div>         
                        {/* {Object?.values(g?.classes)?.length === 1 && <h3 style={({color: data?.groups?.includes(g?.groupID) ? "#0045C7" : "rgb(30,30,30)", fontSize: "12px", transition: "0.5s", fontWeight: "normal"})}>{Object?.keys(g?.classes)[0] + " " + Object?.values(g?.classes)[0]?.startTime + " - " + Object?.values(g?.classes)[0]?.endTime}</h3>}
                        {Object?.values(g?.classes)?.length > 1 && <h3 style={({color: "#0045C7", fontSize: "12px", transition: "0.5s", fontWeight: "normal"})}>{Object?.values(g?.classes)?.length + " razy w tygodniu"} - pokaż grafik</h3>} */}
                        {/* <div style={({display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "flex-start", gap: "5px", width: "100%"})}>
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "11px", transition: "0.5s", fontWeight: "normal"})}>{g?.exp}</h3>       
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "11px", transition: "0.5s", fontWeight: "normal"})}>{g?.slots}/{g?.slots} wolnych miejsc</h3>    
                        </div>   */}
                    </div>
                </div>
            )}
            {groups && Object?.values(groups)?.filter(x => x?.location_id === actualLoc?.id)?.filter(x => x?.days?.weekDay === 6)?.length >= 1 && <h3 style={({color: "rgb(30,30,30)", fontSize: "15px", transition: "0.5s", marginTop: "15px"})}>Sobota</h3>}
            {groups && Object?.values(groups)?.filter(x => x?.location_id === actualLoc?.id)?.filter(x => x?.days?.weekDay === 6)?.map((g) => 
                <div style={({display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", cursor: "pointer", padding: "15px", border: selected?.includes(g?.id) ? "2px solid #0045C7" : "2px solid rgba(0,0,0,0)", borderRadius: "5px", width: "80%", transition: "0.5s", boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1.5px, rgb(209, 213, 219) 0px 0px 0px 1px"})} onClick={() => chooseGroup(g)}>
                    <div style={({display: 'flex', flexDirection: 'column', justifyContent: "flex-start", alignItems: "flex-start", gap: "5px", width: "99%"})}>
                        <div style={({display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "flex-start", gap: "5px", width: "100%"})}>
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "12px", transition: "0.5s"})}>{g?.days?.custom_name ? g?.days?.custom_name : g?.type} - {weekDayConvert(g?.days?.weekDay) + " " + g?.days?.startTime + " - " + g?.days?.endTime}</h3>
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "12px", transition: "0.5s"})}>{g?.maxAge === 99 ? ("Dorośli " + g?.minAge + "+") : g?.minAge} {g?.maxAge === 99 ? "" : "- " + g?.maxAge + " lat"}</h3>        
                        </div>         
                        {/* {Object?.values(g?.classes)?.length === 1 && <h3 style={({color: data?.groups?.includes(g?.groupID) ? "#0045C7" : "rgb(30,30,30)", fontSize: "12px", transition: "0.5s", fontWeight: "normal"})}>{Object?.keys(g?.classes)[0] + " " + Object?.values(g?.classes)[0]?.startTime + " - " + Object?.values(g?.classes)[0]?.endTime}</h3>}
                        {Object?.values(g?.classes)?.length > 1 && <h3 style={({color: "#0045C7", fontSize: "12px", transition: "0.5s", fontWeight: "normal"})}>{Object?.values(g?.classes)?.length + " razy w tygodniu"} - pokaż grafik</h3>} */}
                        {/* <div style={({display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "flex-start", gap: "5px", width: "100%"})}>
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "11px", transition: "0.5s", fontWeight: "normal"})}>{g?.exp}</h3>       
                            <h3 style={({color: selected?.includes(g?.id) ? "#0045C7" : "rgb(30,30,30)", fontSize: "11px", transition: "0.5s", fontWeight: "normal"})}>{g?.slots}/{g?.slots} wolnych miejsc</h3>    
                        </div>   */}
                    </div>
                </div>
            )}
        </div>
        <Link to={`/rejestracja/${params?.fid}/${params?.lid}/${selected?.join("-")}`}><button style={({fontSize: "13px", color: "white", borderRadius: "5px", marginTop: "20px", backgroundColor: "#0045C7", border: "2px solid #0045C7", transition: "0.7s", fontWeight: "bold", padding: "10px 30px"})} disabled={selected?.length === 0}>Potwierdź wybór</button></Link>
        {/* {timeTable &&
            <div style={({display: "flex", flexDirection: "row", gap: "25px", justifyContent: "center", alignItems: "center", backgroundColor: "rgb(1,1,1,0.8)", width: "100%", height: "100vh", position: "fixed", top: "0", left: "0"})} onClick={() => setTimeTable(false)}>
                {Object?.values(timeTable?.classes)?.sort(function(a, b) { return a?.day - b?.day; })?.map((c, index) => 
                    <div className="home-panel" style={({display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "20px", backgroundColor: "rgb(245,245,245)", borderRadius: "5px", cursor: 'pointer'})}>
                        <img className="home-panel-img" src={process.env.PUBLIC_URL + (index === 0 ? "/one.png" : index === 1 ? "/two.png" : "/three.png")}/>
                        <h3 className="home-panel-title" style={({color: "rgb(30,30,30)", fontWeight: "bold", textAlign: "center"})}>{convertToDay(c)} {c?.startTime} - {c?.endTime}</h3>
                        <h3 className="home-panel-text" style={({color: "rgb(30,30,30)", fontWeight: "normal", textAlign: "center"})}>{c?.desc}</h3>
                        <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", gap: "5px"})}>
                            <h3 className="home-panel-text" style={({color: "rgb(0, 153, 255)", fontWeight: "bold", textAlign: "center"})}>{c?.address}</h3>
                        </div>
                    </div>
                )}
            </div>
        } */}
      </div>
    </div>
  );
}

export default GroupsSelect;