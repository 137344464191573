
import { useRef, useState } from "react";
import CanvasDraw from "react-canvas-draw";
import { PDFDownloadLink, Page, View, Document, Text, Image, Font, StyleSheet, PDFViewer, PDFRenderer } from "@react-pdf/renderer";

export default function ParentStatement({user, parent, signing, acc, atributes, date}) {

    Font.register({
        family: "Roboto",
        fonts: [
          { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf", fontWeight: 300 },
          { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf", fontWeight: 400 },
          { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf", fontWeight: 500 },
          { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf", fontWeight: 600 },
        ],
      })
      
    
      const styles = StyleSheet.create({
        body: {
          paddingTop: 25,
          paddingBottom: 65,
          paddingHorizontal: 35,
          width: "50%"
        },
        title: {
          fontSize: 24,
          textAlign: 'center',
          fontFamily: 'Roboto',
          fontWeight: 600,
          marginBottom: 20
        },
        title2: {
            fontSize: 20,
            textAlign: 'center',
            fontFamily: 'Roboto',
            fontWeight: 500
          },
        author: {
          fontSize: 12,
          textAlign: 'center',
          marginTop: 5
        },        author: {
          fontSize: 12,
          textAlign: 'center',
          marginTop: 5
        },
        date: {
            fontSize: 16,
            textAlign: 'right',
            marginBottom: 30,
            marginTop: 10,
            fontWeight: 500,
            fontFamily: 'Roboto',
          },
          name: {
            fontSize: 16,
            textAlign: 'left',
            marginBottom: 30,
            marginTop: 10,
            fontWeight: 500,
            fontFamily: 'Roboto',
          },
        subtitle: {
          fontSize: 9,
          textAlign: 'center',
          fontFamily: 'Roboto'
        },
        subtitle2: {
            fontSize: 9,
            textAlign: 'center',
            fontFamily: 'Roboto',
            marginBottom: 30
          },
        text: {
          margin: 7,
          fontSize: 12,
          textAlign: 'justify',
          fontFamily: 'Roboto'
        },
        textH: {
            margin: 3,
            fontSize: 12,
            textAlign: 'justify',
            fontFamily: 'Roboto',
            width: "80%"
          },
        textB: {
            margin: 7,
            fontSize: 12,
            textAlign: 'justify',
            fontFamily: 'Roboto',   
            fontWeight: 500
          },
        signs: {
            margin: 12,
            fontSize: 12,
            fontFamily: 'Roboto',
            fontWeight: 500
          },
        image: {
          marginVertical: 15,
          marginHorizontal: 10,
          width: 55,
          height: 55,
          alignSelf: "center"
        },
        imageTest: {
            position: "absolute",
            left: "0px",
            right: "0px",
            width: "100vw",
            height: "100vh",
            marginHorizontal: 'auto'
          },
        imageSign: {
            width: 85,
            height: 85,
            alignSelf: "center"
          },
          imageSignMini: {
            width: 60,
            height: 60,
            alignSelf: "center"
          },
        header: {
          fontSize: 12,
          marginBottom: 20,
          textAlign: 'center',
          color: 'grey',
        },
        pageNumber: {
          position: 'absolute',
          fontSize: 12,
          bottom: 30,
          left: 0,
          right: 0,
          textAlign: 'center',
          color: 'grey',
        },
      });

return (
    <>
        {/* <PDFViewer width={"300px"} height={"500px"} children={Parent}>
        </PDFViewer> */}
        <PDFDownloadLink style={{textDecoration: "none", fontSize: "12px", color: "rgb(30,30,30)", cursor: "pointer"}} document={
          <Document>
            <Page style={styles.body}>
                <Image
                    style={styles.imageTest}
                    src={process.env.PUBLIC_URL + "/Oswiadczenie_rodzica1.png"}
                    fixed
                />
                <Text style={{top: "38px", left: "448px", fontSize: "10px", fontFamily: 'Roboto'}}>{date}</Text>
                <Text style={{top: "119px", left: "171px", fontSize: "10px", fontFamily: 'Roboto'}}>{parent?.displayName}</Text>
                <Text style={{top: "106px", left: "384px", fontSize: "10px", fontFamily: 'Roboto'}}>{parent?.phone}</Text>
                <Text style={{top: "111px", left: "45px", fontSize: "10px", fontFamily: 'Roboto'}}>{acc?.email}</Text>
                <Text style={{top: "118px", left: "169px", fontSize: "10px", fontFamily: 'Roboto'}}>{user?.displayName}</Text>
                <Text style={{top: "106px", left: "375px", fontSize: "10px", fontFamily: 'Roboto'}}>{user?.dateOfBirth}</Text>
                {signing && 
                  <Image
                      style={{top: "342px", left: "460px", width: "70px", height: "20px"}}
                      src={signing}
                      fixed
                  />
                }
                {signing && 
                  <Image
                      style={{top: "361px", left: "514px", width: "27px", height: "18px"}}
                      src={process.env.PUBLIC_URL + "icon_circle.png"}
                      fixed
                  />
                }
                {signing && 
                  <Image
                      style={{top: "361.5px", left: "514px", width: "27px", height: "18px"}}
                      src={process.env.PUBLIC_URL + "icon_circle.png"}
                      fixed
                  />
                }
                {signing && 
                  <Image
                      style={{top: "362px", left: "514px", width: "27px", height: "18px"}}
                      src={process.env.PUBLIC_URL + "icon_circle.png"}
                      fixed
                  />
                }
                {signing && 
                  <Image
                      style={{top: "362px", left: "514px", width: "27px", height: "18px"}}
                      src={process.env.PUBLIC_URL + "icon_circle.png"}
                      fixed
                  />
                }
                {signing && 
                  <Image
                      style={{top: "363px", left: "514px", width: "27px", height: "18px"}}
                      src={process.env.PUBLIC_URL + "icon_circle.png"}
                      fixed
                  />
                } 
                {signing && 
                  <Image
                      style={{top: "364px", left: "514px", width: "27px", height: "18px"}}
                      src={process.env.PUBLIC_URL + "icon_circle.png"}
                      fixed
                  />
                }
            </Page>
            <Page style={styles.body}>
                <Image
                    style={styles.imageTest}
                    src={process.env.PUBLIC_URL + "/Oswiadczenie_rodzica2.png"}
                    fixed
                />
                {signing && 
                  <Image
                      style={{top: "92px", left: "481px", width: "27px", height: "18px"}}
                      src={process.env.PUBLIC_URL + "icon_circle.png"}
                      fixed
                  />
                }
                {signing &&
                  <Image
                      style={{top: "220px", left: "450px", width: "80px"}}
                      src={signing}
                      fixed
                  />
                }
            </Page>
          </Document>
        }>{({loading}) => 
          (loading ? 
            <div style={({display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: "15px", cursor: "pointer", padding: "6px", borderRadius: "5px", width: "280px"})}>
              <img src={process.env.PUBLIC_URL + "/emptyFile.png"} style={({width: "24px"})}/>
              <h3 style={({textDecoration: "none", fontSize: "12px", color: "rgb(30,30,30)", cursor: "pointer", fontWeight: "bold", width: "215px"})}>Generowanie oświadczenia rodzica...</h3>
            </div>
        :
          <div style={({display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: "15px", cursor: "pointer", padding: "6px", borderRadius: "5px", width: "280px"})}>
            <img src={process.env.PUBLIC_URL + "/emptyFile.png"} style={({width: "24px"})}/>
            <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: 'flex-start'})}>
              <h3 style={({textDecoration: "none", fontSize: "12px", color: "rgb(30,30,30)", cursor: "pointer", fontWeight: "bold", width: "215px"})}>Oświadczenie rodzica dot. {user?.displayName}</h3>
            </div>
          </div>
        )}
        </PDFDownloadLink>
    </>
    )
}