import React, { act, useEffect, useState } from 'react';
import { arrayRemove, arrayUnion, collection, doc, onSnapshot, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import ArrowBack from '../components/arrowBack';

export default function BookInfoPanel() {

  const [facilities, setFacilities] = useState([])
  const [locations, setLocations] = useState("")

  const [facility, setFacility] = useState("")
  const [location, setLocation] = useState("")
  const [groups, setGroups] = useState("")

  const [selectedGroup, setSelectedGroup] = useState("")
  const [list, setList] = useState("")

  const [newUser, setNewUser] = useState("")
  const [user, setUser] = useState("")

  const [userList, setUserList] = useState("")
  const [bookMark, setBookMark] = useState(0)

  useEffect(() => {
    const getFacilities = onSnapshot(collection(db, "facilities"), (snapshot) => {
      setFacilities(snapshot.docs.map((doc) => (doc.data())));
    });
  }, []);

  useEffect(() => {
    if (!facility) return
    const getLocations = onSnapshot(doc(db, "locations", facility?.id), (snapshot) => {
      setLocations(snapshot.data());
    });
  }, [facility]);
  
  useEffect(() => {
    if (selectedGroup === "") return
    const getList = onSnapshot(doc(db, "book-list", selectedGroup?.id), (snapshot) => {
      setList(snapshot.data());
    });
  }, [selectedGroup]);
  

    useEffect(() => {
      if (bookMark === 0) return 
      const getList = onSnapshot(collection(db, "book-list"), (snapshot) => {
        let list = (snapshot.docs.map((doc) => (doc.data())));

        let uList = []
        for (let i = 0; i < list?.length; i++) {
          for(let x = 0; x < list[i]?.users?.length; x++) {
            uList?.push({user: list[i]?.users[x], group: list[i]})
          }
        }

        const uniqueArray = uList.reduce((acc, current) => {
          const x = acc.find(item => item.user?.displayName === current.user?.displayName);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

        setUserList(uniqueArray)
      });
    }, [bookMark]);

  useEffect(() => {
    if (location) {
      const getGroups = onSnapshot(doc(db, 'groups', facility?.id), (snapshot) => {
        const data = snapshot.data();
  
        let transformedGroups = [];
  
        Object.keys(data).forEach((groupId) => {
          const group = data[groupId];
  
          if (group.days.length > 1 && !group.rnieozdzielenia) {
            group.days.forEach((day) => {
              const newGroup = {
                ...group,
                days: day, 
              };
              transformedGroups.push(newGroup);
            });
          } else {
            const newGroup = {
              ...group,
              days: group.days[0], 
            };
            transformedGroups.push(newGroup);
          }
        });
  
        setGroups(transformedGroups);
      });
    }
  }, [location]);

  const [error, setError] = useState("") 

  const addUser = () => {
    if (!newUser?.displayName || !newUser?.mail || !newUser?.phone) {
      setError("Nie podano wszystkich danych")
      return
    }

    setDoc(doc(db, "book-list", selectedGroup?.id), {
      users: arrayUnion({
        displayName: newUser?.displayName,
        phone: newUser?.phone,
        mail: newUser?.mail,
        age: newUser?.age ? newUser?.age : "",
        info: newUser?.info ? newUser?.info : "",
      })
    }, {merge: true})

    setNewUser("")
  }

  const editUser = () => {
    if (!user?.displayName === "" || !user?.mail === "" || !user?.phone === "") {
      setError("Nie można usunąć podstawowych danych")
      return
    }

    updateDoc(doc(db, "book-list", selectedGroup?.id), {
      users: arrayRemove({
        displayName: user?.displayName,
        phone: user?.phone,
        mail: user?.mail,
        age: user?.age,
        info: user?.info 
      }),
    }, {merge: true})

    updateDoc(doc(db, "book-list", selectedGroup?.id), {
      users: arrayUnion({
        displayName: user?.new_displayName ? user?.new_displayName : user?.displayName,
        phone: user?.new_phone ? user?.new_phone : user?.phone,
        mail: user?.new_mail ? user?.new_mail : user?.mail,
        age: user?.new_age ? user?.new_age : user?.age,
        info: user?.new_info ? user?.new_info : user?.info,
      }),
    }, {merge: true})

    setUser("")
  }

  const deleteUser = () => {
    updateDoc(doc(db, "book-list", selectedGroup?.id), {
      users: arrayRemove({
        displayName: user?.displayName,
        phone: user?.phone,
        mail: user?.mail,
        age: user?.age,
        info: user?.info 
      }),
    }, {merge: true})

    setUser("")
  }

  const weekDayConvert = (day) => {
    if (day === 1) return "Poniedziałek"
    if (day === 2) return "Wtorek"
    if (day === 3) return "Środa"
    if (day === 4) return "Czwartek"
    if (day === 5) return "Piątek"
    if (day === 6) return "Sobota"
  }

    return (
      <div style={({display: "flex", gap: "40px", margin: "3%", width: "97%", marginTop: "20px"})}>
        {!facility ?
          bookMark === 0 ?
          <div style={({display: "flex", gap: "40px", width: "100%"})}>
            {facilities?.map((f) =>
              <div style={({display: "flex", gap: "22px", justifyContent: 'center', alignItems: "center", flexDirection: "column", padding: "30px", border: "1px solid rgb(30,30,30)", borderRadius: "5px"})} onClick={() => setFacility(f)}>
                <img style={({width: "120px"})}src="logo_black.png" />
                <h3 >{f?.desc}</h3>
              </div>
            )}
            <div style={({display: "flex", gap: "22px", justifyContent: 'center', alignItems: "center", flexDirection: "column", padding: "30px", border: "1px solid rgb(30,30,30)", borderRadius: "5px"})} onClick={() => setBookMark(1)}>
              <img style={({width: "120px"})}src="logo_black.png" />
              <h3>Użytkownicy</h3>
            </div>
          </div>
          :
          userList &&
          <div style={({display: "flex", gap: "40px", width: "100%", flexDirection: "column"})}>
            <h3>Użytkownicy: {userList?.length}</h3>
            {userList?.map((u) =>
              <h3>{u?.user?.displayName} - {u?.user?.phone} - {u?.user?.mail}</h3>
            )}
          </div>
        :
          !location ?
            <div style={({display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexWrap: "wrap", width: "95%", gap: "20px"})}>
              <ArrowBack onClick={() => setFacility("")}/>
              {locations &&
              Object?.values(locations)?.sort((a, b) => a.name.localeCompare(b.name))?.map((l) =>
                <h3 onClick={() => setLocation(l)} style={({background: l?.color, borderRadius: "5px", padding: "10px 20px", color: "rgb(250,250,250)"})}>{l?.name}</h3>
              )}
            </div>
          :
          groups &&
              !selectedGroup ?
                <div style={({display: "flex", gap: "17px", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "column", width: "100%"})}>
                  <h3 style={({fontSize: "22px", color: location?.color})}>{location?.name}</h3>
                  <div style={({display: "flex", gap: "26px", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "row", width: "100%"})}>
                    <ArrowBack onClick={() => setLocation("")}/>
                    {Object?.values(groups)?.filter(x => x?.location_id === location?.id)?.filter(x => x?.days?.weekDay === 1)?.length > 0 &&
                      <div style={({display: "flex", gap: "12px", justifyContent: 'flex-start', alignItems: "center", flexDirection: "column", width: "14%"})}>
                        <h3 style={({fontSize: "13px", color: "#f5f5f5", background: location?.color, padding: "10px", width: "100%", borderRadius: '5px', textAlign: "center"})}>PONIEDZIAŁEK</h3>
                        {Object?.values(groups)?.filter(x => x?.location_id === location?.id)?.filter(x => x?.days?.weekDay === 1)?.sort((a, b) => { const timeA = a?.days?.startTime.split(':').map(Number); const timeB = b?.days?.startTime.split(':').map(Number); return timeA[0] - timeB[0] || timeA[1] - timeB[1]; })?.map((g) =>
                          <div style={({display: "flex", gap: "5px", justifyContent: 'space-around', alignItems: "center", flexDirection: "column", width: "90%", padding: "10%", borderRadius: "5px", border: "1px solid rgb(40,40,40)", height: "100px"})} onClick={() => setSelectedGroup(g)}>
                            <h3 style={({fontSize: "14px", color: "rgb(40,40,40)"})}>{g?.days?.startTime} - {g?.days?.endTime}</h3>
                            <h3 style={({fontSize: "14px", color: location?.color, textAlign: "center"})}>{g?.days?.custom_name ? g?.days?.custom_name : g?.type}</h3>
                            <h3 style={({fontSize: "12px", color: "rgb(40,40,40)"})}>{g?.maxAge === 99 ? (g?.adults ? `Dorośli ${g?.minAge} +` : `${g?.minAge} +`) : `${g?.minAge} - ${g?.maxAge} lat`}</h3>
                            <h3 style={({fontSize: "10px", color: "rgb(40,40,40)", textAlign: "center"})}>{g?.exp}</h3>
                          </div>
                        )}
                      </div>
                    }
                    {Object?.values(groups)?.filter(x => x?.location_id === location?.id)?.filter(x => x?.days?.weekDay === 2)?.length > 0 &&
                      <div style={({display: "flex", gap: "12px", justifyContent: 'flex-start', alignItems: "center", flexDirection: "column", width: "14%"})}>
                        <h3 style={({fontSize: "13px", color: "#f5f5f5", background: location?.color, padding: "10px", width: "100%", borderRadius: '5px', textAlign: "center"})}>WTOREK</h3>
                        {Object?.values(groups)?.filter(x => x?.location_id === location?.id)?.filter(x => x?.days?.weekDay === 2)?.sort((a, b) => { const timeA = a?.days?.startTime.split(':').map(Number); const timeB = b?.days?.startTime.split(':').map(Number); return timeA[0] - timeB[0] || timeA[1] - timeB[1]; })?.map((g) =>
                          <div style={({display: "flex", gap: "5px", justifyContent: 'space-around', alignItems: "center", flexDirection: "column", width: "90%", padding: "10%", borderRadius: "5px", border: "1px solid rgb(40,40,40)", height: "100px"})} onClick={() => setSelectedGroup(g)}>
                            <h3 style={({fontSize: "14px", color: "rgb(40,40,40)"})}>{g?.days?.startTime} - {g?.days?.endTime}</h3>
                            <h3 style={({color: location?.color, textAlign: "center", width: "100%", fontSize: "14px"})}>{g?.days?.custom_name ? g?.days?.custom_name : g?.type}</h3>
                            <h3 style={({fontSize: "12px", color: "rgb(40,40,40)"})}>{g?.maxAge === 99 ? (g?.adults ? `Dorośli ${g?.minAge} +` : `${g?.minAge} +`) : `${g?.minAge} - ${g?.maxAge} lat`}</h3>
                            <h3 style={({fontSize: "10px", color: "rgb(40,40,40)", textAlign: "center"})}>{g?.exp}</h3>
                          </div>
                        )}
                      </div>
                    }
                    {Object?.values(groups)?.filter(x => x?.location_id === location?.id)?.filter(x => x?.days?.weekDay === 3)?.length > 0 &&
                      <div style={({display: "flex", gap: "12px", justifyContent: 'flex-start', alignItems: "center", flexDirection: "column", width: "14%"})}>
                        <h3 style={({fontSize: "13px", color: "#f5f5f5", background: location?.color, padding: "10px", width: "100%", borderRadius: '5px', textAlign: "center"})}>ŚRODA</h3>
                        {Object?.values(groups)?.filter(x => x?.location_id === location?.id)?.filter(x => x?.days?.weekDay === 3)?.sort((a, b) => { const timeA = a?.days?.startTime.split(':').map(Number); const timeB = b?.days?.startTime.split(':').map(Number); return timeA[0] - timeB[0] || timeA[1] - timeB[1]; })?.map((g) =>
                          <div style={({display: "flex", gap: "5px", justifyContent: 'space-around', alignItems: "center", flexDirection: "column", width: "90%", padding: "10%", borderRadius: "5px", border: "1px solid rgb(40,40,40)", height: "100px", position: "relative"})} onClick={() => setSelectedGroup(g)}>
                            <h3 style={({fontSize: "14px", color: "rgb(40,40,40)"})}>{g?.days?.startTime} - {g?.days?.endTime}</h3>
                            <h3 style={({fontSize: "14px", color: location?.color, textAlign: "center"})}>{g?.days?.custom_name ? g?.days?.custom_name : g?.type}</h3>
                            <h3 style={({fontSize: "12px", color: "rgb(40,40,40)"})}>{g?.maxAge === 99 ? (g?.adults ? `Dorośli ${g?.minAge} +` : `${g?.minAge} +`) : `${g?.minAge} - ${g?.maxAge} lat`}</h3>
                            <h3 style={({fontSize: "10px", color: "rgb(40,40,40)", textAlign: "center"})}>{g?.exp}</h3>
                          </div>
                        )}
                      </div>
                    }
                    {Object?.values(groups)?.filter(x => x?.location_id === location?.id)?.filter(x => x?.days?.weekDay === 4)?.length > 0 &&
                      <div style={({display: "flex", gap: "12px", justifyContent: 'flex-start', alignItems: "center", flexDirection: "column", width: "14%"})}>
                        <h3 style={({fontSize: "13px", color: "#f5f5f5", background: location?.color, padding: "10px", width: "100%", borderRadius: '5px', textAlign: "center"})}>CZWARTEK</h3>
                        {Object?.values(groups)?.filter(x => x?.location_id === location?.id)?.filter(x => x?.days?.weekDay === 4)?.sort((a, b) => { const timeA = a?.days?.startTime.split(':').map(Number); const timeB = b?.days?.startTime.split(':').map(Number); return timeA[0] - timeB[0] || timeA[1] - timeB[1]; })?.map((g) =>
                          <div style={({display: "flex", gap: "5px", justifyContent: 'space-around', alignItems: "center", flexDirection: "column", width: "90%", padding: "10%", borderRadius: "5px", border: "1px solid rgb(40,40,40)", height: "100px"})} onClick={() => setSelectedGroup(g)}>
                            <h3 style={({fontSize: "14px", color: "rgb(40,40,40)"})}>{g?.days?.startTime} - {g?.days?.endTime}</h3>
                            <h3 style={({fontSize: "14px", color: location?.color, textAlign: "center"})}>{g?.days?.custom_name ? g?.days?.custom_name : g?.type}</h3>
                            <h3 style={({fontSize: "12px", color: "rgb(40,40,40)"})}>{g?.maxAge === 99 ? (g?.adults ? `Dorośli ${g?.minAge} +` : `${g?.minAge} +`) : `${g?.minAge} - ${g?.maxAge} lat`}</h3>
                            <h3 style={({fontSize: "10px", color: "rgb(40,40,40)", textAlign: "center"})}>{g?.exp}</h3>
                          </div>
                        )}
                      </div>
                    }
                    {Object?.values(groups)?.filter(x => x?.location_id === location?.id)?.filter(x => x?.days?.weekDay === 5)?.length > 0 &&
                      <div style={({display: "flex", gap: "12px", justifyContent: 'flex-start', alignItems: "center", flexDirection: "column", width: "14%"})}>
                        <h3 style={({fontSize: "13px", color: "#f5f5f5", background: location?.color, padding: "10px", width: "100%", borderRadius: '5px', textAlign: "center"})}>PIĄTEK</h3>
                        {Object?.values(groups)?.filter(x => x?.location_id === location?.id)?.filter(x => x?.days?.weekDay === 5)?.sort((a, b) => { const timeA = a?.days?.startTime.split(':').map(Number); const timeB = b?.days?.startTime.split(':').map(Number); return timeA[0] - timeB[0] || timeA[1] - timeB[1]; })?.map((g) =>
                          <div style={({display: "flex", gap: "5px", justifyContent: 'space-around', alignItems: "center", flexDirection: "column", width: "90%", padding: "10%", borderRadius: "5px", border: "1px solid rgb(40,40,40)", height: "100px"})} onClick={() => setSelectedGroup(g)}>
                            <h3 style={({fontSize: "14px", color: "rgb(40,40,40)"})}>{g?.days?.startTime} - {g?.days?.endTime}</h3>
                            <h3 style={({fontSize: "14px", color: location?.color, textAlign: "center"})}>{g?.days?.custom_name ? g?.days?.custom_name : g?.type}</h3>
                            <h3 style={({fontSize: "12px", color: "rgb(40,40,40)"})}>{g?.maxAge === 99 ? (g?.adults ? `Dorośli ${g?.minAge} +` : `${g?.minAge} +`) : `${g?.minAge} - ${g?.maxAge} lat`}</h3>
                            <h3 style={({fontSize: "10px", color: "rgb(40,40,40)", textAlign: "center"})}>{g?.exp}</h3>
                          </div>
                        )}
                      </div>
                    }
                    {Object?.values(groups)?.filter(x => x?.location_id === location?.id)?.filter(x => x?.days?.weekDay === 6)?.length > 0 &&
                      <div style={({display: "flex", gap: "12px", justifyContent: 'flex-start', alignItems: "center", flexDirection: "column", width: "14%"})}>
                        <h3 style={({fontSize: "13px", color: "#f5f5f5", background: location?.color, padding: "10px", width: "100%", borderRadius: '5px', textAlign: "center"})}>SOBOTA</h3>
                        {Object?.values(groups)?.filter(x => x?.location_id === location?.id)?.filter(x => x?.days?.weekDay === 6)?.sort((a, b) => { const timeA = a?.days?.startTime.split(':').map(Number); const timeB = b?.days?.startTime.split(':').map(Number); return timeA[0] - timeB[0] || timeA[1] - timeB[1]; })?.map((g) =>
                          <div style={({display: "flex", gap: "5px", justifyContent: 'space-around', alignItems: "center", flexDirection: "column", width: "90%", padding: "10%", borderRadius: "5px", border: "1px solid rgb(40,40,40)", height: "100px"})} onClick={() => setSelectedGroup(g)}>
                            <h3 style={({fontSize: "14px", color: "rgb(40,40,40)"})}>{g?.days?.startTime} - {g?.days?.endTime}</h3>
                            <h3 style={({fontSize: "14px", color: location?.color, textAlign: "center"})}>{g?.days?.custom_name ? g?.days?.custom_name : g?.type}</h3>
                            <h3 style={({fontSize: "12px", color: "rgb(40,40,40)"})}>{g?.maxAge === 99 ? (g?.adults ? `Dorośli ${g?.minAge} +` : `${g?.minAge} +`) : `${g?.minAge} - ${g?.maxAge} lat`}</h3>
                            <h3 style={({fontSize: "10px", color: "rgb(40,40,40)", textAlign: "center"})}>{g?.exp}</h3>
                          </div>
                        )}
                      </div>
                    }
                  </div>
                </div>
                :
                !selectedGroup?.split ?
                  <div style={({display: "flex", gap: "32px", justifyContent: 'space-between', alignItems: "flex-start", flexDirection: "row", width: "85%", marginLeft: "10px"})}>
                    <div style={({display: "flex", gap: "5px", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "column"})}>
                      <ArrowBack onClick={() => {setList(""); setSelectedGroup("")}}/>
                      <h3 style={({fontSize: "18px", color: location?.color})}>{location?.name} - {selectedGroup?.days?.custom_name ? selectedGroup?.days?.custom_name : selectedGroup?.type}</h3>
                      <h3 style={({fontSize: "16px", color: "rgb(30,30,30)", marginTop: "3px"})}>{weekDayConvert(selectedGroup?.days?.weekDay)} {selectedGroup?.days?.startTime} - {selectedGroup?.days?.endTime}</h3>
                      <h3 style={({fontSize: "14px", color: "rgb(30,30,30)", marginTop: "15px"})}>Poziom: {selectedGroup?.exp}</h3>
                      <h3 style={({fontSize: "14px", color: "rgb(30,30,30)", marginTop: "15px"})}>Grupa wiekowa: {selectedGroup?.maxAge === 99 ? (selectedGroup?.adults ? `Dorośli ${selectedGroup?.minAge} +` : `${selectedGroup?.minAge} +`) : `${selectedGroup?.minAge} - ${selectedGroup?.maxAge} lat`}</h3>
                      <h3 style={({fontSize: "14px", color: "rgb(30,30,30)", marginTop: "15px"})}>Instruktor: {selectedGroup?.instructor ? selectedGroup?.instructor : "Nie podano"}</h3>
                      <h3 style={({fontSize: "18px", color: location?.color, marginTop: "25px"})}>Cennik:</h3>
                      <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", marginTop: "5px"})}>Umowa z wypowiedzeniem miesięcznym: {selectedGroup?.prices?.contract} zł</h3>
                      <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", marginTop: "5px"})}>Wejście jednorazowe: {selectedGroup?.prices?.entry} zł</h3>
                      <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", marginTop: "5px"})}>Umowa bez możliwości wypowiedzenia: {selectedGroup?.prices?.obligation === "Nie dotyczy" ? "Nie dotyczy" : selectedGroup?.prices?.obligation + " zł"}</h3>
                      <h3 style={({fontSize: "14px", color: "rgb(30,30,30)", marginTop: "35px"})}>Okres wypowiedzenia umowy: {selectedGroup?.adults ? "Koniec danego miesiąca" : "Miesiąc"}</h3>
                      <h3 style={({fontSize: "14px", color: location?.color, marginTop: "15px"})}>Dodatkowy opis grupy: {selectedGroup?.desc ? selectedGroup?.desc  : "Nie podano"}</h3>
                    </div>
                    <div style={({display: "flex", gap: "5px", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "column"})}>
                      <h3 style={({fontSize: "16px", color: location?.color})}>Maksymalna ilość miejsc: {selectedGroup?.slots}</h3>
                      <h3 style={({fontSize: "18px", color: "rgb(30,30,30)", marginTop: "5px"})}>Lista:</h3>
                      {list?.users?.length > 0 ?
                        list?.users?.map((u, index) =>
                          <div style={({display: "flex", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "column"})} onClick={() => setUser(u)}>
                            <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", marginTop: "5px"})}>{index + 1}. {u?.displayName} {u?.age ? "- " + u?.age + " lat" : ""}</h3>
                            <h3 style={({fontSize: "13px", color: "rgb(30,30,30)", fontWeight: "normal", marginLeft: "15px"})}>tel: {u?.phone} - mail: {u?.mail}</h3>
                            {u?.info && <h3 style={({fontSize: "13px", color: "rgb(30,30,30)", fontWeight: "normal", marginLeft: "15px", maxWidth: "200px"})}>Dodatkowe informacje: {u?.info}</h3>}
                          </div>
                        )
                      :
                        <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", marginTop: "5px"})}>Brak uczestników</h3>
                      }
                      {list?.users?.length < selectedGroup?.slots && <button onClick={() => setNewUser({active: true})} style={({padding: "6px 12px", backgroundColor: location?.color, border: "none", outline: "none", borderRadius: "5px", fontWeight: "bold", color: "rgb(245,245,245)", marginTop: "20px", fontSize: "12px"})}>Dodaj uczestnika do listy</button>}
                      {list?.reserves?.length > 0 && <h3>Rezerwowi:</h3>}
                      {list?.reserves?.length > 0 && list?.reserves?.map((r, index) =>
                        <h3 onClick={() => setUser(r)}>{index + 1}. {r?.displayName}</h3>
                      )}
                      {list?.reserves?.length > 0 && <button onClick={() => setNewUser({active: true})}>Dodaj rezerwowego</button>}
                    </div>
                  </div>
                :
                  <div style={({display: "flex", gap: "32px", justifyContent: 'space-between', alignItems: "flex-start", flexDirection: "row", width: "85%", marginLeft: "10px"})}>
                    <div style={({display: "flex", gap: "5px", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "column"})}>
                      <ArrowBack onClick={() => {setList(""); setSelectedGroup("")}}/>
                      <h3 style={({fontSize: "18px", color: location?.color})}>{location?.name}</h3>
                      {groups && groups?.filter(x => x?.id === selectedGroup?.id)?.map((g, index) =>
                        <h3 style={({fontSize: "16px", color: "rgb(30,30,30)", marginTop: "3px"})}>{g?.days?.custom_name} - {weekDayConvert(g?.days?.weekDay)} {g?.days?.startTime} - {g?.days?.endTime}</h3>
                      )}
                      <h3 style={({fontSize: "14px", color: "rgb(30,30,30)", marginTop: "15px"})}>Poziom: {selectedGroup?.exp}</h3>
                      <h3 style={({fontSize: "14px", color: "rgb(30,30,30)", marginTop: "15px"})}>Grupa wiekowa: {selectedGroup?.maxAge === 99 ? (selectedGroup?.adults ? `Dorośli ${selectedGroup?.minAge} +` : `${selectedGroup?.minAge} +`) : `${selectedGroup?.minAge} - ${selectedGroup?.maxAge} lat`}</h3>
                      <h3 style={({fontSize: "14px", color: "rgb(30,30,30)", marginTop: "15px"})}>Instruktor: {selectedGroup?.instructor ? selectedGroup?.instructor : "Nie podano"}</h3>
                      <h3 style={({fontSize: "18px", color: location?.color, marginTop: "25px"})}>Cennik:</h3>
                      <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", marginTop: "5px"})}>Umowa z wypowiedzeniem miesięcznym: {selectedGroup?.prices?.contract} zł</h3>
                      <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", marginTop: "5px"})}>Wejście jednorazowe: {selectedGroup?.prices?.entry} zł</h3>
                      <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", marginTop: "5px"})}>Umowa bez możliwości wypowiedzenia: {selectedGroup?.prices?.obligation === "Nie dotyczy" ? "Nie dotyczy" : selectedGroup?.prices?.obligation + " zł"}</h3>
                      <h3 style={({fontSize: "14px", color: "rgb(30,30,30)", marginTop: "35px"})}>Okres wypowiedzenia umowy: {selectedGroup?.adults ? "Koniec danego miesiąca" : "Miesiąc"}</h3>
                      <h3 style={({fontSize: "14px", color: location?.color, marginTop: "15px"})}>Dodatkowy opis grupy: {selectedGroup?.desc ? selectedGroup?.desc  : "Nie podano"}</h3>
                    </div>
                    <div style={({display: "flex", gap: "5px", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "column"})}>
                      <h3 style={({fontSize: "15px", color: location?.color})}>Maksymalna ilość miejsc: {selectedGroup?.slots}</h3>
                      <h3 style={({fontSize: "18px", color: "rgb(30,30,30)", marginTop: "5px"})}>Lista:</h3>
                      {list?.users?.length > 0 ?
                        list?.users?.map((u, index) =>
                          <div style={({display: "flex", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "column"})} onClick={() => setUser(u)}>
                            <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", marginTop: "5px"})}>{index + 1}. {u?.displayName} {u?.age ? "- " + u?.age + " lat" : ""}</h3>
                            <h3 style={({fontSize: "13px", color: "rgb(30,30,30)", fontWeight: "normal", marginLeft: "15px"})}>tel: {u?.phone} - mail: {u?.mail}</h3>
                            {u?.info && <h3 style={({fontSize: "13px", color: "rgb(30,30,30)", fontWeight: "normal", marginLeft: "15px", maxWidth: "200px"})}>Dodatkowe informacje: {u?.info}</h3>}
                          </div>
                        )
                      :
                        <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", marginTop: "5px"})}>Brak uczestników</h3>
                      }
                      {list?.users?.length <= selectedGroup?.slots && <button onClick={() => setNewUser({active: true})} style={({padding: "6px 12px", backgroundColor: location?.color, border: "none", outline: "none", borderRadius: "5px", fontWeight: "bold", color: "rgb(245,245,245)", marginTop: "20px", fontSize: "12px"})}>Dodaj uczestnika do listy</button>}
                      {list?.reserves?.length > 0 && <h3>Rezerwowi:</h3>}
                      {list?.reserves?.length > 0 && list?.reserves?.map((r, index) =>
                        <h3 onClick={() => setUser(r)}>{index + 1}. {r?.displayName}</h3>
                      )}
                      {list?.reserves?.length > 0 && <button onClick={() => setNewUser({active: true})}>Dodaj rezerwowego</button>}
                    </div>
                  </div>
        }
        {newUser &&
          <div style={({display: "flex", justifyContent: 'flex-start', alignItems: "center", flexDirection: "column", width: "100%", backgroundColor: "rgb(30,30,30,0.5)", position: "fixed", top: "0px", left: "0px", height: "100vh"})}>
            <div style={({display: "flex", gap: "15px", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "column", backgroundColor: "rgb(245,245,245)", padding: "30px", border: "2px solid rgb(30,30,30)", borderRadius: "5px", marginTop: "6%"})}>
              <h3 style={({fontSize: "18px", color: "rgb(30,30,30)", marginTop: "-10px"})}>Dodaj użytkownika na listę</h3>
              <div style={({display: "flex", gap: "10px", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "row", marginTop: "7px"})}>
                <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", width: "120px"})}>Imię i nazwisko</h3>
                <input onBlur={(e) => setNewUser({...newUser, displayName: e?.target?.value})} style={({borderRadius: "5px", outline: "none", border: `1px solid rgb(30,30,30)`})}/>
              </div>
              <div style={({display: "flex", gap: "10px", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "row", marginTop: "3px"})}>
                <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", width: "120px"})}>Numer telefonu</h3>
                <input onBlur={(e) => setNewUser({...newUser, phone: e?.target?.value})} style={({borderRadius: "5px", outline: "none", border: `1px solid rgb(30,30,30)`})}/>
              </div>
              <div style={({display: "flex", gap: "10px", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "row", marginTop: "3px"})}>
                <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", width: "120px"})}>Adres email</h3>
                <input onBlur={(e) => setNewUser({...newUser, mail: e?.target?.value})} style={({borderRadius: "5px", outline: "none", border: `1px solid rgb(30,30,30)`})}/>
              </div>
              <div style={({display: "flex", gap: "10px", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "row", marginTop: "3px"})}>
                <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", width: "120px"})}>Wiek (opcjonalnie)</h3>
                <input onBlur={(e) => setNewUser({...newUser, age: e?.target?.value})} style={({borderRadius: "5px", outline: "none", border: `1px solid rgb(30,30,30)`})}/>
              </div>
              <div style={({display: "flex", gap: "10px", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "row", marginTop: "3px"})}>
                <h3 style={({fontSize: "13px", color: "rgb(30,30,30)", width: "160px"})}>Dodatkowe informacje (opcj.)</h3>
                <input onBlur={(e) => setNewUser({...newUser, info: e?.target?.value})} style={({borderRadius: "5px", outline: "none", border: `1px solid rgb(30,30,30)`})}/>
              </div>
              <div style={({display: "flex", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "row", marginTop: "3px", width: "100%"})}>
                <button onClick={() => addUser()} style={({width: "80%", height: "30px", backgroundColor: location?.color, border: "none", outline: "none", borderRadius: "5px", fontWeight: "bold", color: "rgb(245,245,245)"})}>DODAJ UŻYTKOWNIKA</button>
                <button onClick={() => {setNewUser(""); setError("")}} style={({width: "20%", height: "30px", border: "none", outline: "none", borderRadius: "5px", fontWeight: "bold", backgroundColor: "rgb(245,245,245)"})}>ANULUJ</button>
              </div>
              {error && <h3 style={({fontSize: "13px", color: "#c71e32"})}>{error}</h3>}
            </div>
          </div>
        }
        {user &&
          <div style={({display: "flex", justifyContent: 'flex-start', alignItems: "center", flexDirection: "column", width: "100%", backgroundColor: "rgb(30,30,30,0.5)", position: "fixed", top: "0px", left: "0px", height: "100vh"})}>
            <div style={({display: "flex", gap: "15px", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "column", backgroundColor: "rgb(245,245,245)", padding: "30px", border: "2px solid rgb(30,30,30)", borderRadius: "5px", marginTop: "6%"})}>
              <h3 style={({fontSize: "18px", color: "rgb(30,30,30)", marginTop: "-10px"})}>Edytuj użytkownika</h3>
              <div style={({display: "flex", gap: "10px", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "row", marginTop: "7px"})}>
                <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", width: "120px"})}>Imię i nazwisko</h3>
                <input onBlur={(e) => setUser({...user, new_displayName: e?.target?.value})} style={({borderRadius: "5px", outline: "none", border: `1px solid rgb(30,30,30)`})} defaultValue={user?.displayName}/>
              </div>
              <div style={({display: "flex", gap: "10px", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "row", marginTop: "3px"})}>
                <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", width: "120px"})}>Numer telefonu</h3>
                <input onBlur={(e) => setUser({...user, new_phone: e?.target?.value})} style={({borderRadius: "5px", outline: "none", border: `1px solid rgb(30,30,30)`})} defaultValue={user?.phone}/>
              </div>
              <div style={({display: "flex", gap: "10px", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "row", marginTop: "3px"})}>
                <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", width: "120px"})}>Adres email</h3>
                <input onBlur={(e) => setUser({...user, new_mail: e?.target?.value})} style={({borderRadius: "5px", outline: "none", border: `1px solid rgb(30,30,30)`})} defaultValue={user?.mail}/>
              </div>
              <div style={({display: "flex", gap: "10px", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "row", marginTop: "3px"})}>
                <h3 style={({fontSize: "15px", color: "rgb(30,30,30)", width: "120px"})}>Wiek (opcjonalnie)</h3>
                <input onBlur={(e) => setUser({...user, new_age: e?.target?.value})} style={({borderRadius: "5px", outline: "none", border: `1px solid rgb(30,30,30)`})} defaultValue={user?.age}/>
              </div>
              <div style={({display: "flex", gap: "10px", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "row", marginTop: "3px"})}>
                <h3 style={({fontSize: "13px", color: "rgb(30,30,30)", width: "160px"})}>Dodatkowe informacje (opcj.)</h3>
                <input onBlur={(e) => setUser({...user, new_info: e?.target?.value})} style={({borderRadius: "5px", outline: "none", border: `1px solid rgb(30,30,30)`})} defaultValue={user?.info}/>
              </div>
              <div style={({display: "flex", justifyContent: 'flex-start', alignItems: "flex-start", flexDirection: "row", marginTop: "3px", width: "100%"})}>
                <button onClick={() => editUser()} style={({width: "50%", height: "30px", backgroundColor: location?.color, border: "none", outline: "none", borderRadius: "5px", fontWeight: "bold", color: "rgb(245,245,245)"})}>ZATWIERDŹ ZMIANY</button>
                <button onClick={() => {setUser(""); setError("")}} style={({width: "20%", height: "30px", border: "none", outline: "none", borderRadius: "5px", fontWeight: "bold", backgroundColor: "rgb(245,245,245)"})}>ANULUJ</button>
                {/* <button onClick={() => deleteUser()} style={({width: "30%", height: "30px", border: "none", outline: "none", borderRadius: "5px", fontWeight: "bold", backgroundColor: "rgb(245,245,245)"})}>USUŃ Z LISTY</button> */}
              </div>
              {error && <h3 style={({fontSize: "13px", color: "#c71e32"})}>{error}</h3>}
            </div>
          </div>
        }
      </div>
    );
  }