import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { auth, apiClient, db } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { collection, onSnapshot, query, where } from 'firebase/firestore';

const Registration = () => {
  const { facilityId, locationId, groupId } = useParams();
  const [facilities, setFacilities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [groups, setGroups] = useState([]);

  const [participants, setParticipants] = useState([
    { email: '', password: '', displayName: '', priceOptionId: '' }
  ]);

  // Subskrypcja do zmian w kolekcji facilities
  useEffect(() => {
    const getFacilities = onSnapshot(collection(db, "facilities"), (snapshot) => {
      setFacilities(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });
    return () => getFacilities(); // Unsubscribe on unmount
  }, []);

  // Subskrypcja do zmian w kolekcji locations w zależności od wybranej placówki
  useEffect(() => {
    if (participants[0].selectedFacilityId) {
      const locationsQuery = query(collection(db, "locations"), where("facility_id", "==", participants[0].selectedFacilityId));
      const getLocations = onSnapshot(locationsQuery, (snapshot) => {
        setLocations(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      });
      return () => getLocations(); // Unsubscribe on unmount
    }
  }, [participants[0].selectedFacilityId]);

  // Subskrypcja do zmian w kolekcji groups w zależności od wybranej lokalizacji
  useEffect(() => {
    if (participants[0].selectedLocationId) {
      const groupsQuery = query(collection(db, "groups"), where("location_id", "==", participants[0].selectedLocationId));
      const getGroups = onSnapshot(groupsQuery, (snapshot) => {
        setGroups(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      });
      return () => getGroups(); // Unsubscribe on unmount
    }
  }, [participants[0].selectedLocationId]);

  const handleParticipantChange = (index, field, value) => {
    const updatedParticipants = [...participants];
    updatedParticipants[index][field] = value;
    setParticipants(updatedParticipants);
  };

  const handleAddParticipant = () => {
    setParticipants([...participants, { email: '', password: '', displayName: '', priceOptionId: '' }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredentials = await Promise.all(participants.map(async (participant) => {
        const userCredential = await createUserWithEmailAndPassword(auth, participant.email, participant.password);
        return userCredential.user;
      }));

      const responses = await Promise.all(userCredentials.map(async (user, index) => {
        const response = await apiClient.post('/registerUser', {
          uid: user.uid,
          displayName: participants[index].displayName,
          priceOptionId: participants[index].priceOptionId,
          facilityId: participants[index].selectedFacilityId || facilityId,
          locationId: participants[index].selectedLocationId || locationId,
          groupId: participants[index].selectedGroupId || groupId
        });
        return response.data;
      }));

      const success = responses.every(response => response.success);
      if (success) {
        alert('Registration successful!');
      } else {
        alert('Registration failed for some participants.');
      }
    } catch (error) {
      console.error('Error registering users:', error);
      alert('Error registering users. Please try again.');
    }
  };

  return (
    // <form onSubmit={handleSubmit}>
    //   {participants.map((participant, index) => (
    //     <div key={index} style={{ marginBottom: '20px' }}>
    //       <h3>Participant {index + 1}</h3>
    //       <input
    //         type="text"
    //         placeholder="Display Name"
    //         value={participant.displayName}
    //         onChange={(e) => handleParticipantChange(index, 'displayName', e.target.value)}
    //         required
    //       />
    //       <input
    //         type="email"
    //         placeholder="Email"
    //         value={participant.email}
    //         onChange={(e) => handleParticipantChange(index, 'email', e.target.value)}
    //         required
    //       />
    //       <input
    //         type="password"
    //         placeholder="Password"
    //         value={participant.password}
    //         onChange={(e) => handleParticipantChange(index, 'password', e.target.value)}
    //         required
    //       />

    //       {/* Wybór placówki */}
    //       <select
    //         value={participant.selectedFacilityId}
    //         onChange={(e) => handleParticipantChange(index, 'selectedFacilityId', e.target.value)}
    //         required
    //       >
    //         <option value="">Select Facility</option>
    //         {facilities.map(facility => (
    //           <option key={facility.id} value={facility.id}>{facility.name}</option>
    //         ))}
    //       </select>

    //       {/* Wybór lokalizacji */}
    //       <select
    //         value={participant.selectedLocationId}
    //         onChange={(e) => handleParticipantChange(index, 'selectedLocationId', e.target.value)}
    //         required
    //       >
    //         <option value="">Select Location</option>
    //         {locations.map(location => (
    //           <option key={location.id} value={location.id}>{location.name}</option>
    //         ))}
    //       </select>

    //       {/* Wybór grupy */}
    //       <select
    //         value={participant.selectedGroupId}
    //         onChange={(e) => handleParticipantChange(index, 'selectedGroupId', e.target.value)}
    //         required
    //       >
    //         <option value="">Select Group</option>
    //         {groups.map(group => (
    //           <option key={group.id} value={group.id}>{group.name}</option>
    //         ))}
    //       </select>

    //       {/* Wybór opcji cenowej */}
    //       <select
    //         value={participant.priceOptionId}
    //         onChange={(e) => handleParticipantChange(index, 'priceOptionId', e.target.value)}
    //         required
    //       >
    //         <option value="">Select Price Option</option>
    //         <option value="price_1">Basic Plan</option>
    //         <option value="price_2">Standard Plan</option>
    //         <option value="price_3">Premium Plan</option>
    //       </select>
    //     </div>
    //   ))}

    //   <button type="button" onClick={handleAddParticipant}>Add Another Participant</button>
    //   <button type="submit">Register</button>
    // </form>
    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", backgroundColor: "rgb(250,250,250)", width: "100%", minHeight: "100vh", overflowY: "scroll", gap: "30px"})}>
        <nav style={({width: "100%", height: "75px", borderBottom: "1px solid rgb(30,30,30,0.1)", display: "flex", justifyContent: "center", alignItems: "center"})}>
            <div style={({display: "flex", justifyContent: "space-between", alignItems: "center", width: "97%", height: "100%"})}>
            </div>
        </nav>
        <div style={({display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "90%", gap: "15px", marginBottom: "50px"})}>
            
        </div>
    </div>
  );
};

export default Registration;